import React, { ReactNode, useEffect, useRef, useState } from 'react';

import { AppMode, useCommonState, useNameplateSettings } from '@/hooks';
import { NameplateCanvasComponent } from '@/pixi/nameplate-canvas-app/NameplateCanvasComponent';
import NameplateHeader from '../NameplateHeader';
import NameplateMenuActions from './NameplateMenuActions';
import cls from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { Preloader } from '@/components/common/Preloader';
import Button from '@mui/material/Button';
import { ReactComponent as TutorialArrowIcon } from '@assets/images/cursor/tutorialArrow.svg'
import { ReactComponent as ZoomWithFingers } from '@assets/images/cursor/zoomWithFingers.svg'
import { MobileBasicNameplateCanvasComponent } from '@/pixi/nameplate-canvas-app/MobileBasicNameplateCanvasComponent';
import backgroundSource from '@assets/images/backgrounds/model-4/model-4.jpg'
import ThreeDViewComponent from '@/pixi/nameplate-canvas-app/ThreedViewComponent';
import MobileThreeDViewComponent from '@/pixi/nameplate-canvas-app/MobileThreedViewComponent';

const windowHeight = window.innerHeight + 300;
export const mobilePanel = 'mobilePanel';
export const NameplateMobileSquare: React.FC = () => {
    const { texturesLoading, constructor, images } = useNameplateSettings();
    const { appMode } = useCommonState();
    const isLoading = texturesLoading || constructor.isLoading || !!images?.isLoading;
    const [tutorialStep, setTutorialStep] = useState<number>(0);

    useEffect(() => {
        const isTutorialShown = localStorage.getItem("isTutorialShown")
        if (isTutorialShown == "true") {
            setTutorialStep(2)
        } else {
            if (tutorialStep == 2) {
                localStorage.setItem("isTutorialShown", "true")
            }
        }
    }, [tutorialStep])


  const zoomRef = useRef<HTMLDivElement>(null); // Ref for the element
  const [scale, setScale] = useState<number>(1); // State for tracking scale
  const [initialDistance, setInitialDistance] = useState<number | null>(null); // Initial touch distance

  // Helper function to calculate distance between two touches
  const getDistance = (touch1: Touch, touch2: Touch): number => {
    const dx = touch2.clientX - touch1.clientX;
    const dy = touch2.clientY - touch1.clientY;
    return Math.sqrt(dx * dx + dy * dy);
  };

  useEffect(() => {
    const element = zoomRef.current;

    const handleTouchStart = (e: TouchEvent): void => {

      if (e.touches.length === 2) {
        const distance = getDistance(e.touches[0], e.touches[1]);
        setInitialDistance(distance);
      }
    };

    const handleTouchMove = (e: TouchEvent): void => {

        if (e.touches.length === 2 && initialDistance) {
        const currentDistance = getDistance(e.touches[0], e.touches[1]);
        const zoomFactor = currentDistance / initialDistance;
        const newScale = Math.max(1, Math.min(zoomFactor, 4)); // Min and max zoom levels
        setScale(newScale);
      }
    };

    const handleTouchEnd = (): void => {
      setInitialDistance(null);
    };

    // Attach event listeners
    if (element) {
      element.addEventListener('touchstart', handleTouchStart);
      element.addEventListener('touchmove', handleTouchMove);
      element.addEventListener('touchend', handleTouchEnd);
    }

    // Cleanup on component unmount
    return () => {
      if (element) {
        element.removeEventListener('touchstart', handleTouchStart);
        element.removeEventListener('touchmove', handleTouchMove);
        element.removeEventListener('touchend', handleTouchEnd);
      }
    };
  }, [initialDistance]); // Depend on initialDistance


    return (
        <motion.div
            className={cls('w-full relative bg-cover bg-no-repeat bg-top bg-white', {
                'h-full': appMode === AppMode.CONSTRUCTOR,
            })}

            ref={zoomRef}
        >
            {/* <Preloader open={isLoading} /> */}
            {
                tutorialStep <= 1 && appMode === AppMode.CONSTRUCTOR ?
                    <AnimatePresence>
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.4, delay: 1 }}
                            className='flex flex-col items-center'
                            style={{ backgroundColor: "rgba(15, 26, 52, .6)", width: '100%', height: '100%', position: 'absolute', zIndex: 1002 }}
                        >
                            {tutorialStep === 0 ?
                                <motion.div>
                                    <motion.div
                                        initial={{ y: 16, opacity: 0 }}
                                        animate={{ y: 0, opacity: 1 }}
                                        transition={{ ease: "easeOut", duration: 0.5, delay: 1.15, opacity: { duration: 0.5 } }}
                                        className='flex flex-col items-center'
                                    >
                                        <p style={{ lineHeight: '32px' }} className='text-center font-semibold text-[24px] pl-12 pr-12 pt-28'>depending on size,</p>
                                        <p style={{ lineHeight: '32px' }} className='text-center font-semibold text-[24px] pl-12 pr-12 pb-10'>the price updates in real-time</p>
                                    </motion.div>
                                    <motion.div
                                        className='flex flex-col items-center'
                                        initial={{ y: 16, opacity: 0 }}
                                        animate={{ y: 0, opacity: 1 }}
                                        transition={{ ease: "easeOut", duration: 0.5, delay: 1.25, opacity: { duration: 0.5 } }}
                                    >
                                        <Button
                                            className={cls(
                                                'w-[132px] h-[56px] text-stylish-black text-normal font-semibold text-[21px] cursor-pointer whitespace-nowrap bg-white normal-case',
                                            )}
                                            style={{ borderRadius: 36 }}
                                            onClick={() => { setTutorialStep(1) }}
                                        >
                                            Got it
                                        </Button>
                                    </motion.div>
                                    <TutorialArrowIcon style={{ position: 'absolute', right: 20, top: 16 + 48 }} />
                                </motion.div>
                                :
                                <motion.div
                                    initial="visible"
                                    style={{ opacity: 0, y: 16 }}//{{ y: 16, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: 0.5, delay: 0.45, opacity: { duration: 0.5 } }}

                                >
                                    <motion.div
                                    >
                                        <p className='text-center font-semibold text-[24px] pl-12 pr-12 pt-28 pb-52'>Zoom with fingers</p>
                                        <ZoomWithFingers style={{ position: 'absolute', top: 50 }} />
                                    </motion.div>
                                    <motion.div
                                        className='flex flex-col items-center'
                                    >
                                        <Button
                                            className={cls(
                                                'w-[132px] h-[56px] text-stylish-black text-normal font-semibold text-[21px] cursor-pointer whitespace-nowrap bg-white normal-case',
                                            )}
                                            style={{ borderRadius: 36 }}
                                            onClick={() => { setTutorialStep(2) }}
                                        >
                                            Great!
                                        </Button>
                                    </motion.div>
                                </motion.div>
                            }
                        </motion.div>
                    </AnimatePresence>
                    :
                    <></>
            }
            <div className='flex flex-col justify-between items-center w-full h-full' style={appMode === AppMode.CONSTRUCTOR ? { pointerEvents: 'auto' } : { pointerEvents: 'none' }}>

                <div
                    className={cls('w-full h-full flex flex-col justify-end items-center', {
                        'z-[100]': appMode === AppMode.CONSTRUCTOR,
                    })}
                >
                    <motion.div
                        id={`${mobilePanel}`}
                        initial={false}
                        style={{ y: 290 }}
                        animate={appMode === AppMode.LANDING ? { x: 0, y: 290 } : { x: 0, y: 0 }}
                        transition={{ duration: 0, type: 'ease' }}
                        className={cls('max-w-md xl:max-w-lg w-full relative z-[1000]')}
                    >
                        <NameplateMenuActions />
                    </motion.div>
                </div>

            </div>
            
            <div 
            style={{
                position: 'absolute',
                top: 0,
                right: 0,
                left: 0,
                transform:`scale(${scale})`,
                transformOrigin: '50% 200px', // Customize this value
                // zIndex: 10000
            }}
            >
                <img style={{ position: 'absolute', objectFit: 'cover' }} src={backgroundSource} width={window.innerWidth} height={windowHeight} ></img>
                
                <MobileBasicNameplateCanvasComponent />
            </div>
            <MobileThreeDViewComponent />
            <NameplateHeader />
        </motion.div>
    );
};
