import React from 'react';
import { Container } from '../Container';
import { motion } from 'framer-motion';
import { ReactComponent as DecadesSVG } from '../../../../assets/images/landing/svg/free-shipping.svg';
import { ReactComponent as FairPriceSVG } from '../../../../assets/images/landing/svg/hassle-free-returns.svg';
import { ReactComponent as SameDaySVG } from '../../../../assets/images/landing/svg/same-day-fulfillment.svg';
import { ANCHOR_TABS } from '../../enums';
import { useMediaQuery } from '@mui/material';

const animationImageContainer = {
  hidden: { y: 100, opacity: 0 },
  visible: (custom: number) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: custom * 0.2,
      duration: 1,
    },
  }),
};

export const WhyChooseUs = () => {
  const isMobile = useMediaQuery('(max-width: 1023px)');


  return (
    <motion.section
    id={ANCHOR_TABS.whyUs}
    initial='hidden'
    whileInView='visible'
    viewport={{ amount: 0.15, once: true }}
    className={isMobile ? 'pt-[64px] relative' : 'pt-[24px] relative'}
  >
    <Container>
      <div className={isMobile ? 'bg-white rounded-3xl p-8 lg:p-20 lg:pb-24 shadow-3xlwarmContMob' : 'bg-white rounded-3xl p-8 lg:p-20 lg:pb-24 shadow-3xlwarmCont'}>
        <motion.h2
        custom={1}
        variants={animationImageContainer}
        className={isMobile ? 'text-center text-4xl lg:text-5xl font-averta text-terracotta font-extrabold mb-14 mt-[26px]' : 'text-center text-4xl lg:text-5xl font-averta text-terracotta font-extrabold mb-14'}
        >
          Why choose us?
        </motion.h2>
        <motion.div 
        custom={1}
        variants={animationImageContainer}
        className={isMobile ? 'grid grid-rows-3 lg:grid-rows-none lg:grid-cols-3 gap-y-5 lg:gap-x-5 pb-[24px]' : 'grid grid-rows-3 lg:grid-rows-none lg:grid-cols-3 gap-y-5 lg:gap-x-5' }
        >
          <motion.div
            className='flex flex-col items-center text-center'
          >
            <DecadesSVG className='mb-6' />
            <h3 className='text-2xl font-averta font-bold text-terracotta mb-3'>
              Free Shipping
            </h3>
            <p style={isMobile ? {lineHeight: '24px'} : {}} className='text-lg font-averta text-stylish-black'>
            No extra costs, we deliver your order for free, straight to your doorstep   
            </p>
          </motion.div>
          <motion.div
            className='flex flex-col items-center text-center'
          >
            <FairPriceSVG className='mb-6' />
            <h3 className='text-2xl font-averta font-bold text-terracotta mb-3'>Hassle-Free Returns</h3>
            <p style={isMobile ? {lineHeight: '24px'} : {}} className='text-lg font-averta text-stylish-black'>
            Changed your mind? Return it easily, no questions asked
            </p>
          </motion.div>
          <motion.div
            className='flex flex-col items-center text-center'
          >
            <SameDaySVG className='mb-6' />
            <h3 className='text-2xl font-averta font-bold text-terracotta mb-3'>
              Same day fulfillment
            </h3>
            <p style={isMobile ? {lineHeight: '24px'} : {}} className='text-lg font-averta text-stylish-black'>
            Order today and it on its way. Enjoy your purchase without the wait
            </p>
          </motion.div>
        </motion.div>
      </div>
    </Container>
  </motion.section>
  );
};
