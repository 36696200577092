import { PendantCanvasAppProps } from './PendantCanvasApp';
import * as PIXI from 'pixi.js';
import { PendantCanvasAppLayout } from './PendantCanvasAppLayout';

export const adjustLabelsOnShapeChange = (
  props: PendantCanvasAppProps,
  layout: PendantCanvasAppLayout,
  prevPendantTexture: PIXI.Texture,
): boolean => {
  const pendantTexture = layout.pendant.texture;
  const sx = pendantTexture.width / prevPendantTexture.width;
  let adjusted = false;

  for (const labelData of props.labels) {
    if (!labelData) return false;

    const label = layout.labelById[labelData.id];

    // if (layout.controller.isEditing(label.mesh)) continue;
    adjusted = true;

    const x = (labelData.textPosition.x / prevPendantTexture.width) * pendantTexture.width;
    const y = (labelData.textPosition.y / prevPendantTexture.height) * pendantTexture.height;

    // props.setFontSize(labelData.id, labelData.fontSize * sx);
    // props.setTextPosition(labelData.id, { x, y });

    label.outOfPendantDirty = true;
    label.root.x = x;
    label.root.y = y;
    label.root.scale.set(labelData.fontSize * sx);

    labelData.fontSize = labelData.fontSize * sx;
    labelData.textPosition.x = x;
    labelData.textPosition.y = y;
  }

  return adjusted;
};
