import * as PIXI from 'pixi.js';

import { getLabelMaskAsset, getPendantAsset, getPossibleMessageShapes } from '../../hooks/pendant/use-pendant-settings/pendants';
import { promisifyEvent } from '../../utils/async';

import { PendantCanvasAppProps } from './PendantCanvasApp';
import {
  backgroundConfigByName,
  ChainSide,
  getChainAsset,
  getChainRingAsset,
} from '../nameplate-canvas-app/backgrounds';
import { nameplateMaterialByPendantMaterial } from './PendantChain';

export type PendantCanvasAppTextureByName = {
  pendantTexture: PIXI.Texture;
  labelMaskTexture: PIXI.Texture;
  chainRingTexture: PIXI.Texture;
} & Record<ChainSide, PIXI.Texture>;

export class PendantCanvasAppAssetsLoader {
  async loadTextures(props: PendantCanvasAppProps): Promise<PendantCanvasAppTextureByName> {
    const {
      shape,
      messageShape,
      pendantMaterial,
      image,
      backgroundName,
      currentElement,
      pendantSize,
    } = props;
    const bgConfig = backgroundConfigByName[backgroundName];

    const [
      pendantTexture,
      labelMaskTexture,
      chainLeftTexture,
      chainRightTexture,
      chainRingTexture,
    ] = await Promise.all(
      [
        getPendantAsset(shape, pendantMaterial, messageShape, pendantSize),
        getLabelMaskAsset(shape, messageShape, pendantSize),
        getChainAsset(
          bgConfig,
          ChainSide.ChainLeft,
          nameplateMaterialByPendantMaterial[pendantMaterial],
        ),
        getChainAsset(
          bgConfig,
          ChainSide.ChainRight,
          nameplateMaterialByPendantMaterial[pendantMaterial],
        ),
        getChainRingAsset(bgConfig, nameplateMaterialByPendantMaterial[pendantMaterial]),
      ].map((asset) => PIXI.Texture.fromURL(asset)),
    );

    const labelMaskPromises = getPossibleMessageShapes(shape, pendantSize, currentElement).map(
      async (mShape) => {
        if (mShape === messageShape) return;

        const asset = getLabelMaskAsset(shape, mShape, pendantSize);
        await PIXI.Texture.fromURL(asset);
      },
    );
    await Promise.all(labelMaskPromises);

    if (image) {
      const isLoaded = image.complete && image.naturalHeight !== 0;

      if (!isLoaded) {
        await Promise.race([promisifyEvent(image, 'load'), promisifyEvent(image, 'error')]);
      }
    }

    await document.fonts.ready;

    return {
      pendantTexture,
      labelMaskTexture,
      [ChainSide.ChainLeft]: chainLeftTexture,
      [ChainSide.ChainRight]: chainRightTexture,
      chainRingTexture,
    };
  }
}
