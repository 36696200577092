import React from 'react';
import { AppMode, NameplateMenuPages, useCommonState, useNamePlateMenu } from "@/hooks";
import cls from "classnames";
import { Button } from "../Button";
import { ReactComponent as CrossIcon } from "../../../../assets/images/icons/common/cross_mob.svg";

export const ZoomButton: React.FC = () => {
  const { onChangeAppMode } = useCommonState();
  const { onSelectMenuPage } = useNamePlateMenu();
  const { onChangeZoom, onChangeRulerIsEnabled, rulerIsEnabled, setIs3dViewEnabled } = useCommonState();

  const handleOnClick = () => {

    if (rulerIsEnabled) {
      onChangeRulerIsEnabled(false);
    }

    setIs3dViewEnabled(false);
    onChangeZoom(1);

    onChangeAppMode(AppMode.LANDING);
    onSelectMenuPage(NameplateMenuPages.MENU);
  };

  return (
      <div className={cls('fixed cursor-pointer top-4 left-4 z-[1001]')}>
        <Button
            onClick={handleOnClick}
            isTransparent={true}
            style={{
              width: '48px',
              height: '48px',
              borderRadius: '50%',
              backgroundColor: 'rgba(255,255,255,0.8)'
            }}
        >
          <CrossIcon style={{
            position: 'relative',
            width: '20px',
            height: '20px',
          }} />
        </Button>
      </div>
  );
}