export const DEFAULT_MESSAGE_TIMEOUT = 3000;

import { usd } from '@/utils/format';

export const getPriceMessage = (price: number) => `Updated price is ${usd.format(price)}`;
export const getSizeLimitWarnMessage = () => `You're approaching a size limit`;
export const getSizeLimitEmptyMessage = () => `Type a name`;
export const getSizeLimitExceededMessage = () => `You're approaching a size limit`;

// export const getPriceMessage = (price: number) => `
// <!--  <span>Another size — not same price</span><br/>-->
//   <span style='display: block; padding: 10px 0;'>Updated price is $${price}</span>
// `;

// export const getSizeLimitWarnMessage = () => `
//   <span>You're approaching a size limit</span>
// `;
//
// export const getSizeLimitExceededMessage = () => `
//   <span>Type a name</span>
// `;
//
// export const getSizeLimitEmptyMessage = () => `
//   <span>Type a name</span>
// `;
