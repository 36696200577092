import React, { useState } from 'react';
// import { addMember } from '@/api';
import subPopup3 from '@assets/images/landing/svg/emily_img.jpg'
import { Button } from '@mui/base';
import { ReactComponent as CrossIcon } from '@assets/images/icons/common/lightCross.svg';
import { motion } from 'framer-motion';

type Props = {
    onClose: ()=> void;
  };

export const SubscriptionPopup: React.FC<Props> = ({
    onClose,
  }) => {

    const [userEmail, setUserEmail] = useState('');

    const handleChangeEmailText = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const newValue = e.currentTarget.value || '';
        setUserEmail(newValue);
    };

    const subscribeUser = async () => {
        // addMember({
        //     email: userEmail
        // }).then((data) => {
        //     // console.log(data.data.id)
        //     localStorage.setItem("mc_customerId", data.data.id.toString())
        //     onClose();
        // })
        // .catch((err) => {
        //     alert('Sorry, something went wrong, please try again.');
        //     console.log(err);
        // });
    }


    return (
        <motion.div style={{
            position: 'fixed',
            width: '100%',
            height: '100vh',
            top: 0,
            left: 0,
            zIndex: 10001,
            backgroundColor: 'rgba(62, 73, 102, 0.1)',
            backdropFilter: 'blur(44px)',
        }}
        initial={{opacity: 0, y: -100}}
        animate={{y: 0, opacity: 1, transition: {duration: 0.5}}}
        >
            <div style={{position:'absolute', 
                top: window.innerHeight / 2 - 600 / 2 + 32,
                left: window.innerWidth / 2 - 880 / 2 + 16,
                width: '854px',
                height: '500px',
                backgroundColor: '#B59997',
                filter: 'blur(44px)',
                }}></div>
            <div className='flex' style={{
                position: 'relative',
                width: '880px',
                height: '500px',
                backgroundColor: '#F7E2D9',
                top: window.innerHeight / 2 - 600 / 2,
                left: window.innerWidth / 2 - 880 / 2,
                zIndex: 10001,
                borderRadius: 24
            }}>
                <img src={subPopup3} className='flex-none rounded-l-3xl' width={443} height={500}></img>
                <div className='flex-1 rounded-r-3xl' style={{ backgroundColor: '#F7E2D9' }}>
                    <Button onClick={onClose} className='border-0 w-[42px] h-[42px] bg-inherit pt-[14px] pl-[380px]'> <CrossIcon /></Button>
                    <p className='text-stylish-black font-extrabold text-[48px] text-center pt-[70px] font-averta'>
                        Get 15% off
                    </p>
                    <p className='text-stylish-black leading-normal  text-[21px] text-center pt-[24px] pl-[70px] pr-[60px] font-averta'>
                        Subscribe to our newsletter and receive exclusive offers
                    </p>
                    <input
                        placeholder='Email'
                        onChange={handleChangeEmailText}
                        style={{
                            backgroundColor: '#F5F5F5',
                            fontFamily: 'Averta',
                            fontWeight: 600,
                            fontSize: '21px',
                            lineHeight: '24px',
                        }}
                        className='pl-4 rounded-2xl border-0 h-[56px] mt-[40px] ml-[70px] w-[301px]'
                    >
                    </input>
                    <Button
                        onClick={subscribeUser}
                        style={{borderRadius: 30}}
                        className='ml-[120px] mt-[24px] w-[194px] border-0 font-averta py-5 text-white text-bold mb-4 font-bold text-[18px] cursor-pointer whitespace-nowrap bg-stylish-black normal-case'
                    >
                        Unlock discount
                    </Button>
                </div>
            </div>
        </motion.div>
    )
}

