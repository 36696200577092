export enum NameplateModeType {
  TEXT = 'text',
  SETTINGS = 'settings',
}

export enum NameplateMenuPages {
  MENU = 'menu',
  MATERIAL = 'material',
  SIZE = 'size',
  CHAIN = 'chain',
  ZOOM = 'zoom'
}

export interface NameplateMenuContext {
  mode: NameplateModeType;
  menuPage: NameplateMenuPages;
  onSelectMode: (mode: NameplateModeType) => void;
  onSelectMenuPage: (page: NameplateMenuPages) => void;
}
