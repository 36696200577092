import * as PIXI from 'pixi.js';
import { PendantCanvasApp } from '../pendant-canvas-app';
import { NameplateCanvasApp } from '../nameplate-canvas-app';
import {
  backgroundConfigByName,
  BackgroundName,
  CropVariant,
} from '../nameplate-canvas-app/backgrounds';
import { getPendantTrimData } from '../../hooks/pendant/use-pendant-settings/pendants';

export const drawCropVariant = (
  app: PendantCanvasApp | NameplateCanvasApp,
  bgName: BackgroundName,
  cropVariant: CropVariant,
): HTMLCanvasElement => {
  const bgConfig = backgroundConfigByName[bgName];
  const rect = bgConfig.exportsCropRect[cropVariant];
  const lt = app.layout.bg.root.toGlobal({ x: rect.x, y: rect.y });
  const rb = app.layout.bg.root.toGlobal({ x: rect.x + rect.width, y: rect.y + rect.height });

  return drawArea(app, { x: lt.x, y: lt.y, width: rb.x - lt.x, height: rb.y - lt.y });
};

export const drawPendantArea = (
  app: PendantCanvasApp,
  bgName: BackgroundName,
): HTMLCanvasElement => {
  const props = app.layout.props!;
  const bgConfig = backgroundConfigByName[bgName];
  const trim = getPendantTrimData(props.shape, props.messageShape, props.pendantSize);

  const top = app.layout.chainsRing.toGlobal(bgConfig.chainRing.topPivot).y;

  const { x: left, y: bottom } = app.layout.pendant.toGlobal({
    x: -trim.frameWidth / 2,
    y: trim.fullHeight - trim.bottom - trim.fullHeight * app.layout.pendant.anchor.y,
  });

  const right = app.layout.pendant.toGlobal({ x: trim.frameWidth / 2, y: 0 }).x;

  const centerX = (left + right) / 2;
  const centerY = (top + bottom) / 2;

  const size = Math.max(right - left, bottom - top) * 1.8;

  const x = centerX - size / 2;
  const y = centerY - size / 2;

  return drawArea(app, { x: x, y: y, width: size, height: size });
};

export const drawNameplateArea = (app: NameplateCanvasApp): HTMLCanvasElement => {
  const bounds = app.layout.label.getBoundsInStageSpace();

  const centerX = (bounds.minX + bounds.maxX) / 2;
  const centerY = (bounds.minY + bounds.maxY) / 2;

  const size = Math.max(bounds.maxX - bounds.minX, bounds.maxY - bounds.minY) * 1.8;

  const x = centerX - size / 2;
  const y = centerY - size / 2;

  return drawArea(app, {
    x: x,
    y: y,
    width: size,
    height: size,
  });
};

export const drawArea = (
  app: PendantCanvasApp | NameplateCanvasApp,
  cropRect: { x: number; y: number; width: number; height: number },
) => {
  const { x, y, width, height } = cropRect;
  const rt = PIXI.RenderTexture.create({ width, height });

  app.renderer.render(app.stage, {
    renderTexture: rt,
    transform: new PIXI.Matrix(1, 0, 0, 1, -x, -y),
  });

  return app.renderer.plugins.extract.canvas(rt);
};
