import * as PIXI from 'pixi.js';

const radius = 70;
const thickness = radius / 2;

export class NameplateCircle {
  private readonly mask: PIXI.Graphics;
  private readonly material: PIXI.Sprite;

  constructor(parent: PIXI.Container) {
    this.mask = new PIXI.Graphics();
    this.mask.lineStyle(thickness, 0xffffff, 1, 0);
    this.mask.drawCircle(0, 0, radius);

    this.material = PIXI.Sprite.from(PIXI.Texture.WHITE);
    this.material.anchor.set(0.5);
    this.material.mask = this.mask;
    parent.addChild(this.mask);
    parent.addChild(this.material);
  }

  set(
    edge: { x: number; y: number },
    center: { x: number; y: number },
    materialTexture: PIXI.Texture,
  ) {
    const rotation = Math.atan2(edge.y - center.y, edge.x - center.x);
    this.material.texture = materialTexture;
    this.material.width = radius * 3;
    this.material.height = radius * 3;
    this.material.x = edge.x - Math.cos(rotation) * radius;
    this.material.y = edge.y - Math.sin(rotation) * radius;
    this.material.rotation = rotation;

    this.mask.x = this.material.x;
    this.mask.y = this.material.y;
    this.mask.rotation = rotation;
  }

  getEdgePosition(neckPosition: { x: number; y: number }, space: PIXI.Container) {
    const position = this.mask.toLocal(neckPosition, space);
    const direction = Math.atan2(position.y, position.x);

    position.x = Math.cos(direction) * radius;
    position.y = Math.sin(direction) * radius;
    space.toLocal(position, this.mask, position);

    return position;
  }
}
