import * as PIXI from 'pixi.js';

export class MobileTransformView {
  root: PIXI.Container;
  private readonly lines: PIXI.Sprite[];

  constructor() {
    this.root = new PIXI.Container();

    this.lines = Array.from({ length: 4 }).map(() => {
      const line = PIXI.Sprite.from(PIXI.Texture.WHITE);
      line.height = 2;
      this.root.addChild(line);

      return line;
    });
  }

  render(target: PIXI.Container) {
    const hw = target.width / 2 / target.scale.x;
    const hh = target.height / 2 / target.scale.y;

    [
      { x: -hw, y: -hh },
      { x: +hw, y: -hh },
      { x: +hw, y: +hh },
      { x: -hw, y: +hh },
    ]
      .map((point) => {
        return this.root.toLocal(point, target);
      })
      .forEach((point, i, arr) => {
        const next = arr[i + 1] ?? arr[0];
        const line = this.lines[i];
        const dx = next.x - point.x;
        const dy = next.y - point.y;

        line.x = point.x;
        line.y = point.y;
        line.width = Math.hypot(dy, dx);
        line.rotation = Math.atan2(dy, dx);
      });
  }
}
