import * as PIXI from 'pixi.js';

// export class ShadowContainer extends PIXI.Container {
//   constructor() {
//     super();
//     const filter = new PIXI.filters.ColorMatrixFilter();
//     filter.brightness(1, true);
//     filter.alpha = 1

//     const render = this.render;
//     this.render = (renderer) => {
//       const offsetX = -2 * this.scale.x;
//       const offsetY = 2 * this.scale.y;
//       this.x -= offsetX;
//       this.y -= offsetY;
//       this.updateTransform();
//       this.filters = [filter];
//       render.call(this, renderer);

//       this.x += offsetX;
//       this.y += offsetY;
//       this.updateTransform();
//       this.filters = null;
//       render.call(this, renderer);
//     };
//   }
// }

export class ShadowContainer extends PIXI.Container {
  constructor() {
    super();

    const filter = new PIXI.filters.ColorMatrixFilter();
    filter.brightness(0.65, true);

    const render = this.render;
    this.render = (renderer) => {
      const offsetX = -2 * this.scale.x;
      const offsetY = 2 * this.scale.x;
      this.x -= offsetX;
      this.y -= offsetY;
      this.updateTransform();
      this.filters = [filter];
      render.call(this, renderer);

      this.x += offsetX;
      this.y += offsetY;
      this.updateTransform();
      this.filters = null;
      render.call(this, renderer);
    };
  }
}

export class SecondShadowContainer extends PIXI.Container {
  constructor() {
    super();
    const blurFilter = new PIXI.filters.BlurFilter(1.5, 0.7);

    const filter = new PIXI.filters.ColorMatrixFilter();

    const replaceColorFilter = new PIXI.filters.AlphaFilter(0.32)
    filter.tint(0x644C44)
    filter.brightness(0, true);
    filter.alpha = 0.9

    const render = this.render;
    this.render = (renderer) => {
      const offsetX = 3 * this.scale.x;
      const offsetY = -5 * this.scale.y;
      this.x -= offsetX;
      this.y -= offsetY;
      this.updateTransform();
      this.filters = [filter, blurFilter, replaceColorFilter];
      render.call(this, renderer);

      this.x += offsetX;
      this.y += offsetY;
      this.updateTransform();
      this.filters = null;
      render.call(this, renderer);
    };
  }
}
