import React from 'react';

import classNames from 'classnames';
import classes from './ZoomRange.module.css';

type Props = {
  value: number;
  min: number;
  max: number;
  step: number;
  onChange: (e: any) => any;
  onMouseUp?: (e: any) => any;
  onTouchEnd?: (e: any) => any;
  className?: string;
  style?: object;
  isRed?: boolean;
  isWhite?: boolean;
  withoutStrip?: boolean;
  isHorizontal?: boolean;
};

export const ZoomRange: React.FC<Props> = (props) => {
  return (
    <div className={props.isHorizontal ? classes.horizontalStatic : ''}>
    <span className={classes.rangeLabelStart}>+</span>
      <div className={classes.Line}>
        <input
          type='range'
          value={props.value}
          min={props.min}
          max={props.max}
          step={props.step}
          onChange={props.onChange}
          onMouseUp={props.onMouseUp}
          onTouchEnd={props.onTouchEnd}
          className={classNames(
            props.className,
            classes.Range,
            props.withoutStrip ? classes.withoutStrip : '',
          )}
          style={{
            ...props.style,
          }}
        />
      </div>

      <span className={classes.rangeLabelEnd}>{props.isHorizontal ? '-' : '|'}</span>
    </div>
  );
};
