import React, { CSSProperties } from 'react';
import LogoIcon from '../../../assets/images/icons/common/logo.png';
import LogoText from '../../../assets/images/icons/common/logo-text.png';

export const Logo: React.FC = ({ style }: { style?: CSSProperties }) => {

  return (
    <div className='flex justify-center items-center' style={style}>
      <img src={LogoIcon} alt='two-in-town' className='w-[26.8px] md:w-[26.8px] mr-2'/>
      <img src={LogoText} alt='logo-text' className='hidden w-[134.76px] md:w-[134.76px] md:block'/>
    </div>
  );
}
