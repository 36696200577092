import { NameplateMaterialType } from '../hooks';

export const getWeightInGrams = (areaInMM: number, material: NameplateMaterialType) => {
  let heightInMM = 0;
  let density = 0;

  if (material === NameplateMaterialType.GOLD) {
    heightInMM = 0.7;
    density = 19.3 / 1000; // g/mm³
  } else if (material === NameplateMaterialType.SILVER) {
    heightInMM = 1.09;
    density = 0.010078325530454159 //10.5 / 1000; // g/mm³
  } else if (material === NameplateMaterialType.ROSE_GOLD) {
    heightInMM = 0.7;
    density = 19.3 / 1000; // g/mm³
  }
  return areaInMM * heightInMM * density;
};

export const getWeightInOunces = (areaInMM: number, material: NameplateMaterialType): number => {
  const grams = getWeightInGrams(areaInMM, material);
  return grams * 0.03527396; // 1 gram = 0.03527396 ounces
};

export const getMaterialNumber = (material: NameplateMaterialType): number => {
  switch (material) {
    case NameplateMaterialType.GOLD:
      return 1;
    case NameplateMaterialType.ROSE_GOLD:
      return 2;
    case NameplateMaterialType.SILVER:
      return 3;
  }
};

export const getMaterialNameByNumber = (materialNum: number): NameplateMaterialType => {
  switch (materialNum) {
    case 1:
      return NameplateMaterialType.GOLD;
    case 2:
      return NameplateMaterialType.ROSE_GOLD;
    case 3:
      return NameplateMaterialType.SILVER;
    default:
      return NameplateMaterialType.ROSE_GOLD;
  }
};

export const getMaterialHeight = (material: NameplateMaterialType): number => {
  switch (material) {
    case NameplateMaterialType.GOLD:
      return 0.7;
    case NameplateMaterialType.ROSE_GOLD:
      return 0.7;
    case NameplateMaterialType.SILVER:
      return 1;
  }
};
