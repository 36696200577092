import * as PIXI from 'pixi.js';
import { PendantCanvasApp } from './PendantCanvasApp';
import { canvasToSvg, scaleSvgPath, strokeImage, SvgExportData } from '../common/canvasToSvg';
import {
  getPendantTrimData,
  getPendantWidthMm,
  pendantSizeByShape,
} from '../../hooks/pendant/use-pendant-settings/pendants';
import { addWhiteBg } from '../common/addWhiteBg';
import { PendantMaterialType, PendantSizeType, RulerUnits } from '../../hooks';
import { drawCropVariant, drawPendantArea } from '../common/drawPendantArea';
import * as uuid from 'uuid';
import { CropVariant } from '../nameplate-canvas-app/backgrounds';

export class PendantCanvasAppExporter {
  private static app: PendantCanvasApp | null;

  static setApp(app: PendantCanvasApp) {
    this.app = app;
  }

  static async export(): Promise<{
    previewBase64: string;
    pendant: SvgExportData;
    engraving: SvgExportData;
    engravingPosition: { x: number; y: number };
    previewVariants: any;
  } | null> {
    if (!this.app) {
      return null;
    }

    const app = this.app;

    const parent = app.layout.pendant.parent;
    const index = parent.getChildIndex(app.layout.pendant);

    const scale = app.layout.pendant.scale.clone();
    app.layout.pendant.scale.set(1);

    const container = new PIXI.Container();
    container.addChild(app.layout.pendant);

    const previewBase64 = app.renderer.plugins.extract.base64(container);

    app.layout.chainsRing.visible = false;

    const engraving = this.generateEngravingSvg(this.app, container);
    const pendant = this.generatePendantSvg(this.app, container);

    app.layout.pendant.scale.copyFrom(scale);
    parent.addChildAt(app.layout.pendant, index);

    const engravingData = await canvasToSvg(engraving);
    const pendantData = await canvasToSvg(pendant);

    {
      const props = app.layout.props!;
      const widthMm = getPendantWidthMm(props.shape, props.messageShape, props.pendantSize);
      const svgScale = widthMm / pendantData.bounds.width;

      scaleSvgPath(engravingData, svgScale);
      scaleSvgPath(pendantData, svgScale);
    }

    const pendantCenterX = pendantData.bounds.x + pendantData.bounds.width / 2;
    const pendantCenterY = pendantData.bounds.y + pendantData.bounds.height / 2;

    const engravingCenterX = engravingData.bounds.x + engravingData.bounds.width / 2;
    const engravingCenterY = engravingData.bounds.y + engravingData.bounds.height / 2;

    app.layout.chainsRing.visible = true;

    const previewVariants = await this.generatePreviewVariants();

    return {
      previewBase64,
      engraving: engravingData,
      pendant: pendantData,
      engravingPosition: {
        x: engravingCenterX - pendantCenterX,
        y: -(engravingCenterY - pendantCenterY),
      },
      previewVariants,
    };
  }

  private static generatePendantSvg(
    app: PendantCanvasApp,
    pendantContainer: PIXI.Container,
  ): HTMLCanvasElement {
    app.layout.pendant.scale.set(1);
    const canvas = app.renderer.plugins.extract.canvas(pendantContainer);
    return strokeImage(canvas);
  }

  private static generateEngravingSvg(
    app: PendantCanvasApp,
    pendantContainer: PIXI.Container,
  ): HTMLCanvasElement {
    const pendantTexture = app.layout.pendant.texture;
    const imageTexture = app.layout.pendantImage.texture;

    const emptyTexture = PIXI.RenderTexture.create({
      width: pendantTexture.width,
      height: pendantTexture.height,
    });

    app.layout.pendant.texture = emptyTexture;
    app.layout.pendantImage.texture = emptyTexture;

    const canvas = app.renderer.plugins.extract.canvas(pendantContainer);

    app.layout.pendant.texture = pendantTexture;
    app.layout.pendantImage.texture = imageTexture;

    return strokeImage(canvas);
  }

  private static async generatePreviewVariants() {
    const app = this.app;

    if (!app) return;

    const props = app.layout.props!;
    const update = app.update.bind(app);
    app.update = async () => void 0;
    app.layout.transformViewContainer.visible = false;

    const result: any = {};

    // 2b. Size M without ruler Gold and Silver
    {
      const possibleSizes = Object.entries(
        pendantSizeByShape[props.shape][props.messageShape] ?? {},
      )
        .filter(([size, value]) => value)
        .map(([size]) => size as unknown as PendantSizeType);

      for (const material of [PendantMaterialType.GOLD, PendantMaterialType.SILVER]) {
        for (const size of possibleSizes) {
          await update({
            ...props,
            zoom: 0.5,
            pendantMaterial: material,
            pendantSize: size,
            rulerIsEnabled: false,
          });

          const canvas = drawCropVariant(app, props.backgroundName, CropVariant.Big);
          result[`portrait${material}${size}`] = canvas.toDataURL();
        }
      }
    }

    // 1. white bg Gold and Silver
    {
      app.layout.bg.slicesContainer.alpha = 0;

      for (const material of [PendantMaterialType.GOLD, PendantMaterialType.SILVER]) {
        await update({
          ...props,
          zoom: 0.5,
          pendantMaterial: material,
          rulerIsEnabled: false,
        });

        const canvas = drawPendantArea(app, props.backgroundName);
        const whiteCanvas = addWhiteBg(canvas);

        result[`whiteBg${material}`] = whiteCanvas.toDataURL();
      }

      app.layout.bg.slicesContainer.alpha = 1;
    }

    // // 1b. white bg Gold and Silver Full chain
    // {
    //   app.layout.bg.slicesContainer.visible = false;
    //
    //   for (const material of [PendantMaterialType.GOLD, PendantMaterialType.SILVER]) {
    //     await update({
    //       ...props,
    //       zoom: 0.5,
    //       pendantMaterial: material,
    //       rulerIsEnabled: false,
    //     });
    //
    //     const canvas = app.renderer.plugins.extract.canvas(app.stage);
    //     const whiteCanvas = addWhiteBg(canvas);
    //
    //     result[`whiteBgFullChain${material}`] = whiteCanvas.toDataURL();
    //   }
    //
    //   app.layout.bg.slicesContainer.visible = true;
    // }

    // 1с. white bg Gold and Silver with Corporate stamp
    {
      app.layout.bg.slicesContainer.alpha = 0;

      const trimData = getPendantTrimData(props.shape, props.messageShape, props.pendantSize);
      const bottom = trimData.frameHeight / 2;

      for (const material of [
        PendantMaterialType.GOLD,
        PendantMaterialType.SILVER,
        // fix font not loaded first time
        PendantMaterialType.GOLD,
      ]) {
        await update({
          ...props,
          zoom: 0.5,
          pendantMaterial: material,
          rulerIsEnabled: false,
          labels: [
            {
              id: uuid.v4(),
              text: '925',
              textPosition: {
                x: 0,
                y: bottom - 70,
              },
              textDeformation: 0,
              fontWeight: '400',
              fontStyle: 'normal',
              textAlign: 'center',
              font: 'Arial',
              fontSize: 0.125,
              letterSpacing: 0,
              lineHeight: 175,
              textRotation: 0,
              textSize: 1,
            },
            {
              id: uuid.v4(),
              text: 'Two in Town',
              textPosition: {
                x: 0,
                y: bottom - 150,
              },
              textDeformation: 0,
              fontWeight: '400',
              fontStyle: 'normal',
              textAlign: 'center',
              font: 'Caveat',
              fontSize: 0.19,
              letterSpacing: 0,
              lineHeight: 175,
              textRotation: 0,
              textSize: 1,
            },
          ],
          clipArts: [],
          image: null,
        });

        const canvas = drawPendantArea(app, props.backgroundName);
        const whiteCanvas = addWhiteBg(canvas);

        result[`whiteBgWithStamp${material}`] = whiteCanvas.toDataURL();
      }

      app.layout.bg.slicesContainer.alpha = 1;
    }

    // 2a. All sizes with ruler Gold and Silver
    {
      const possibleSizes = Object.entries(
        pendantSizeByShape[props.shape][props.messageShape] ?? {},
      )
        .filter(([size, value]) => value)
        .map(([size]) => size as unknown as PendantSizeType);

      for (const material of [PendantMaterialType.GOLD, PendantMaterialType.SILVER]) {
        for (const size of possibleSizes) {
          await update({
            ...props,
            zoom: 0.5,
            pendantMaterial: material,
            pendantSize: size,
            rulerMode: RulerUnits.CM,
            rulerIsEnabled: true,
          });

          const canvas = drawCropVariant(app, props.backgroundName, CropVariant.Middle);
          result[`withRuler${material}${size}`] = canvas.toDataURL();
        }
      }
    }

    // // 3. Gold and silver cut model head
    // {
    //   for (const material of [PendantMaterialType.GOLD, PendantMaterialType.SILVER]) {
    //     await update({
    //       ...props,
    //       zoom: 0.5,
    //       pendantMaterial: material,
    //       pendantSize: props.pendantSize,
    //       rulerIsEnabled: false,
    //     });
    //
    //     const canvas = drawCropVariant(app, props.backgroundName, CropVariant.Middle);
    //     result[`cutHead${material}`] = canvas.toDataURL();
    //   }
    // }

    await update(props);

    app.update = update;
    app.layout.transformViewContainer.visible = true;

    return result;
  }
}

// window.test = async () => {
//   const { previewVariants } = (await PendantCanvasAppExporter.export())!;
//
//   Object.entries(previewVariants).forEach(([name, base64]) => {
//     const image = new Image();
//     image.src = base64 as string;
//
//     const div = document.createElement('div');
//     div.innerText = name;
//     document.body.appendChild(div);
//     document.body.appendChild(image);
//     document.getElementById('root')!.style.visibility = 'hidden';
//   });
// };
