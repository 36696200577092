import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';
import * as uuid from 'uuid';
import { textHasLineBreak } from '../../../utils/text';

import {
  PendantContextType,
  LabelProps,
  PendantImageFilterType,
  PendantMaterialType,
  PendantMessageShape,
  PendantSelectedItem,
  PendantSelectedItemType,
  PendantShapeType,
  PendantSizeType,
  PendantState,
} from './types';
import { patchSnapshot, redo, saveSnapshot, undo } from './snapshots';
import { getPendantPrice } from '../../../hooks';
import { DEFAULT_PRICE } from '../../use-common-state';
import { Constructor, defaultPendantConstructor, testConstructorRootElement } from './constructor';

export const PendantSettings = createContext({} as PendantContextType);

enum ActionType {
  SAVE_SNAPSHOT = 'save_snapshot',
  PATCH_SNAPSHOT = 'patch_snapshot',
  UNDO = 'undo',
  REDO = 'redo',
  SET_CURRENT_ELEMENT = 'set_current_element',
  SET_PRICE = 'set_price',
  SET_SHAPE = 'set_shape',
  SET_MESSAGE_SHAPE = 'set_message_shape',
  SET_SELECTED_MATERIAL = 'set_selected_material',
  SET_SELECTED_SIZE = 'set_selected_size',
  SET_NEW_TEXT = 'set_new_text',
  REMOVE_TEXT = 'REMOVE_TEXT',
  SET_CURRENT_TEXT = 'set_current_text',
  SET_IMAGE_POSITION = 'set_image_position',
  SET_TEXT_POSITION = 'set_text_position',
  SET_TEXT = 'set_text',
  SET_TEXT_SIZE = 'set_text_size',
  SET_TEXT_DEFORMATION = 'set_text_deformation',
  SET_FONT_WEIGHT = 'set_font_weight',
  SET_FONT_STYLE = 'set_font_style',
  SET_TEXT_ALIGN = 'set_text_align',
  SET_FONT = 'set_font',
  SET_FONT_SIZE = 'set_font_size',
  SET_LETTER_SPACING = 'set_letter_spacing',
  SET_LINE_HEIGHT = 'set_line_height',
  SET_TEXT_ROTATION = 'set_text_rotation',
  SET_IMAGE = 'set_image',
  SET_IMAGE_ROTATION = 'set_image_rotation',
  SET_IMAGE_SIZE = 'set_image_size',
  SET_IMAGE_FILTER = 'set_image_filter',
  SET_ICONS = 'set_icons',
  REMOVE_ICON = 'remove_icon',
  SET_CURRENT_ICON = 'set_current_icon',
  SET_ICON_POSITION = 'set_icon_position',
  SET_ICON_SCALE = 'set_icon_scale',
  SET_ICON_ROTATION = 'set_icon_rotation',
  SET_AUTO_ADAPT_SHAPE_ENABLED = 'set_auto_adapt_shape_enabled',
  SET_SHAPE_AUTO_ADAPTED = 'set_shape_auto_adapted',
  SET_TEXTURES_LOADING = 'set_textures_loading',
}

export const createLabelState = (props: { id?: string; text?: string }): LabelProps => {
  const { id = uuid.v4(), text = 'Love' } = props;

  return {
    id,
    text,
    textPosition: { x: 0, y: 0 },
    textDeformation: 0, // text curving
    fontWeight: '400',
    fontStyle: 'normal',
    textAlign: 'center',
    font: 'Arial',
    fontSize: 0.7,
    letterSpacing: 0,
    lineHeight: 175,
    textRotation: 0,
    textSize: 1, // text stretching
  };
};

const defaultShape = PendantShapeType.CIRCLE;
const defaultMessageShape = PendantMessageShape.Normal;
const defaultSize = PendantSizeType.MEDIUM;

const initialLabelState = createLabelState({});

const initialState: PendantState = {
  price: {
    data: DEFAULT_PRICE,
    isLoading: true,
  },
  shape: defaultShape,
  messageShape: defaultMessageShape,
  selectedMaterial: PendantMaterialType.GOLD,
  selectedSize: defaultSize,
  autoAdaptShapeEnabled: false,
  shapeAutoAdapted: false,
  currentText: initialLabelState.id,
  labelById: { [initialLabelState.id]: initialLabelState },
  img: {
    image: null,
    imagePosition: { x: 0, y: 0 },
    imageRotation: 0,
    imageSize: 1,
    imageFilter: PendantImageFilterType.NoFilter,
  },
  iconById: {},
  currentIcon: null,
  texturesLoading: true,
  isSnapshot: false,
  snapshotIndex: 0,
  snapshots: [],
  currentElement: testConstructorRootElement,
};
initialState.snapshots.push(initialState);

const patchLabel = (state: PendantState, id: string, patch: Partial<LabelProps>): PendantState => {
  const label = state.labelById[id];

  if (
    !label ||
    (Object.keys(patch) as unknown as (keyof LabelProps)[]).every(
      (key) => label[key] === patch[key],
    )
  ) {
    return state;
  }

  return {
    ...state,
    labelById: {
      ...state.labelById,
      [id]: {
        ...state.labelById[id],
        ...patch,
      },
    },
  } as PendantState;
};

function reducer(state: PendantState, action: { type: ActionType; value: any; id?: any }): PendantState {
  state = { ...state, isSnapshot: false };

  switch (action.type) {
    case ActionType.SAVE_SNAPSHOT:
      return saveSnapshot(state);
    case ActionType.PATCH_SNAPSHOT:
      return patchSnapshot(state);
    case ActionType.UNDO:
      return undo(state);
    case ActionType.REDO:
      return redo(state);
    case ActionType.SET_CURRENT_ELEMENT:
      return { ...state, currentElement: action.value };
    case ActionType.SET_PRICE: {
      return { ...state, price: action.value };
    }
    case ActionType.SET_SHAPE: {
      return { ...state, shape: action.value };
    }
    case ActionType.SET_MESSAGE_SHAPE: {
      return { ...state, messageShape: action.value };
    }
    case ActionType.SET_SELECTED_MATERIAL: {
      return { ...state, selectedMaterial: action.value };
    }
    case ActionType.SET_SELECTED_SIZE: {
      return { ...state, selectedSize: action.value };
    }
    case ActionType.SET_NEW_TEXT: {
      const labelState = createLabelState({
        id: action.value,
      });

      return {
        ...state,
        labelById: {
          ...state.labelById,
          [labelState.id]: labelState,
        },
      };
    }
    case ActionType.REMOVE_TEXT: {
      const labelById = { ...state.labelById };

      if (Object.keys(labelById).length > 1) {
        delete labelById[action.id];

        return {
          ...state,
          labelById,
          currentText: Object.keys(labelById)[Object.keys(labelById).length - 1],
        };
      }

      return {
        ...state,
        currentText: action.id,
        labelById: {
          ...state.labelById,
          [action.id]: createLabelState({
            id: action.id,
            text: ' ',
          }),
        },
      };
    }
    case ActionType.SET_CURRENT_TEXT: {
      return { ...state, currentText: action.value };
    }
    case ActionType.SET_AUTO_ADAPT_SHAPE_ENABLED: {
      return { ...state, autoAdaptShapeEnabled: action.value };
    }
    case ActionType.SET_SHAPE_AUTO_ADAPTED: {
      return { ...state, shapeAutoAdapted: action.value };
    }
    case ActionType.SET_TEXT: {
      return patchLabel(state, action.id, { text: action.value });
    }
    case ActionType.SET_TEXT_SIZE: {
      return patchLabel(state, action.id, { textSize: action.value });
    }
    case ActionType.SET_TEXT_POSITION: {
      return patchLabel(state, action.id, { textPosition: action.value });
    }
    case ActionType.SET_TEXT_DEFORMATION: {
      return patchLabel(state, action.id, { textDeformation: action.value });
    }
    case ActionType.SET_FONT_WEIGHT: {
      return patchLabel(state, action.id, { fontWeight: action.value });
    }
    case ActionType.SET_FONT_STYLE: {
      return patchLabel(state, action.id, { fontStyle: action.value });
    }
    case ActionType.SET_TEXT_ALIGN: {
      return patchLabel(state, action.id, { textAlign: action.value });
    }
    case ActionType.SET_FONT_SIZE: {
      return patchLabel(state, action.id, { fontSize: action.value });
    }
    case ActionType.SET_FONT: {
      return patchLabel(state, action.id, { font: action.value });
    }
    case ActionType.SET_LETTER_SPACING: {
      return patchLabel(state, action.id, { letterSpacing: action.value });
    }
    case ActionType.SET_LINE_HEIGHT: {
      return patchLabel(state, action.id, { lineHeight: action.value });
    }
    case ActionType.SET_TEXT_ROTATION: {
      return patchLabel(state, action.id, { textRotation: action.value });
    }
    case ActionType.SET_IMAGE: {
      return {
        ...state,
        img: { ...state.img, image: action.value },
      };
    }
    case ActionType.SET_IMAGE_POSITION: {
      return {
        ...state,
        img: { ...state.img, imagePosition: action.value },
      };
    }
    case ActionType.SET_IMAGE_ROTATION: {
      return {
        ...state,
        img: { ...state.img, imageRotation: action.value },
      };
    }
    case ActionType.SET_IMAGE_SIZE: {
      return {
        ...state,
        img: { ...state.img, imageSize: action.value },
      };
    }
    case ActionType.SET_IMAGE_FILTER: {
      return {
        ...state,
        img: { ...state.img, imageFilter: action.value },
      };
    }
    case ActionType.SET_TEXTURES_LOADING: {
      return { ...state, texturesLoading: action.value };
    }
    case ActionType.SET_ICONS: {
      return { ...state, iconById: action.value };
    }
    case ActionType.SET_CURRENT_ICON: {
      return { ...state, currentIcon: action.value };
    }
    case ActionType.REMOVE_ICON: {
      const iconById = { ...state.iconById };

      if (Object.keys(iconById).length > 1) {
        delete iconById[action.id];

        return {
          ...state,
          iconById,
          currentIcon: Object.keys(iconById)[Object.keys(iconById).length - 1],
        };
      }

      return {
        ...state,
        iconById: {},
        currentIcon: null,
      };
    }
    case ActionType.SET_ICON_POSITION: {
      return {
        ...state,
        iconById: {
          ...state.iconById,
          [action.value.id]: {
            ...state.iconById[action.value.id],
            position: action.value.position,
          },
        },
      };
    }
    case ActionType.SET_ICON_SCALE: {
      return {
        ...state,
        iconById: {
          ...state.iconById,
          [action.value.id]: {
            ...state.iconById[action.value.id],
            scale: action.value.scale,
          },
        },
      };
    }
    case ActionType.SET_ICON_ROTATION: {
      return {
        ...state,
        iconById: {
          ...state.iconById,
          [action.value.id]: {
            ...state.iconById[action.value.id],
            rotation: action.value.rotation,
          },
        },
      };
    }

    default: {
      throw new Error('Used unsupported action type');
    }
  }
}

export const PendantSettingsContextProvider: React.FC<{ children: React.ReactElement }> = ({
  children,
}) => {
  const [state, dispatch]: [PendantState, any] = useReducer(reducer, initialState);
  const [constructor] = useState<{ config: Constructor; isLoading: boolean }>({
    config: defaultPendantConstructor,
    isLoading: false,
  });

  useEffect(() => {
    handleSetPrice(DEFAULT_PRICE, true);
    const init = async () => {
      const price = await getPendantPrice(
        state.selectedMaterial,
        state.shape,
        state.messageShape,
        state.selectedSize,
      );
      handleSetPrice(price, false);
    };
    init();
  }, [
    state.selectedMaterial,
    state.shape,
    state.messageShape,
    state.selectedSize,
    state.snapshotIndex,
  ]);

  const handleUndo = () => dispatch({ type: ActionType.UNDO });
  const handleRedo = () => dispatch({ type: ActionType.REDO });
  const handleSaveSnapshot = () => dispatch({ type: ActionType.SAVE_SNAPSHOT });
  const handlePatchSnapshot = () => dispatch({ type: ActionType.PATCH_SNAPSHOT });

  const handleSetCurrentElementByShape = React.useCallback(
    (shape: PendantShapeType) => {
      const element = constructor.config.elements.find((el) => el.shape === shape);
      dispatch({ type: ActionType.SET_CURRENT_ELEMENT, value: element });
    },
    [constructor],
  );

  const handleClearState = React.useCallback(() => {
    dispatch({ type: ActionType.SET_SHAPE, value: PendantShapeType.CIRCLE });
    dispatch({ type: ActionType.SET_MESSAGE_SHAPE, value: PendantMessageShape.Normal });
    dispatch({ type: ActionType.SAVE_SNAPSHOT });
  }, []);

  const handleSetPrice = React.useCallback((price: number, isLoading = false) => {
    dispatch({ type: ActionType.SET_PRICE, value: { data: price, isLoading } });
  }, []);

  const handleSelectShape = React.useCallback(
    (s: PendantShapeType, saveSnapshot = true) => {
      if (state.shape !== s) {
        dispatch({ type: ActionType.SET_MESSAGE_SHAPE, value: PendantMessageShape.Normal });
        dispatch({ type: ActionType.SET_SELECTED_SIZE, value: PendantSizeType.MEDIUM });
        dispatch({
          type: ActionType.SET_PRICE,
          value: getPendantPrice(
            state.selectedMaterial,
            s,
            PendantMessageShape.Normal,
            PendantSizeType.MEDIUM,
          ),
        });
        dispatch({ type: ActionType.SET_SHAPE, value: s });
        handleSetCurrentElementByShape(s);
        if (saveSnapshot) {
          dispatch({ type: ActionType.SAVE_SNAPSHOT });
        }
      }
    },
    [state],
  );

  const handleSelectMessageShape = React.useCallback(
    (s: PendantMessageShape) => {
      dispatch({ type: ActionType.SET_SELECTED_SIZE, value: PendantSizeType.MEDIUM });
      dispatch({ type: ActionType.SET_MESSAGE_SHAPE, value: s });
      dispatch({
        type: ActionType.SET_PRICE,
        value: getPendantPrice(state.selectedMaterial, state.shape, s, PendantSizeType.MEDIUM),
      });
      dispatch({ type: ActionType.SAVE_SNAPSHOT });
    },
    [state],
  );

  const handleSelectMaterial = React.useCallback(
    (material: PendantMaterialType, saveSnapshot = true) => {
      if (state.selectedMaterial !== material) {
        dispatch({ type: ActionType.SET_SELECTED_MATERIAL, value: material });
        dispatch({
          type: ActionType.SET_PRICE,
          value: getPendantPrice(material, state.shape, state.messageShape, state.selectedSize),
        });
        if (saveSnapshot) {
          dispatch({ type: ActionType.SAVE_SNAPSHOT });
        }
      }
    },
    [state],
  );

  const handleSelectSize = React.useCallback(
    (size: PendantSizeType) => {
      dispatch({ type: ActionType.SET_SELECTED_SIZE, value: size });
      dispatch({
        type: ActionType.SET_PRICE,
        value: getPendantPrice(state.selectedMaterial, state.shape, state.messageShape, size),
      });
      dispatch({ type: ActionType.SAVE_SNAPSHOT });
    },
    [state],
  );

  const handleCreateText = React.useCallback((newTextId: string) => {
    dispatch({ type: ActionType.SET_NEW_TEXT, value: newTextId });
    dispatch({ type: ActionType.SAVE_SNAPSHOT });
  }, []);

  const handleSelectCurrentItem = React.useCallback(
    (item: PendantSelectedItem | null) => {
      if (item && item.type === PendantSelectedItemType.Label && state.currentText !== item.id) {
        dispatch({ type: ActionType.SET_CURRENT_TEXT, value: item.id });
        dispatch({ type: ActionType.SAVE_SNAPSHOT });
      }
      if (item && item.type === PendantSelectedItemType.Icon && state.currentIcon !== item.id) {
        dispatch({ type: ActionType.SET_CURRENT_ICON, value: item.id });
      }
    },
    [state],
  );

  const handleSelectImagePosition = React.useCallback(
    (position: { x: number; y: number } | null) => {
      const value = position || { x: 0, y: 0 };
      dispatch({ type: ActionType.SET_IMAGE_POSITION, value });
    },
    [],
  );

  const handleSelectTextPosition = React.useCallback(
    (id: string, position: { x: number; y: number } | null) => {
      const value = position || { x: 0, y: 0 };
      dispatch({ type: ActionType.SET_TEXT_POSITION, value, id });
    },
    [],
  );

  const handleChangeText = React.useCallback(
    (id: string, text: string, saveSnapshot = true) => {
      if (state.labelById && state.labelById[id]?.text === text) {
        return;
      }

      dispatch({ type: ActionType.SET_TEXT, value: text, id });
      if (!textHasLineBreak(text)) {
        handleChangeLineHeight(id, 175);
      }
      if (saveSnapshot) {
        dispatch({ type: ActionType.SAVE_SNAPSHOT });
      }
    },
    [state],
  );

  const handleChangeTextSize = React.useCallback((id: string, size: number) => {
    dispatch({ type: ActionType.SET_TEXT_SIZE, value: size, id });
  }, []);

  const handleChangeFontWeight = React.useCallback((id: string, fontWeight: string) => {
    dispatch({ type: ActionType.SET_FONT_WEIGHT, value: fontWeight, id });
    dispatch({ type: ActionType.SAVE_SNAPSHOT });
  }, []);

  const handleChangeFontStyle = React.useCallback((id: string, fontStyle: string) => {
    dispatch({ type: ActionType.SET_FONT_STYLE, value: fontStyle, id });
    dispatch({ type: ActionType.SAVE_SNAPSHOT });
  }, []);

  const handleChangeTextAlign = React.useCallback((id: string, textAlign: string) => {
    dispatch({ type: ActionType.SET_TEXT_ALIGN, value: textAlign, id });
    dispatch({ type: ActionType.SAVE_SNAPSHOT });
  }, []);

  const handleChangeFont = React.useCallback((id: string, font: string) => {
    dispatch({ type: ActionType.SET_FONT, value: font, id });
    dispatch({ type: ActionType.SAVE_SNAPSHOT });
  }, []);

  const handleChangeTextDeformation = React.useCallback((id: string, deformation: number) => {
    dispatch({ type: ActionType.SET_TEXT_DEFORMATION, value: deformation, id });
  }, []);

  const handleChangeFontSize = React.useCallback((id: string, size: number) => {
    dispatch({ type: ActionType.SET_FONT_SIZE, value: size, id });
  }, []);

  const handleChangeLetterSpacing = React.useCallback((id: string, space: number) => {
    dispatch({ type: ActionType.SET_LETTER_SPACING, value: space, id });
  }, []);

  const handleChangeLineHeight = React.useCallback((id: string, space: number) => {
    dispatch({ type: ActionType.SET_LINE_HEIGHT, value: space, id });
  }, []);

  const handleSelectTextRotation = React.useCallback((id: string, rotation: number) => {
    dispatch({ type: ActionType.SET_TEXT_ROTATION, value: rotation, id });
  }, []);

  const handleTextRemove = React.useCallback((id: string) => {
    dispatch({ type: ActionType.REMOVE_TEXT, id });
    dispatch({ type: ActionType.SAVE_SNAPSHOT });
  }, []);

  const handleSelectImage = React.useCallback((image: HTMLImageElement | null) => {
    dispatch({ type: ActionType.SET_IMAGE, value: image });
    dispatch({ type: ActionType.SAVE_SNAPSHOT });
  }, []);

  const handleSelectImageRotation = React.useCallback((rotation: number) => {
    dispatch({ type: ActionType.SET_IMAGE_ROTATION, value: rotation });
  }, []);

  const handleSelectImageSize = React.useCallback((size: number) => {
    dispatch({ type: ActionType.SET_IMAGE_SIZE, value: size });
  }, []);

  const handleSelectImageFilter = React.useCallback((filter: PendantImageFilterType) => {
    dispatch({ type: ActionType.SET_IMAGE_FILTER, value: filter });
    dispatch({ type: ActionType.SAVE_SNAPSHOT });
  }, []);

  const handleSelectAutoAdaptShape = React.useCallback((value: boolean) => {
    dispatch({ type: ActionType.SET_AUTO_ADAPT_SHAPE_ENABLED, value });

    if (!value) {
      dispatch({ type: ActionType.SET_SHAPE_AUTO_ADAPTED, value });
    }
  }, []);

  const handleSelectShapeAutoAdapted = React.useCallback((value: boolean) => {
    dispatch({ type: ActionType.SET_SHAPE_AUTO_ADAPTED, value });
  }, []);

  const handleChangeTexturesLoading = React.useCallback((value: boolean) => {
    dispatch({ type: ActionType.SET_TEXTURES_LOADING, value });
  }, []);

  const handleAddIcon = React.useCallback(
    (newId: string, svgString: string) => {
      const icons = {
        ...state.iconById,
        [newId]: {
          id: newId,
          image: svgString,
          position: { x: 0, y: 0 },
          scale: 0.5,
          rotation: 0,
        },
      };
      dispatch({ type: ActionType.SET_ICONS, value: icons });
      dispatch({ type: ActionType.SET_CURRENT_ICON, value: newId });
      dispatch({ type: ActionType.SAVE_SNAPSHOT });
    },
    [state.iconById, state.shape],
  );

  const handleRemoveIcon = React.useCallback(
    (id: string) => {
      dispatch({ type: ActionType.REMOVE_ICON, id });
      dispatch({ type: ActionType.SAVE_SNAPSHOT });
    },
    [state.iconById],
  );

  const handleChangeIconPosition = React.useCallback(
    (id: string, position: { x: number; y: number }) => {
      dispatch({ type: ActionType.SET_ICON_POSITION, value: { id, position } });
    },
    [state.iconById],
  );

  const handleChangeIconScale = React.useCallback(
    (id: string, scale: number) => {
      dispatch({ type: ActionType.SET_ICON_SCALE, value: { id, scale } });
    },
    [state.iconById],
  );

  const handleChangeIconRotation = React.useCallback(
    (id: string, rotation: number) => {
      dispatch({ type: ActionType.SET_ICON_ROTATION, value: { id, rotation } });
    },
    [state.iconById],
  );

  return (
    <PendantSettings.Provider
      value={{
        state,
        price: state.price,
        shape: state.shape,
        messageShape: state.messageShape,
        selectedMaterial: state.selectedMaterial,
        selectedSize: state.selectedSize,
        currentText: state.currentText,
        labelById: state.labelById,
        labelsNum: Object.keys(state.labelById).length,
        image: state.img.image,
        imagePosition: state.img.imagePosition,
        imageRotation: state.img.imageRotation,
        imageSize: state.img.imageSize,
        imageFilter: state.img.imageFilter,
        autoAdaptShapeEnabled: state.autoAdaptShapeEnabled,
        shapeAutoAdapted: state.autoAdaptShapeEnabled,
        iconById: state.iconById,
        currentIcon: state.currentIcon,
        texturesLoading: state.texturesLoading,
        onChangeAutoAdaptShape: handleSelectAutoAdaptShape,
        onChangeShapeAutoAdapted: handleSelectShapeAutoAdapted,
        clearState: handleClearState,
        onSetPrice: handleSetPrice,
        onSelectShape: handleSelectShape,
        onSelectMessageShape: handleSelectMessageShape,
        onSelectMaterial: handleSelectMaterial,
        onSelectSize: handleSelectSize,
        onCreateText: handleCreateText,
        onSetSelectedItem: handleSelectCurrentItem,
        onChangeImagePosition: handleSelectImagePosition,
        onChangeText: handleChangeText,
        onChangeFontWeight: handleChangeFontWeight,
        onChangeFontStyle: handleChangeFontStyle,
        onChangeTextAlign: handleChangeTextAlign,
        onChangeFont: handleChangeFont,
        onChangeTextPosition: handleSelectTextPosition,
        onChangeFontSize: handleChangeFontSize,
        onChangeLetterSpacing: handleChangeLetterSpacing,
        onChangeLineHeight: handleChangeLineHeight,
        onChangeTextRotation: handleSelectTextRotation,
        onChangeTextSize: handleChangeTextSize,
        onChangeTextDeformation: handleChangeTextDeformation,
        onTextRemove: handleTextRemove,
        onChangeImage: handleSelectImage,
        onChangeImageRotation: handleSelectImageRotation,
        onChangeImageSize: handleSelectImageSize,
        onChangeImageFilter: handleSelectImageFilter,
        onChangeTexturesLoading: handleChangeTexturesLoading,
        onAddIcon: handleAddIcon,
        onRemoveIcon: handleRemoveIcon,
        onChangeIconPosition: handleChangeIconPosition,
        onChangeIconScale: handleChangeIconScale,
        onChangeIconRotation: handleChangeIconRotation,
        onUndo: handleUndo,
        onRedo: handleRedo,
        isSnapshot: state.isSnapshot,
        patchSnapshot: handlePatchSnapshot,
        saveSnapshot: handleSaveSnapshot,
        constructor: constructor,
        currentElement: state.currentElement,
      }}
    >
      {children}
    </PendantSettings.Provider>
  );
};

export const usePendantSettings = () => {
  const context = useContext(PendantSettings);
  return context;
};
