import svgpath from 'svgpath';

export const fileToSvgStringWithFixesAsync = async (file: File): Promise<string> => {
  const svgString = await file.text();
  const el = document.createElement('div');
  el.innerHTML = svgString;

  const pathElements = el.querySelectorAll('path');
  let d = Array.from(pathElements)
    .map((pathEl) => pathEl.getAttribute('d'))
    .join(' ');

  d = svgpath(d).scale(100).toString();

  let svg = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg">
    <path stroke="none" fill="white" fill-rule="evenodd" d="${d}"/>
</svg>`;

  el.innerHTML = svg;
  document.body.appendChild(el);

  const svgEl = el.querySelector('svg')!;
  const bounds = svgEl.getBBox();

  document.body.removeChild(el);

  d = svgpath(d).translate(-bounds.x, -bounds.y).toString();

  svg = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="${bounds.width}" height="${bounds.height}">
    <path stroke="none" fill="black" fill-rule="evenodd" d="${d}"/>
</svg>`;

  return svg;
};

export const svgToImageSrc = (svg: string): string => {
  return 'data:image/svg+xml,' + svg;
};
