import React, { useEffect, useRef, useState } from 'react';

import { Canvas } from '../../components';

import { NameplateCanvasApp } from './NameplateCanvasApp';
import { useMediaQuery } from '@mui/material';

import {
  AppMode,
  useCommonState,
  useNameplateSettings,
  useResizeHandler,
} from '../../hooks';
import { BackgroundName } from './backgrounds';
import { NameplateMaterialType, NameplateSizeLimitState, NameplateSizeType } from '../../hooks/nameplate/use-nameplate-settings/types';
import backgroundSource from '@assets/images/backgrounds/model-4/model-4.jpg'
import axios from 'axios';
import { ThreeScene } from './ThreejsCanvasComponent';
import { MobileThreeScene } from './MobileThreeksCanvasComponent';
import ZoomableDiv from './ZoomableDiv';

let timeoutId: any;

// const nameplateApiUrl = "http://localhost:3334"
const nameplateApiUrl = "https://nameplategeneratorapi.twointown.com"

export const MobileBasicNameplateCanvasComponent = () => {
  const { rulerIsEnabled, rulerMode, zoom, appMode, is3dViewEnabled } = useCommonState();
  
  const {
    fontSize,
    letterSpacing,
    font,
    text,
    textDeformation,
    selectedMaterial,
    textSize,
    texturesLoading,
    chainSize,
    onChangeText,
    onChangeFontSize,
    onChangeArea,
    onChangeBaseArea,
    onChangeBaseHeightInMM,
    onChangeBaseWidthInMM,
    onChangeWidthInMM,
    onChangeHeightInMM,
    onChangeTexturesLoading,
    onChangeSizeLimitState,
    saveSnapshot,
  } = useNameplateSettings();
  const [maxWidth, setMaxWidth] = useState(58);
  const [isLimit, setIsLimitSize] = useState(false);

  useEffect(() => {

    // Debounce function to delay the function call
    const debounce = (func: any, delay: any) => {
      return function (...args: any) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func.apply(args), delay);
      };
    };

    // Function to fetch and display image
    async function fetchAndDisplayImage() {
      try {
        console.log("axios.get price for square")
        // await axios.get(`${nameplateApiUrl}/square?text=${text}&font=${font}&color=${selectedMaterial}&fontSize=${(NameplateSizeType.SMALL / 5.75)}`).then(result => {
        //   const smallArea = result.data.totalArea
        //   console.log(smallArea)
        // })
        // await axios.get(`${nameplateApiUrl}/square?text=${text}&font=${font}&color=${selectedMaterial}&fontSize=${(NameplateSizeType.LARGE / 5.75)}`).then(result => {
        //   const largeArea = result.data.totalArea
        //   console.log(largeArea)
        // })
        await axios.get(`${nameplateApiUrl}/square?text=${text}&font=${font}&color=${selectedMaterial}&fontSize=${(5.75 / 5.75)}`).then(result => {
          console.log(result.data)
          onChangeArea(result.data.totalArea)
          onChangeBaseArea(result.data.totalArea)
          onChangeWidthInMM(result.data.widthInMM)
          onChangeHeightInMM(result.data.heightInMM)
          onChangeBaseHeightInMM(result.data.heightInMM)
          onChangeBaseWidthInMM(result.data.widthInMM)
          const percent = result.data.widthInMM / maxWidth;

          const limitState =
            percent === Infinity
              ? NameplateSizeLimitState.Empty
              : percent > 1
                ? NameplateSizeLimitState.Exceeded
                : percent >= 0.8
                  ? NameplateSizeLimitState.Warn
                  : NameplateSizeLimitState.Allowed;

          onChangeSizeLimitState(limitState);
          if (limitState === NameplateSizeLimitState.Exceeded) {
            setIsLimitSize(true)
          } else {
            setIsLimitSize(false)
          }
        })

      } catch (error) {
        console.error('Error fetching image:', error);
      }
    }

    // Call the function to fetch and display the image
    // fetchAndDisplayImage();


    // Create a debounced function to delay the request
    const debouncedFetchImageData = debounce(fetchAndDisplayImage, 500); // Delay of 0.3 seconds

    // Call the debounced function to fetch image data
    debouncedFetchImageData();
    // // Clean up the timeout on unmount
    return () => {
      clearTimeout(timeoutId);
    };

  }, [font, text, selectedMaterial])
  
  return (
    <div
      className={appMode === AppMode.CONSTRUCTOR ? 'absolute overflow-hidden' : 'absolute overflow-hidden'}
      // style={{ width: window.innerWidth }}
      style={{width: window.innerWidth, height: '100vh', display: is3dViewEnabled ? 'none' : 'block'}}
    >
      {true && (
        <div 
        style={{
          'width': '100%',
          'height': '100%',
          'position': 'absolute',
          top: 0,
        }}>
          <MobileThreeScene/>
        </div>
      )}
      {/* <img style={{position: 'absolute', left: 0, top: -100}}src={`http://localhost:3334/image?text=${text}&font=${font}&color=${selectedMaterial}&fontSize=${(fontSize/5.75)}`}></img> */}
    </div>

  );
};
