import React, { useEffect, useState } from 'react';
import classes from './Menu.module.css';

import { NameplateMenuPages, getNameplateMaterialCarat, useNamePlateMenu, useNameplateSettings } from '../../../../../hooks';
import { NameplateSizeType } from '../../../../../hooks';

import { ReactComponent as GoldMaterialIcon } from '../../../../../assets/images/icons/materials/Gold.svg';
import { ReactComponent as RoseGoldMaterialIcon } from '../../../../../assets/images/icons/materials/Rose_gold.svg';
import { ReactComponent as SilverMaterialIcon } from '../../../../../assets/images/icons/materials/Silver.svg';
import { ReactComponent as SizeIcon } from '../../../../../assets/images/icons/common/size.svg';
import { ReactComponent as ChainIcon } from '../../../../../assets/images/icons/common/chainMenu.svg';

import fonts from '../../text/fonts';
import cls from 'classnames'
  ;
const materialIcons: { [index: string]: any } = {
  Gold: <GoldMaterialIcon style={{ width: 32, height: 32 }} />,
  Rose_gold: <RoseGoldMaterialIcon style={{ width: 32, height: 32 }} />,
  Silver: <SilverMaterialIcon style={{ width: 32, height: 32 }} />,
};

export const Menu: React.FC = () => {
  const { menuPage, onSelectMenuPage } = useNamePlateMenu();
  const { selectedMaterial, fontSize, chainSize } = useNameplateSettings();
  const { text, onChangeText, font, onChangeFont, sizeLimitState } = useNameplateSettings();

  const handleSelectMaterialPage = React.useCallback(() => {
    onSelectMenuPage(NameplateMenuPages.MATERIAL);
  }, [menuPage]);

  const handleSelectSizePage = React.useCallback(() => {
    onSelectMenuPage(NameplateMenuPages.SIZE);
  }, [menuPage]);

  const handleSelectChainPage = React.useCallback(() => {
    onSelectMenuPage(NameplateMenuPages.CHAIN);
  }, [menuPage]);

  const handleSetAnderlechtFont = () => {
    handleSetFont("Anderlecht")
  }

  const handleSetLobsterFont = () => {
    handleSetFont("Lobster")
  }

  const handleSetJockyStarlineFont = () => {
    handleSetFont("JockyStarline")
  }

  const handleSetFont = (font: string) => {
    onChangeFont(font);
  };


  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyItems: 'space-between',
      gap: 0,
      alignContent: 'center',
      width: '350px',
      height: 81,
      marginTop: 24,
      marginBottom: 24,
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'center',
        alignContent: 'center',
        width: 184,
      }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyItems: 'space-between',
            alignContent: 'center',
            width: 184,
            height: 56,
            gap: 8
          }}
        >
          <div
            style={font === "Anderlecht" ? {
              width: 56,
              height: 56,
              justifyItems: 'center',
              alignContent: 'center',
              borderColor: "#3E4966",
              borderWidth: 2,
              borderRadius: 18,
              borderStyle: 'solid'
            } : {
              width: 56,
              height: 56,
              justifyItems: 'center',
              alignContent: 'center',
            }}
            onClick={handleSetAnderlechtFont}
          >
            <p style={{
              fontFamily: 'Anderlecht',
              fontSize: 17.5,
              color: "#3E4966",
              textAlign: 'center'
            }}>N</p>
          </div>
          <div
            style={font === "Lobster" ? {
              width: 56,
              height: 56,
              justifyItems: 'center',
              alignContent: 'center',
              borderColor: "#3E4966",
              borderWidth: 2,
              borderRadius: 18,
              borderStyle: 'solid'
            } : {
              width: 56,
              height: 56,
              justifyItems: 'center',
              alignContent: 'center',
            }}
            onClick={handleSetLobsterFont}
          >
            <p style={{
              fontFamily: 'Lobster',
              fontSize: 17.5,
              color: "#3E4966",
              textAlign: 'center'
            }}>N</p>
          </div>
          <div
            style={font === "JockyStarline" ? {
              width: 56,
              height: 56,
              justifyItems: 'center',
              alignContent: 'center',
              borderColor: "#3E4966",
              borderWidth: 2,
              borderRadius: 18,
              borderStyle: 'solid'
            } : {
              width: 56,
              height: 56,
              justifyItems: 'center',
              alignContent: 'center',
            }}
            onClick={handleSetJockyStarlineFont}
          >
            <p style={{
              fontFamily: 'JockyStarline',
              fontSize: 17.5,
              color: "#3E4966",
              textAlign: 'center'
            }}>N</p>
          </div>
        </div>
        <p style={{
          textAlign: 'center',
          fontWeight: 600,
          color: "#3E4966",
          fontSize: 15,
          marginTop: 12
        }}>Style: classic</p>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          // width: '100%',
        }}
      >
        <div style={{
          display: 'flex',
          width: 56,
          height: 56,
          backgroundColor: 'white',
          borderRadius: 18,
          justifyContent: 'center',
          alignItems: 'center'
        }}

          onClick={handleSelectMaterialPage}
        >
          <div style={{
            width: 32,
            height: 32,
            // backgroundColor: 'red',
            position: 'relative'
          }}>
            {materialIcons[selectedMaterial]}
            <p style={{
              color: '#3E4966', fontSize: 11, fontWeight: 600,
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}>{getNameplateMaterialCarat(selectedMaterial.toLowerCase())}</p>
          </div>
        </div>
        <p style={{
          fontWeight: 600,
          color: "#3E4966",
          fontSize: 15,
          textAlign: 'center',
          marginTop: 12,
          width: 92
        }}
        >{(selectedMaterial).replace(/_/g, ' ')}</p>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          // width: '100%',
          // marginRight: 20
        }}
      >
        <div style={{
          display: 'flex',
          width: 56,
          height: 56,
          backgroundColor: 'white',
          borderRadius: 18,
          justifyContent: 'center',
          alignItems: 'center'
        }}
          onClick={handleSelectChainPage}
        >
          <ChainIcon style={{ width: 32, height: 32 }} />
        </div>
        <p style={{
          fontWeight: 600,
          color: "#3E4966",
          fontSize: 15,
          textAlign: 'center',
          marginTop: 12,
          width: 92
        }}
        >
          {chainSize == 16 && 'Chain: 16’’'}
          {chainSize == 18 && 'Chain: 18’’'}
        </p>
      </div>
    </div>
    // <div className='flex columns-1 items-center w-full p-4 pt-2 pb-4 w-[335px]'>
    //   {/* <div> */}
    //      <div
    //         className={cls(
    //           'flex w-full justify-center items-center',
    //           'pt-0 pr-0.5',
    //         )}
    //       >
    //         {fonts.map((f, idx) => (
    //           <div
    //             className={cls(
    //               classes.styleItem,
    //               (font) === f.value ? classes.styleItemActive : '',
    //             )}
    //             key={idx}
    //             style={{ fontFamily: f.value }}
    //             onClick={() => handleSetFont(f.value)}
    //           >
    //             N
    //           </div>
    //         ))}

    //       <span className={classes.menuItemValue + ' mt-2'}>
    //         style: classic
    //       </span>
    //       </div>
    //   {/* </div> */}
    //   <div className={classes.menuItem} onClick={handleSelectMaterialPage}>
    //     <button className={classes.styleItem}>
    //       {materialIcons[selectedMaterial]}
    //     </button>
    //     <span className={classes.menuItemValue + ' mt-2'}>
    //     {getNameplateMaterialCarat(selectedMaterial.toLowerCase()) + " "} 
    //     {(selectedMaterial === 'Rose_gold' ? 'White_gold' : selectedMaterial).replace(/_/g, ' ')}
    //     </span>
    //   </div>
    //   {/* <div className={classes.menuItem} onClick={handleSelectSizePage}>
    //     <button className={classes.styleItem}>
    //       <SizeIcon/>
    //     </button>
    //     <span className={classes.menuItemValue + ' mt-2'}>
    //       {getSizeTitle()}
    //     </span>
    //   </div> */}
    //   <div className={classes.menuItem} onClick={handleSelectChainPage}>
    //     <button className={classes.styleItem}>
    //       <ChainIcon />
    //     </button>
    //     <span className={classes.menuItemValue + ' mt-2'}>
    //       {chainSize == 16 && 'Chain: 16’’'}
    //       {chainSize == 18 && 'Chain: 18’’'}
    //     </span>
    //   </div>
    // </div>
  );
};
