import { PendantMessageShape } from './types';

export type Constructor = {
  defaultLabel: string;
  materials: MaterialElement[];
  elements: ConstructorRootElement[];
};

type MaterialElement = {
  title: string;
  isDefault: boolean;
};

export type ConstructorRootElement = {
  shape: string;
  title: string;
  isDefault: boolean;
  group: string | null;
  isStatic: boolean;
  autoAdapt: boolean;
  nested: ConstructorElement[];
};

type ConstructorElement = {
  shape: string;
  sizes: string[];
  nested: ConstructorElement[] | null;
};

export const getDefaultLabel = (config: Constructor): string => {
  return config.defaultLabel;
};

export const getDefaultRootElement = (config: Constructor): ConstructorRootElement => {
  const el = config.elements.find((e) => e.isDefault);

  if (el) {
    return el;
  }
  return config.elements[0];
};

export const getDefaultMaterialElement = (config: Constructor): MaterialElement => {
  const el = config.materials.find((e) => e.isDefault);

  if (el) {
    return el;
  }
  return config.materials[0];
};

export const elementHasNestedShapes = (el: ConstructorRootElement) => {
  return !el.isStatic;
};

export const getNestedShapes = (el: ConstructorRootElement) => {
  const res: Array<any> = [];
  el.nested.map((elem, i) => {
    res.push(elem.shape);
  });
  return res;
};

export const elementHasNestedShapesOrGroup = (el: ConstructorRootElement) => {
  const hasNestedShapes = elementHasNestedShapes(el);
  const hasGroup = el.group !== null;

  return hasNestedShapes || hasGroup;
};

export const getElementsByGroup = (group: string | null, elements: ConstructorRootElement[]) => {
  return elements.filter((el) => el.group === group);
};

export const elementIsFirstOrCurrentInGroup = (
  element: ConstructorRootElement,
  currentElement: ConstructorRootElement,
  elements: ConstructorRootElement[],
) => {
  const groupExists = element.group !== null;
  const isGroupCurrent = currentElement.group === element.group && groupExists;
  const isCurrent = element.shape === currentElement.shape;
  if (isGroupCurrent && isCurrent) {
    return true;
  }

  const isFirst =
    elements.find((el) => el.group === element.group)?.shape === element.shape && groupExists;
  return isFirst && !isGroupCurrent;
};

export const getElementSizes = (
  nestedShape: PendantMessageShape,
  currentElement: ConstructorRootElement,
) => {
  return currentElement.nested.find((el) => el.shape === nestedShape)?.sizes;
};

export const testConstructorConfig: Constructor = {
  defaultLabel: 'Love',
  materials: [
    {
      title: 'Gold',
      isDefault: true,
    },
  ],
  elements: [
    {
      shape: 'circle',
      title: 'Circle',
      isDefault: true,
      group: null,
      isStatic: true,
      autoAdapt: false,
      nested: [
        {
          shape: 'Normal',
          sizes: ['XSmall', 'Small', 'Medium', 'Large', 'XLarge'],
          nested: null,
        },
      ],
    },
  ],
};

export const testConstructorRootElement: ConstructorRootElement = testConstructorConfig.elements[0];

export const defaultPendantConstructor: Constructor = {
  defaultLabel: 'Love',
  materials: [
    {
      title: 'Silver',
      isDefault: false,
    },
    {
      title: 'Gold',
      isDefault: true,
    },
    // {
    //   title: 'Rose_gold',
    //   isDefault: false,
    // },
  ],
  elements: [
    {
      shape: 'bubble',
      title: 'Message',
      isDefault: false,
      group: null,
      isStatic: false,
      autoAdapt: true,
      nested: [
        {
          shape: 'Long',
          sizes: ['Small', 'Medium', 'Large'],
          nested: null,
        },
        {
          shape: 'Normal',
          sizes: ['Small', 'Medium', 'Large'],
          nested: null,
        },
        {
          shape: 'High',
          sizes: ['Small', 'Medium', 'Large'],
          nested: null,
        },
      ],
    },
    {
      shape: 'rectangle',
      title: 'Rectangle',
      isDefault: false,
      group: null,
      isStatic: false,
      autoAdapt: true,
      nested: [
        {
          shape: 'XLong',
          sizes: ['Small', 'Medium', 'Large'],
          nested: null,
        },
        {
          shape: 'Long',
          sizes: ['XSmall', 'Small', 'Medium', 'Large', 'XLarge'],
          nested: null,
        },
        {
          shape: 'Normal',
          sizes: ['XSmall', 'Small', 'Medium', 'Large', 'XLarge'],
          nested: null,
        },
        {
          shape: 'High',
          sizes: ['XSmall', 'Small', 'Medium', 'Large', 'XLarge'],
          nested: null,
        },
        {
          shape: 'XHigh',
          sizes: ['Small', 'Medium', 'Large'],
          nested: null,
        },
      ],
    },
    {
      shape: 'heart2',
      title: 'Heart',
      isDefault: false,
      group: 'hearts',
      isStatic: true,
      autoAdapt: false,
      nested: [
        {
          shape: 'Normal',
          sizes: ['XSmall', 'Small', 'Medium', 'Large'],
          nested: null,
        },
      ],
    },
    {
      shape: 'heart',
      title: 'Heart',
      isDefault: false,
      group: 'hearts',
      isStatic: true,
      autoAdapt: false,
      nested: [
        {
          shape: 'Normal',
          sizes: ['XSmall', 'Small', 'Medium', 'Large'],
          nested: null,
        },
      ],
    },
    {
      shape: 'heart3',
      title: 'Heart',
      isDefault: false,
      group: 'hearts',
      isStatic: true,
      autoAdapt: false,
      nested: [
        {
          shape: 'Normal',
          sizes: ['XSmall', 'Small', 'Medium', 'Large'],
          nested: null,
        },
      ],
    },
    {
      shape: 'circle',
      title: 'Circle',
      isDefault: true,
      group: null,
      isStatic: true,
      autoAdapt: false,
      nested: [
        {
          shape: 'Normal',
          sizes: ['XSmall', 'Small', 'Medium', 'Large', 'XLarge'],
          nested: null,
        },
      ],
    },
    {
      shape: 'octangle',
      title: 'Octangle',
      isDefault: false,
      group: null,
      isStatic: true,
      autoAdapt: false,
      nested: [
        {
          shape: 'Normal',
          sizes: ['XSmall', 'Small', 'Medium', 'Large'],
          nested: null,
        },
      ],
    },
  ],
};
