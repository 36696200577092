import * as PIXI from 'pixi.js';
import * as TWEENS from '@tweenjs/tween.js';
import canvasRemoveIconAsset from '../../assets/images/UI/canvas-remove-icon.png';
import canvasRemoveIconLidAsset from '../../assets/images/UI/canvas-remove-icon-lid.png';
import { PendantCanvasApp, PendantCanvasAppProps } from './PendantCanvasApp';
import { Easing } from '@tweenjs/tween.js';

enum ViewState {
  Hidden = 'Hidden',
  Visible = 'Visible',
  VisibleHover = 'VisibleHover',
}

export { ViewState as PendantCanvasAppRemoveIconViewState };

const targetByViewState: Record<
  ViewState,
  {
    icon: {
      alpha: number;
      scale: { x: number; y: number };
    };
    label: {
      alpha: number;
    };
    lid: {
      angle: number;
    };
  }
> = {
  [ViewState.Hidden]: {
    icon: {
      alpha: 1,
      scale: { x: 0, y: 0 },
    },
    label: {
      alpha: 0,
    },
    lid: {
      angle: 0,
    },
  },
  [ViewState.Visible]: {
    icon: {
      alpha: 1,
      scale: { x: 1, y: 1 },
    },
    label: {
      alpha: 1,
    },
    lid: {
      angle: 0,
    },
  },
  [ViewState.VisibleHover]: {
    icon: {
      alpha: 0.8,
      scale: { x: 1.2, y: 1.2 },
    },
    label: {
      alpha: 1,
    },
    lid: {
      angle: 10,
    },
  },
};

export class PendantCanvasAppRemoveIcon {
  public readonly root: PIXI.Container;
  private readonly app: PendantCanvasApp;
  readonly icon: PIXI.Sprite;
  readonly lid: PIXI.Sprite;
  readonly label: PIXI.Text;
  private viewState: ViewState = ViewState.Hidden;

  constructor(app: PendantCanvasApp) {
    this.app = app;

    this.root = new PIXI.Container();

    this.icon = PIXI.Sprite.from(canvasRemoveIconAsset);
    this.icon.anchor.set(0.5);
    this.root.addChild(this.icon);

    this.lid = new PIXI.Sprite();
    this.lid.y = -18;
    this.lid.anchor.set(1, 0.5);
    this.icon.addChild(this.lid);

    PIXI.Texture.fromURL(canvasRemoveIconLidAsset).then((lidTexture) => {
      if (this.root.destroyed) return;

      this.lid.texture = lidTexture;
      this.lid.x = lidTexture.width / 2;
    });

    this.label = new PIXI.Text('Drag to remove', {
      fill: '#ffffff',
      fontFamily: 'Arial',
      fontSize: 20,
    });
    this.label.y = 100;
    this.label.anchor.set(0.5);
    this.root.addChild(this.label);

    {
      const target = targetByViewState[ViewState.Hidden];

      this.icon.alpha = target.icon.alpha;
      this.icon.scale.copyFrom(target.icon.scale);

      this.label.alpha = target.label.alpha;
    }
  }

  render(props: PendantCanvasAppProps, pendant: PIXI.Sprite) {
    const position = new PIXI.Point();
    this.root.parent.toLocal(position, pendant, position);

    this.root.x = position.x;
    this.root.y = this.app.screen.height - 150;
    this.root.scale.set(props.isMobile ? 1 : 0.7);

    if (this.viewState !== props.removeIconViewState) {
      this.viewState = props.removeIconViewState;
      const target = targetByViewState[props.removeIconViewState];

      const iconTween = new TWEENS.Tween(this.icon)
        .to(target.icon, 140)
        .easing(Easing.Sinusoidal.InOut)
        .start();

      const labelTween = new TWEENS.Tween(this.label)
        .to(target.label, 140)
        .easing(Easing.Sinusoidal.InOut)
        .start();

      const lidTween = new TWEENS.Tween(this.lid)
        .to(target.lid, 140)
        .easing(Easing.Sinusoidal.InOut)
        .start();

      this.app.tweens.add(iconTween);
      this.app.tweens.add(labelTween);
      this.app.tweens.add(lidTween);
    }
  }
}
