import * as PIXI from 'pixi.js';
import { BackgroundConfig, ChainSide } from './backgrounds';
import { NameplateCircle } from './NameplateCircle';
import { NameplateMaterialType } from '../../hooks/nameplate/use-nameplate-settings/types';
import { NameplateCanvasAppTextureByName } from './NameplateCanvasAppAssetsLoader';

export class NameplateChain {
  public readonly chain: PIXI.Sprite;
  private readonly side: ChainSide;

  constructor(props: { chainsContainer: PIXI.Container; side: ChainSide }) {
    this.side = props.side;

    const { chainsContainer } = props;

    this.chain = new PIXI.Sprite();
    chainsContainer.addChild(this.chain);
  }

  setChain(
    bgConfig: BackgroundConfig,
    circle: NameplateCircle,
    material: NameplateMaterialType,
    textureByName: NameplateCanvasAppTextureByName,
  ) {
    const neckPosition = bgConfig[this.side].positionNeck;
    const circlePosition = circle.getEdgePosition(neckPosition, this.chain.parent);
    console.log("circlePosition = ", circlePosition)
    const assetProps = bgConfig[this.side].byMaterial[material];
    this.chain.x = circlePosition.x;
    this.chain.y = circlePosition.y;

    this.chain.pivot.x = assetProps.pivotNameplate.x;
    this.chain.pivot.y = assetProps.pivotNameplate.y;

    this.chain.texture = textureByName[this.side];

    const posDx = circlePosition.x - neckPosition.x;
    const posDy = circlePosition.y - neckPosition.y;

    const regDx = assetProps.pivotNameplate.x - assetProps.pivotNeck.x;
    const regDy = assetProps.pivotNameplate.y - assetProps.pivotNeck.y;

    this.chain.rotation = Math.atan2(posDy, posDx) - Math.atan2(regDy, regDx);
    this.chain.scale.set(Math.hypot(posDy, posDx) / Math.hypot(regDy, regDx));
  }
}
