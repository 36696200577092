import React from 'react';

export const Terms = () => {
  const block = 'font-averta mb-5 text-[18px] text-[#3E4966]';
  const title = 'font-averta font-semibold text-[#3E4966]';
  const description = 'font-averta text-[#3E4966]';

  return (
    <>
      <div className={`mt-5 ${block}`}>
        <p className={title}>1. Acceptance of Terms</p>
        <p className={description}>
          By accessing or using the services of Two in Town, Inc. (&quot;Company&quot;,
          &quot;we&quot;, &quot;us&quot;, &quot;our&quot;), you (&quot;User&quot;) acknowledge that
          you have read, understood, and agree to be bound by these Terms of Service and the
          accompanying Privacy and Data Policy.
        </p>
      </div>
      <div className={block}>
        <p className={title}>2. Eligibility</p>
        <p className={description}>
          Our website is intended for users who are at least 18 years old or the age of majority in
          their jurisdiction. Minors may use the site only with the involvement and permission of a
          parent or guardian.
        </p>
      </div>
      <div className={block}>
        <p className={title}>3. Products & Services</p>
        <p className={description}>
          We primarily offer jewelry pieces. Product descriptions aim to be accurate, but errors may
          occur. We reserve the right to correct any errors and to change or update information
          without prior notice.
        </p>
      </div>
      <div className={block}>
        <p className={title}>4. Payment Terms</p>
        <p className={description}>
          We accept various payment methods. By making a purchase, you agree to provide valid
          payment information and authorize us to charge the provided payment method.
        </p>
      </div>
      <div className={block}>
        <p className={title}>5. Privacy & Data Use</p>
        <p className={description}>
          Your use of our site and any data you provide is also governed by our Privacy and Data
          Policy, which details how we handle personal information.
        </p>
      </div>
      <div className={block}>
        <p className={title}>6. Account Management</p>
        <p className={description}>
          If account creation becomes available, you agree to provide accurate information and
          maintain its accuracy. We reserve the right to terminate or suspend any account at our
          discretion.
        </p>
      </div>
      <div className={block}>
        <p className={title}>7. Dispute Resolution</p>
        <p className={description}>
          Any disputes arising out of these terms will first be addressed by our customer service.
          If not resolved, disputes shall be subject to binding arbitration in New York, NY, under
          the rules of the American Arbitration Association.
        </p>
      </div>
      <div className={block}>
        <p className={title}>8. Returns & Refunds</p>
        <p className={description}>
          Products can be returned within 30 days from purchase. Refunds are issued once the product
          is received and inspected.
        </p>
      </div>
      <div className={block}>
        <p className={title}>9. Shipping</p>
        <p className={description}>
          Shipping times are estimates and cannot be guaranteed. We are not responsible for delays
          or damages during shipping.
        </p>
      </div>
      <div className={block}>
        <p className={title}>10. Intellectual Property</p>
        <p className={description}>
          All content, including customized designs, is owned by the Company and protected by U.S.
          and international copyright laws.
        </p>
      </div>

      <div className={block}>
        <p className={title}>11. Changes to the Terms & Privacy Policy</p>
        <p className={description}>
          We may modify these terms or the Privacy and Data Policy at any time. Your continued use
          after such changes signifies your acceptance.
        </p>
      </div>

      <div className={block}>
        <p className={title}>12. Third-Party Relationships</p>
        <p className={description}>
          We may share user information with trusted third parties solely for operational purposes.
          We are not responsible for third-party actions, content, or services.
        </p>
      </div>
      <div className={block}>
        <p className={title}>13. Limitation of Liability</p>
        <p className={description}>
          To the fullest extent permissible by law, we disclaim all warranties. Our liability for
          claims relating to products purchased is limited to the purchase price.
        </p>
      </div>
      <div className={block}>
        <p className={title}>14. Governing Law</p>
        <p className={description}>
          These terms are governed by and construed in accordance with the laws of the State of New
          York, without regard to its conflict of law principles.
        </p>
      </div>
      <div className={`mb-8  ${block}`}>
        <p className={title}>15. Contact Information</p>
        <p className={description}>
          For questions or concerns, contact us via the details provided on our website.
        </p>
      </div>
      <h4 className='mb-8 font-black text-2xl text-[#3E4966]'>Privacy and Data Policy</h4>
      <div className={`mb-8  ${block}`}>
        <p className={title}>Introduction</p>
        <p className={description}>
          We prioritize the privacy of our users. This section describes the types of information we
          collect, how we use it, and measures taken to protect your data.
        </p>
      </div>
      <div className={`mb-8  ${block}`}>
        <p className={title}>Information We Collect</p>
        <p className={description}>We may collect personal, transactional, and usage data.</p>
      </div>
      <div className={`mb-8  ${block}`}>
        <p className={title}>How We Use Your Data</p>
        <p className={description}>
          For fulfillment, customer support, marketing, and analytics purposes.
        </p>
      </div>
      <div className={`mb-8  ${block}`}>
        <p className={title}>Data Sharing and Third-Party Services</p>
        <p className={description}>
          Shared with trusted partners like Payoneer, GoShippo, and Google Ads for operational
          purposes.
        </p>
      </div>
      <div className={`mb-8  ${block}`}>
        <p className={title}>Consent</p>
        <p className={description}>
          By using our website, you consent to this policy. You may opt out of marketing
          communications at any time.
        </p>
      </div>
      <div className={`mb-8  ${block}`}>
        <p className={title}>Data Retention</p>
        <p className={description}>
          We retain data for as long as necessary for the purposes outlined, unless required by law.
        </p>
      </div>
      <div className={`mb-8  ${block}`}>
        <p className={title}>Data Security</p>
        <p className={description}>We implement measures to protect your personal information.</p>
      </div>
      <div className={`mb-8  ${block}`}>
        <p className={title}>Children&apos;s Privacy</p>
        <p className={description}>
          Our website isn&apos;t for those under 18. We don’t knowingly collect or market to
          children.
        </p>
      </div>
      <div className={`mb-8  ${block}`}>
        <p className={title}>Changes to this Policy</p>
        <p className={description}>
          Updates will be communicated and posted here. Review frequently for changes.
        </p>
      </div>
    </>
  );
};
