import { State } from './types';
import isEqual from 'lodash/isEqual';

export const maxSnapshots = 5;

const isStateEqual = (a: State, b: State) => {
  return isEqual(
    { ...a, snapshotIndex: 0, snapshots: [], isSnapshot: true },
    { ...b, snapshotIndex: 0, snapshots: [], isSnapshot: true },
  );
};

export const saveSnapshot = (state: State): State => {
  if (isStateEqual(state, state.snapshots[state.snapshotIndex])) {
    return state;
  }

  let snapshots = state.snapshots.slice(0, state.snapshotIndex + 1);
  snapshots = [...snapshots, state].slice(-maxSnapshots);

  return {
    ...state,
    snapshots,
    snapshotIndex: snapshots.length - 1,
  };
};

export const removeFirstSnapshot = (state: State): State => {
  const snapshots = state.snapshots;
  state.snapshots.shift();

  return {
    ...state,
    snapshots,
    snapshotIndex: snapshots.length - 1,
  };
};

export const patchSnapshot = (state: State): State => {
  return {
    ...state,
    snapshots: state.snapshots.map((s, i) => {
      return i === state.snapshotIndex ? state : s;
    }),
  };
};

export const undo = (state: State): State => {
  return move(state, -1);
};

export const redo = (state: State): State => {
  return move(state, 1);
};

const move = (state: State, side: 1 | -1): State => {
  const snapshot = state.snapshots[state.snapshotIndex + side];

  if (!snapshot) {
    return state;
  }

  return {
    ...snapshot,
    snapshots: state.snapshots,
    snapshotIndex: state.snapshotIndex + side,
    isSnapshot: true,
  };
};
