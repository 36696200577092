import * as PIXI from 'pixi.js';
import { PendantCanvasAppProps } from '../PendantCanvasApp';
import { ClipArt } from './ClipArt';
import { PendantCanvasAppLayout } from '../PendantCanvasAppLayout';
import { createClipArtGesturesHandler } from '../gestureHandlers';
import { pendantSizeByShape } from '../../../hooks/pendant/use-pendant-settings/pendants';
import { PendantMessageShape, PendantShapeType, PendantSizeType } from '../../../hooks';

export class ClipArts {
  public readonly root: PIXI.Container;

  private readonly viewById: Record<string, ClipArt> = {};

  private readonly layout: PendantCanvasAppLayout;

  constructor(layout: PendantCanvasAppLayout) {
    this.layout = layout;

    this.root = new PIXI.Container();
  }

  render(props: PendantCanvasAppProps, prev: PendantCanvasAppProps | null) {
    const ids = props.clipArts.map((clipArtData) => clipArtData.id);

    const size = pendantSizeByShape[props.shape][props.messageShape]![props.pendantSize]!;
    const initSize =
      pendantSizeByShape[PendantShapeType.CIRCLE][PendantMessageShape.Normal]![
        PendantSizeType.MEDIUM
      ]!;
    const scale = Math.min(size.width / initSize.width, size.height / initSize.height);
    this.root.scale.set(scale);

    Object.values(this.viewById).forEach((view) => {
      if (!ids.includes(view.id)) {
        view.destroy();
        delete this.viewById[view.id];
      }
    });

    props.clipArts.forEach((clipArtData) => {
      let view = this.viewById[clipArtData.id];

      if (!view) {
        view = new ClipArt(clipArtData.id, () => {
          this.layout.controller.activate(view.gestureHandler);
        });
        this.viewById[view.id] = view;
        this.root.addChild(view.root);

        view.gestureHandler = createClipArtGesturesHandler(view);
        this.layout.controller.add(view.gestureHandler);
      }

      view.render(clipArtData, props.pendantMaterial);
    });

    if (props.activeClipArtId !== prev?.activeClipArtId) {
      if (props.activeClipArtId) {
        const view = this.viewById[props.activeClipArtId];

        if (view && view.isLoaded()) {
          this.layout.controller.activate(view.gestureHandler);
        }
      } else {
        this.layout.controller.deactivate();
      }
    }
  }
}
