import React, { useEffect, useState } from 'react';
import { Container } from '../Container';
import { motion } from 'framer-motion';
import { ReactComponent as NameplateStyleSVG } from '../../../../assets/images/landing/svg/quick-1.svg';
import { ReactComponent as CustomNameSVG } from '../../../../assets/images/landing/svg/quick-2.svg';
import { ReactComponent as OrderSVG } from '../../../../assets/images/landing/svg/quick-3.svg';
import { ANCHOR_TABS } from '../../enums';
import './styles.css';
import { useNavigate } from 'react-router-dom';
import { AppMode, useCommonState } from '@/hooks';
import ReactGA from 'react-ga4'
import { usePrice } from '@/hooks/use-price';
import { useMediaQuery } from '@mui/material';


const animationImageContainer = {
  hidden: { y: 100, opacity: 0 },
  visible: (custom: number) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: custom * 0.2,
      duration: 1,
    },
  }),
};

export const HowItWorks = () => {
  const { onChangeAppMode } = useCommonState();
  const {priceData} = usePrice();
  const isMobile = useMediaQuery('(max-width: 1023px)');
  
  const [scrollPosition, setScrollPosition] = useState(0);
  
  const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
  };

  useEffect(() => {
      window.addEventListener('scroll', handleScroll, { passive: true });

      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);

  const handleOpenConstructor = () => {


    ReactGA.gtag("event","view_item", {
      currency: "USD",
      value: priceData?.singleProductPrice
    })

    // onChangeAppMode(AppMode.CONSTRUCTOR);

    window.scrollTo(0, 0);
    setTimeout(function () { //Start the timer
      onChangeAppMode(AppMode.CONSTRUCTOR);
    }.bind(this), scrollPosition/9)

    // window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <motion.section
      id={ANCHOR_TABS.howItWorks}
      initial='hidden'
      whileInView='visible'
      viewport={{ amount: 0.15, once: true }}
      className={isMobile ? 'pt-[20px] relative z-20' : 'pt-[24px] relative z-20'}
    >
      <Container>
        <div className={isMobile ? 'bg-white rounded-3xl p-8 lg:p-20 lg:pb-16 shadow-3xlwarmContMob' : 'bg-white rounded-3xl p-8 lg:p-20 lg:pb-16 shadow-3xlwarmCont'}>
          <motion.h2
            custom={1}
            variants={animationImageContainer}
            className={isMobile ? 'text-center text-4xl lg:text-5xl font-averta text-terracotta font-extrabold mb-10 pt-[26px]' : 'text-center text-4xl lg:text-5xl font-averta text-terracotta font-extrabold mb-10' }
          >
            Quick & Easy
          </motion.h2>
          <div>
            <motion.div
              custom={1}
              variants={animationImageContainer}
              className='grid grid-rows-3 justify-center lg:justify-normal lg:grid-rows-none lg:grid-cols-3 gap-x-5 gap-y-[30px] lg:gap-y-0 mb-10'
            >
              <motion.div
                className='flex flex-row lg:flex-col gap-x-5 lg:gap-x-0 items-end lg:items-center text-center relative lg:desktop-line'
              >
                <NameplateStyleSVG className='mb-6 shrink-0 max-w-[125px]' />
                <div className='flex flex-row lg:flex-col lg:gap-y-2 gap-x-2 text-terracotta items-center'>
                  <div className='w-12 h-12 border-[1.5px] border-solid border-terracotta rounded-full text-center relative shrink-0 [@media(max-width:1023px)]:mobile-line'>
                    <span className='font-bold font-averta text-2xl text-terracotta absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
                      1
                    </span>
                  </div>
                  <p style={isMobile ? {lineHeight: '24px'} : {}} className={isMobile ? 'ml-2 text-left [@media(max-width:380px)]:text-lg text-[24px] text-stylish-black font-bold font-averta basis-[60%] lg:whitespace-nowrap' : '[@media(max-width:380px)]:text-lg text-[24px] text-stylish-black font-bold font-averta basis-[60%] lg:whitespace-nowrap' }>
                    Choose a font
                  </p>
                </div>
              </motion.div>
              <motion.div
                className='flex flex-row lg:flex-col gap-x-5 lg:gap-x-0 items-end lg:items-center text-center relative lg:desktop-line'
              >
                <CustomNameSVG className='mb-6 shrink-0 max-w-[125px]' />
                <div className='flex flex-row lg:flex-col lg:gap-y-2 gap-x-2 text-terracotta items-center'>
                  <div className='w-12 h-12 border-[1.5px] border-solid border-terracotta rounded-full text-center shrink-0 relative [@media(max-width:1023px)]:mobile-line'>
                    <span className='font-bold font-averta text-2xl text-terracotta absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
                      2
                    </span>
                  </div>
                  <p style={isMobile ? {lineHeight: '24px'} : {}} className={isMobile ? 'ml-2 text-left [@media(max-width:380px)]:text-lg text-[24px] text-stylish-black font-bold font-averta basis-[60%] lg:whitespace-nowrap' : '[@media(max-width:380px)]:text-lg text-[24px] text-stylish-black font-bold font-averta basis-[60%] lg:whitespace-nowrap' }>
                    Type the name
                  </p>
                </div>
              </motion.div>
              <motion.div
                className='flex flex-row lg:flex-col gap-x-5 lg:gap-x-0 items-end lg:items-center text-center'
              >
                <OrderSVG className='mb-6 shrink-0 max-w-[125px]' />
                <div className='flex flex-row lg:flex-col lg:gap-y-2 gap-x-2 text-terracotta items-center'>
                  <div className='w-12 h-12 border-[1.5px] border-solid border-terracotta rounded-full text-center relative shrink-0'>
                    <span className='font-bold font-averta text-2xl absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
                      3
                    </span>
                  </div>
                  <p style={isMobile ? {lineHeight: '24px'} : {}} className={isMobile ? 'ml-2 text-left [@media(max-width:380px)]:text-lg text-[24px] text-stylish-black font-bold font-averta basis-[60%] lg:whitespace-nowrap' : '[@media(max-width:380px)]:text-lg text-[24px] text-stylish-black font-bold font-averta basis-[60%] lg:whitespace-nowrap' }>
                    Order
                  </p>
                </div>
              </motion.div>
            </motion.div>
          </div>

          <motion.div
            custom={1}
            variants={animationImageContainer}
            className={isMobile ? 'flex justify-center pb-[20px]' : 'flex justify-center' }>
            <button
              onClick={handleOpenConstructor}
              type='button'
              style={isMobile ? { borderRadius: 36, height: '72px', width: '300px'} : {borderRadius: 36}}
              className={isMobile ? 'text-white font-bold font-averta text-xl bg-terracotta border-0 cursor-pointer' : 'text-white font-bold font-averta text-xl py-5 px-10 bg-terracotta border-0 cursor-pointer'}
            >
              Create your nameplate
            </button>
          </motion.div>
        </div>
      </Container>
    </motion.section>
  );
};
