import { NameplateCanvasAppLayout } from './NameplateCanvasAppLayout';
import { NameplateLabel } from './NameplateLabel';
import { GestureHandler } from '../common/controllers/types';
import { NameplateCanvasAppProps } from './NameplateCanvasApp';
import { NameplateSizeType } from '../../hooks/nameplate/use-nameplate-settings/types';

export const createNameplateLabelGesturesHandler = (
  layout: NameplateCanvasAppLayout,
  label: NameplateLabel,
): GestureHandler => {
  return {
    startPosition: null,
    rotationOffset: null,
    scaleMultiplier: null,
    target: label.inputArea,
    getSize(props: NameplateCanvasAppProps): number {
      return label.mesh.scale.x;
    },
    setPosition(props: NameplateCanvasAppProps, x: number, y: number) {
      // noop
    },
    setRotation(props: NameplateCanvasAppProps, rotation: number) {
      // noop
    },
    setSize(props: NameplateCanvasAppProps, size: number) {
      size = Math.min(NameplateSizeType.LARGE, Math.max(NameplateSizeType.SMALL, size));
      props.setFontSize(size);
    },
    activate(props: NameplateCanvasAppProps): void {
      //
    },
    onEnd(props: NameplateCanvasAppProps) {
      props.saveSnapshot();
    },
  };
};
