import React from 'react';
import { Container } from '../Container';
import hannahImage from '../../../../assets/images/landing/svg/hannah-image.svg';
import hannahSmall from '@assets/images/landing/svg/hannah-image.png'
import { ReactComponent as PieceTellsStorySVG } from '../../../../assets/images/landing/svg/pieceTellsStory.svg';
import { motion } from 'framer-motion';
import { cloudinaryImages } from '@/utils/cloudinary';
import { useMediaQuery } from '@mui/material';

const animationContainer = {
  hidden: {
    opacity: 0,
  },
  visible: (custom: number) => ({
    opacity: 1,
    transition: {
      delay: custom * 0.2,
      duration: 0.3,
    },
  }),
};

const animationImageContainer = {
  hidden: { y: 100, opacity: 0 },
  visible: (custom: number) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: custom * 0.2,
      duration: 1,
    },
  }),
};

export const TellsStory = () => {
  const isMobile = useMediaQuery('(max-width: 1023px)');


  return (
    <motion.section
      initial='hidden'
      whileInView='visible'
      viewport={{ amount: 0.15, once: true }}
      className='bg-landing-blue pt-20'
    >
      <Container>
        <div className='flex flex-col lg:flex-row gap-x-4 lg:gap-x-0 justify-between items-center lg:items-start'>
          <div className={'rounded-3xl relative flex flex-col items-center lg:pr-2'}>
            <PieceTellsStorySVG className={isMobile ? 'mb-6' : 'mb-3'} />
            <h3 
            style={isMobile ? {lineHeight: "48px"} : { lineHeight: "72px" }} 
            className={isMobile ? 'max-w-[358px] text-stylish-black text-[56px] lg:text-[80px] leading-[72px] mb-5 font-albra text-center' : 'max-w-[487px] text-stylish-black text-[56px] lg:text-[80px] leading-[72px] mb-8 font-albra text-center'}
            >
              A Piece That tells a Story
            </h3>
            <p className='max-w-[684px] text-xl lg:text-2xl text-stylish-black text-center leading-6 mb-12'>
              Every chapter of your life is a tale worth sharing. Let your jewelry be the emblem of
              your story&apos;s milestones
            </p>
            <motion.div 
            custom={1}
            variants={animationContainer}
            className='hidden lg:block translate-y-8'>
              <motion.div
                className='bg-shadow-warm blur-2xl'
                style={{ left: 16, right: 16, width: '95%', zIndex: -10, top: 48, height: '100%', position: 'absolute', borderRadius: "24px"}}
              />
              <motion.img
                className='rounded-3xl object-cover max-w-full h-auto transition-transform origin-right group-hover:!scale-105'
                src={hannahImage}
                alt='hannah'
              />
            </motion.div>
          </div>
          <div className='shrink-0'>
            <motion.div 
            custom={1}
            variants={animationImageContainer}
            className={isMobile ? 'relative z-0 flex group max-w-lg justify-center mb-[20px] mt-1 lg:mb-0 lg:-translate-y-32' : 'relative z-0 flex group max-w-lg justify-center mb-4 -mt-32 lg:mb-0 lg:-translate-y-32'}
            >
              <motion.div
                className='blur-2xl bg-shadow-cold'
                style={isMobile ? { width: '80%', zIndex: -20, top: 40, height: '100%', position: 'absolute'} : { width: '80%', zIndex: -20, top: 48, height: '100%', position: 'absolute'}}
              ></motion.div>
              <motion.div
                className='rounded-3xl bg-landing-blue h-full w-full max-w-[95%] absolute right-0 bottom-0 -z-10'
              ></motion.div>
              <motion.img
                className='rounded-3xl object-cover max-w-[95%] h-auto transition-transform !duration-700 origin-bottom-right group-hover:!scale-105'
                src={cloudinaryImages.landing.images.g2}
                alt='g2'
              />
            </motion.div>
          </div>
          <div className='block lg:hidden z-20 h-[200px]'>
            <div 
            // style={{backgroundColor: 'red'}}
            className='blur-2xl bg-shadow-warm absolute mt-[16px] left-[16px] h-[250px] rounded-3xl object-cover w-[90%]'>
            </div>
            <motion.img
              custom={1}
              variants={animationContainer}
              className='absolute p-[8px] left-0 rounded-3xl object-cover w-full h-auto transition-transform origin-right z-20 group-hover:!scale-105'
              src={hannahSmall}
              alt='hannah'
            />
          </div>
        </div>
      </Container>
    </motion.section>
  );
};
