import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as BurgerMenuSVG } from '../../../../assets/images/landing/svg/burger-menu.svg';
import { motion, useAnimation } from 'framer-motion';
import { Container } from '../Container';
import { ANCHOR_TABS } from '../../enums';
import { AppMode, useCommonState } from '../../../../hooks';
import { MobileNavbarMenu } from '../MobileNavbarMenu';
import { MobileNavbarRef } from '../MobileNavbarMenu/MobileNavbarMenu';
import ReactGA from "react-ga4";
import { usePrice } from '@/hooks/use-price';
import Lottie from 'react-lottie'
import MenuX from '@assets/animations/menu-x.json';
import xMenu from '@assets/animations/x-menu.json';
import { useMediaQuery } from '@mui/material';

const SHIFTING_HIDE = 50;

export const Header = () => {
  const controls = useAnimation();
  const { onChangeAppMode, appMode, isOpenMenu, onChangeMenuState} = useCommonState();
  const [show, setShow] = React.useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width: 1023px)')
  const [lastScrollY, setLastScrollY] = React.useState(0);
  const refMobileNavbar = React.useRef<MobileNavbarRef | null>(null);

  React.useEffect(() => {
    const handleScroll = () => {
      if (!isMobile) {
        if (typeof window !== 'undefined') {
          if (window.scrollY > lastScrollY && window.scrollY > SHIFTING_HIDE) {
            show && setShow(false);
            controls.start({ opacity: 0, y: -105 }, { duration: 0.35 });
          } else {
            !show && setShow(true);
            controls.start({ opacity: 1, y: 0 }, { duration: 0.35 });
          }
          setLastScrollY(window.scrollY);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  const onOpenMobileMenu = () => {
    if (isOpen) {
      refMobileNavbar.current?.close();
    } else {
      refMobileNavbar.current?.open();
    }
    setIsOpen(!isOpen);
    onChangeMenuState(!isOpen)
  }
  const {priceData} = usePrice()

  const handleOpenConstructor = () => {
    ReactGA.gtag("event","view_item", {
      currency: "USD",
      value: priceData?.singleProductPrice
    })

    onChangeAppMode(AppMode.CONSTRUCTOR);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // useEffect(() => {
  //   if (appMode == AppMode.CONSTRUCTOR) {
  //     if (isOpen) {
  //       setIsOpen(!isOpen);
  //     }
  //   }
  // }, [appMode]);

  useEffect(() => {
    console.log("MENU STATE CHANGED = ", isOpenMenu)
    setIsOpen(isOpenMenu);
  }, [isOpenMenu]);

  return (
    <motion.header
      className='fixed top-0 z-[1001] w-full'
      initial={{ opacity: 1, y: 0 }}
      animate={controls}
    >
      <Container>

      {isMobile ? <MobileNavbarMenu ref={refMobileNavbar} /> : <></>}
        <div className='flex py-6 bg-transparent'>
          <div className='flex items-center grow gap-x-3'>
          </div>
          {isMobile && (
            <>
              <button className='bg-transparent outline-0 border-0 pr-2 -mt-1' onClick={onOpenMobileMenu}>
                {/* <BurgerMenuSVG className='text-stylish-black cursor-pointer' /> */}
                { isOpen ?
                <Lottie options={{
                  loop: false,
                  autoplay: true,
                  animationData: MenuX
                }} />
                :
                <Lottie options={{
                  loop: false,
                  autoplay: true,
                  animationData: xMenu,
                  
                }} />
                }
              </button>
            </>
          )}
          {!isMobile && (
            <nav>
              <ul className='flex items-center gap-x-8 font-averta'>
                <li>
                  <a href={`#${ANCHOR_TABS.whyUs}`} className='no-underline text-stylish-black'>
                    Why us?
                  </a>
                </li>
                <li>
                  <a
                    href={`#${ANCHOR_TABS.howItWorks}`}
                    className='no-underline text-stylish-black'
                  >
                    How it works?
                  </a>
                </li>
                <li>
                  <button
                    type='button'
                    onClick={handleOpenConstructor}
                    style={{borderRadius: 30}}
                    className='cursor-pointer font-averta text-base text-white px-8 py-4 font-bold bg-terracotta border-0 break-words'
                  >
                    Design your own
                  </button>
                </li>
              </ul>
            </nav>
          )}
        </div>
      </Container>
    </motion.header>
  );
};
