import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import AppRouter from './pages/AppRouter';
import ReactGA from "react-ga4";

ReactGA.initialize("G-QH4Y7PRFT2");

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <AppRouter />
    </BrowserRouter>
  );
};

export default App;
