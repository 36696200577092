export const cloudinaryImages = {
  checkout: {
    bgCheckout: 'https://res.cloudinary.com/ddkzd7yy3/image/upload/v1707373627/2-in-town/checkout/images/x352rbrauvsdh7bxmi1p.png',
  },
  landing: {
    images: {
      desktopBgConstructor: 'https://res.cloudinary.com/ddkzd7yy3/image/upload/v1707373675/2-in-town/landing/images/tt1yqs9qj3gjvnsm0ywu.png',
      g1: 'https://res.cloudinary.com/ddkzd7yy3/image/upload/v1707373671/2-in-town/landing/images/akxfsygyguu47vof2szi.png',
      g2: 'https://res.cloudinary.com/ddkzd7yy3/image/upload/v1707373670/2-in-town/landing/images/a6wlissxdpzoinorosql.png',
      hannah: 'https://res.cloudinary.com/ddkzd7yy3/image/upload/v1707373669/2-in-town/landing/images/fnrn8ieipwfuhdu7bbni.png',
      mobileBgConstructor: 'https://res.cloudinary.com/ddkzd7yy3/image/upload/v1707373669/2-in-town/landing/images/lgwmwfhet9hr9onxmt3z.png',
      moreThanGiftNameplate1: 'https://res.cloudinary.com/ddkzd7yy3/image/upload/v1707373670/2-in-town/landing/images/xnsimc4fabtkhz8f162z.jpg',
      moreThanGiftNameplate2: 'https://res.cloudinary.com/ddkzd7yy3/image/upload/v1707373669/2-in-town/landing/images/lckxra0p66q02eclqa3b.jpg',
      moreThanGiftPlate: 'https://res.cloudinary.com/ddkzd7yy3/image/upload/v1707373671/2-in-town/landing/images/gfhotwpeg3msvsdv3i0q.png',
      bgPreviewConstructorMobile: 'https://res.cloudinary.com/ddkzd7yy3/image/upload/v1707373670/2-in-town/landing/images/y715twf9iljsvgsk5kgx.jpg',
      bgPreviewConstructorDesktop: 'https://res.cloudinary.com/ddkzd7yy3/image/upload/v1707373670/2-in-town/landing/images/muvjqtbi69hvrg0ymfos.jpg',
    },
    // svg: {
    //   decades1: cloudinaryUrl + 'two-in-town/images/landing/svg/ptsgqr9s59yxbi2ww3u7',
    //   sameDay3: cloudinaryUrl + 'two-in-town/images/landing/svg/fg6iahrytm1tfamlxcxz',
    //   alsono: cloudinaryUrl + 'two-in-town/images/landing/svg/s0awgmeru281dueuvsri',
    //   burgerMenu: cloudinaryUrl + 'two-in-town/images/landing/svg/f9zrfpauidb3berb7jsj',
    //   close: cloudinaryUrl + 'two-in-town/images/landing/svg/smdhfkxcwzmq3ycnuauj',
    //   constructorBgElement1: cloudinaryUrl + 'two-in-town/images/landing/svg/wcfydqkcyuvj2toav6y2',
    //   fairPricing: cloudinaryUrl + 'two-in-town/images/landing/svg/kmnkr0hesq9z5j5ycwqx',
    //   footerLip: cloudinaryUrl + 'two-in-town/images/landing/svg/x0zssowbxsppqbeneysv',
    //   footerLipSecond: cloudinaryUrl + 'two-in-town/images/landing/svg/aofauzlpijj5czmiaiew',
    //   lipsDareToBeDifferent: cloudinaryUrl + 'two-in-town/images/landing/svg/jzxfzwdjdwzylieqkrvq',
    //   moreThanGiftLips: cloudinaryUrl + 'two-in-town/images/landing/svg/rifzxlxk6swa4nndnjf8',
    //   patternFooter: cloudinaryUrl + 'two-in-town/images/landing/svg/yq9fjwoux1ccublzhfn8',
    //   patternTopRight1: cloudinaryUrl + 'two-in-town/images/landing/svg/r9hrhferghfs01yjkrqg',
    //   patternTopRight2: cloudinaryUrl + 'two-in-town/images/landing/svg/in8frvdhvwcqwm7ipp26',
    //   pieceTellsStory: cloudinaryUrl + 'two-in-town/images/landing/svg/gd9qq6k51uhj4fivo48r',
    //   quick1: cloudinaryUrl + 'two-in-town/images/landing/svg/rejlfsdio0kwyga9qmxu',
    //   quick2: cloudinaryUrl + 'two-in-town/images/landing/svg/b0oljc34huwkz95nvfv7',
    //   quick3: cloudinaryUrl + 'two-in-town/images/landing/svg/a8tokujomeb7ngf5ok4v',
    //   whyChooseUsEllipse: cloudinaryUrl + 'two-in-town/images/landing/svg/ud74ebpjqa7zj7hwz2is'
    // },
  },
  thankYou: {
    images: {
      heart: 'https://res.cloudinary.com/ddkzd7yy3/image/upload/v1707373672/2-in-town/thank-you/images/gd6emybx6fkz2y74uff0.png',
      smile: 'https://res.cloudinary.com/ddkzd7yy3/image/upload/v1707373671/2-in-town/thank-you/images/do343rujkbwyu7whnkzu.png',
    },
    // svg: {
    //   diamond: 'https://res.cloudinary.com/ddkzd7yy3/image/upload/v1707373671/2-in-town/thank-you/svg/dnq8onmogsnsm55sy7mz.svg'
    // }
  },
};
