import * as PIXI from 'pixi.js';
import * as TWEENS from '@tweenjs/tween.js';
import * as Hammer from 'hammerjs';

import { ICanvasApp } from '../types';
import {
  PendantImageFilterType,
  PendantModeType,
  PendantMaterialType,
  PendantMessageShape,
  PendantShapeType,
  PendantSizeType,
  RulerUnits,
  PendantSelectedItem,
  IconProps,
  LabelProps,
} from '../../hooks';
import { ConstructorRootElement } from '../../hooks/pendant/use-pendant-settings/constructor';
import { PendantCanvasAppLayout } from './PendantCanvasAppLayout';
import { PendantCanvasAppAssetsLoader } from './PendantCanvasAppAssetsLoader';
import { PendantCanvasAppRemoveIconViewState } from './PendantCanvasAppRemoveIcon';
import { createHammer } from '../common/createHammer';
import { ensureFullScreen } from '../common/ensureFullScreen';
import { createTweens } from '../common/createTweens';
import { BackgroundName } from '../nameplate-canvas-app/backgrounds';
import { PendantCanvasAppExporter } from './PendantCanvasAppExporter';

type Props = {
  position: { x: number; y: number };
  backgroundName: BackgroundName;
  shape: PendantShapeType;
  pendantSize: PendantSizeType;
  messageShape: PendantMessageShape;
  pendantMaterial: PendantMaterialType;
  zoom: number; // bg scale multiplier
  imageSize: number; // image size, % from pendant shape size
  image: HTMLImageElement | null;
  imageRotation: number;
  imagePosition: { x: number; y: number };
  imageFilter: PendantImageFilterType;
  setImagePosition: (position: { x: number; y: number } | null) => void;
  mode: PendantModeType | null;
  removeIconViewState: PendantCanvasAppRemoveIconViewState;
  labels: (LabelProps | undefined)[];
  autoAdaptShapeEnabled: boolean;
  rulerMode: RulerUnits;
  rulerIsEnabled: boolean;
  isMobile: boolean;
  shapeAutoAdapted: boolean;
  setMessageShape: (messageShape: PendantMessageShape) => void;
  setRemoveIconViewState: (state: PendantCanvasAppRemoveIconViewState) => void;
  activeTextId: string;
  texturesLoading: boolean;
  isSnapshot: boolean;
  currentElement: ConstructorRootElement;
  clipArts: IconProps[];
  setClipArtPosition: (id: string, position: { x: number; y: number }) => void;
  setClipArtScale: (id: string, scale: number) => void;
  setClipArtRotation: (id: string, rotation: number) => void;
  removeClipArt: (id: string) => void;
  activeClipArtId: string | null;
  patchSnapshot: () => void;
  saveSnapshot: () => void;
  setText: (id: string, text: string) => void;
  setTextPosition: (id: string, position: { x: number; y: number }) => void;
  setFontSize: (id: string, size: number) => void;
  setTextRotation: (id: string, rotation: number) => void;
  removeText: (id: string) => void;
  setImage: (image: HTMLImageElement | null) => void;
  setImageSize: (size: number) => void;
  setImageRotation: (rotation: number) => void;
  setShapeAutoAdapted: (value: boolean) => void;
  onTextBoundsChange: (isOutOfBounds: boolean) => void;
  setTexturesLoading: (isLoading: boolean) => void;
  setSelectedItem: (item: PendantSelectedItem | null) => void;
};

export class PendantCanvasApp extends PIXI.Application implements ICanvasApp {
  private readonly assetsLoader: PendantCanvasAppAssetsLoader;
  public readonly layout: PendantCanvasAppLayout;

  public readonly hammer: InstanceType<typeof Hammer.Manager>;
  public readonly tweens: TWEENS.Group;

  private updateId = 1;

  constructor(parent: HTMLDivElement) {
    super({
      width: window.innerWidth,
      height: window.innerHeight,
      antialias: false,
    });

    parent.appendChild(this.view);

    this.tweens = createTweens(this);
    this.hammer = createHammer(this);

    this.assetsLoader = new PendantCanvasAppAssetsLoader();
    this.layout = new PendantCanvasAppLayout(this);
  }

  async update(props: Props) {
    if (!this.stage) return;

    this.updateId += 1;

    const updateId = this.updateId;
    let texturesLoaded = false;

    PendantCanvasAppExporter.setApp(this);
    ensureFullScreen(this);

    setTimeout(() => {
      if (texturesLoaded || updateId !== this.updateId || !this.stage || props.texturesLoading)
        return;

      props.setTexturesLoading(true);
    }, 200);

    const textureByName = await this.assetsLoader.loadTextures(props);
    texturesLoaded = true;

    if (!this.stage) return;

    this.layout.render(props, textureByName);

    if (props.texturesLoading && updateId === this.updateId) {
      props.setTexturesLoading(false);
    }
  }

  destroy(): void {
    super.destroy(true, true);
    this.layout.controller?.destroy();
    this.hammer.destroy();
    this.tweens.removeAll();
  }
}

export type PendantCanvasAppProps = Props;
