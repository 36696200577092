export const compareAndSaveEqualItems = (a: Array<any>, b: Array<any>) => {
  const len = a.length > b.length ? a.length : b.length;
  const res: Array<any> = [];

  for (let i = 0; i < len; i++) {
    if (a.includes(b[i]) && !res.includes(b[i])) {
      res.push(b[i]);
    }
  }

  return res;
};
