import * as PIXI from 'pixi.js';
import { PendantCanvasAppLayout } from './PendantCanvasAppLayout';
import { isTextOutOfPendant } from './isTextOutOfPendant';
import { createLabelGesturesHandler } from './gestureHandlers';
import { GestureHandler } from '../common/controllers/types';
import { getMeshLocalBounds } from './LabelUtils';
import { LabelProps, PendantMaterialType } from '../../hooks/pendant/use-pendant-settings';
import { EngravingMaterial } from './ClipArts/EngravingMaterial';

export class Label {
  public readonly id: string;
  public readonly mesh: PIXI.SimpleRope;
  public readonly label: PIXI.Text;
  public readonly root: PIXI.Container;
  public outOfPendantDirty = true;

  private isNew = true;
  private readonly app: PIXI.Application;
  private readonly layout: PendantCanvasAppLayout;
  private readonly deformationPoints = Array.from({ length: 1000 }).map(() => new PIXI.Point());
  private props: LabelProps | null = null;
  private outOfPendant = false;
  public readonly gestureHandler: GestureHandler;
  private material: EngravingMaterial;
  private materialType!: PendantMaterialType;

  private meshLocalBounds = { minX: 0, minY: 0, maxX: 1, maxY: 1 };
  public readonly inputArea: PIXI.Sprite;

  constructor(id: string, app: PIXI.Application, layout: PendantCanvasAppLayout) {
    this.id = id;
    this.app = app;
    this.layout = layout;

    this.root = new PIXI.Container();
    this.root.mask = layout.labelMask;
    layout.labelsContainer.addChild(this.root);

    this.label = new PIXI.Text('', {
      fontSize: 300,
      fill: '#ffffff',
      padding: 10,
      strokeThickness: 0,
    });

    const texture = PIXI.Texture.from(this.label.canvas);
    PIXI.Texture.removeFromCache(texture);

    this.mesh = new PIXI.SimpleRope(texture, this.deformationPoints);

    this.inputArea = PIXI.Sprite.from(PIXI.Texture.EMPTY);
    this.inputArea.anchor.set(0.5);
    layout.labelsContainer.addChild(this.inputArea);

    this.material = new EngravingMaterial(PIXI.Texture.EMPTY);
    this.root.addChild(this.material.root);

    this.gestureHandler = createLabelGesturesHandler(this.layout, this);
    this.layout.controller.add(this.gestureHandler);
  }

  render(labelProps: LabelProps, materialType: PendantMaterialType) {
    const prev = this.props;
    this.props = labelProps;

    const materialChanged = materialType !== this.materialType;
    this.materialType = materialType;

    const labelDirty =
      materialChanged ||
      labelProps.text !== prev?.text ||
      labelProps.lineHeight !== prev?.lineHeight ||
      labelProps.letterSpacing !== prev?.letterSpacing ||
      labelProps.textAlign !== prev?.textAlign ||
      labelProps.font !== prev?.font ||
      labelProps.fontWeight !== prev?.fontWeight ||
      labelProps.fontStyle !== prev?.fontStyle;

    const meshDirty =
      labelDirty ||
      labelProps.textDeformation !== prev?.textDeformation ||
      labelProps.textSize !== prev?.textSize;

    const transformDirty =
      meshDirty ||
      labelProps.textPosition !== prev?.textPosition ||
      labelProps.textRotation !== prev?.textRotation ||
      labelProps.fontSize !== prev?.fontSize;

    if (labelDirty) {
      this.renderLabel(labelProps, materialType);
    }

    if (meshDirty) {
      this.renderMesh(labelProps, materialType);
    }

    if (transformDirty) {
      this.renderTransform(labelProps);
    }
  }

  private renderLabel(labelProps: LabelProps, materialType: PendantMaterialType) {
    const { label } = this;
    const { text, lineHeight, letterSpacing, textAlign, font, fontWeight, fontStyle } = labelProps;

    label.text = text;
    label.style.lineHeight = lineHeight;
    label.style.letterSpacing = letterSpacing;
    label.style.align = textAlign;
    label.style.fontFamily = font;
    label.style.fontWeight = fontWeight;
    label.style.fontStyle = fontStyle;
    label.updateText(true);
    this.outOfPendantDirty = true;
  }

  private renderMesh(labelProps: LabelProps, materialType: PendantMaterialType) {
    const { label, mesh } = this;
    const { textDeformation, textSize } = labelProps;

    const texture = PIXI.Texture.from(label.canvas);
    PIXI.Texture.removeFromCache(texture);
    mesh.texture = texture;

    const direction = textDeformation * PIXI.DEG_TO_RAD;

    if (Math.abs(Math.sin(direction)) > 0.001) {
      const radius = texture.width / 2 / Math.tan(direction);
      const stepAngle = (texture.width / radius / (this.deformationPoints.length - 1)) * textSize;
      const startAngle = -Math.PI / 2 - (this.deformationPoints.length / 2) * stepAngle;

      this.deformationPoints.forEach((point, i) => {
        const angle = startAngle + i * stepAngle;
        point.x = Math.cos(angle) * radius;
        point.y = Math.sin(angle) * radius + radius;
      });
    } else {
      this.deformationPoints.forEach((point, i) => {
        point.x =
          (((i - this.deformationPoints.length / 2) * texture.width) /
            (this.deformationPoints.length - 1)) *
          textSize;
        point.y = 0;
      });
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    mesh._render(this.app.renderer);

    const canvas = this.app.renderer.plugins.extract.canvas(mesh);
    const mTexture = PIXI.Texture.from(canvas);
    PIXI.Texture.removeFromCache(mTexture);

    this.material.setTexture(mTexture);
    this.material.render(materialType);

    this.meshLocalBounds = getMeshLocalBounds(this.app, mesh);

    this.outOfPendantDirty = true;

    if (this.isNew && this.props?.text) {
      this.isNew = false;
      this.layout.controller.activate(this.gestureHandler);
    }
  }

  private renderTransform(labelProps: LabelProps) {
    const { root } = this;
    const { fontSize, textPosition, textRotation } = labelProps;

    root.x = textPosition.x;
    root.y = textPosition.y;
    root.rotation = textRotation;
    root.scale.set(fontSize);
    this.outOfPendantDirty = true;

    this.renderInputArea();
  }

  private renderInputArea() {
    const { root, meshLocalBounds } = this;
    const dx = (meshLocalBounds.maxX + meshLocalBounds.minX) * root.scale.x;
    const dy = (meshLocalBounds.maxY + meshLocalBounds.minY) * root.scale.y;
    const offset = new PIXI.Point(dx / 2, dy / 2);
    const matrix = new PIXI.Matrix();
    matrix.rotate(root.rotation);
    matrix.apply(offset, offset);
    this.inputArea.width = (meshLocalBounds.maxX - meshLocalBounds.minX) * root.scale.x;
    this.inputArea.height = (meshLocalBounds.maxY - meshLocalBounds.minY) * root.scale.y;
    this.inputArea.x = root.x + offset.x;
    this.inputArea.y = root.y + offset.y;
    this.inputArea.rotation = root.rotation;
  }

  calculateOutOfBounds(pendantTexture: PIXI.Texture, anchorY: number): boolean {
    return this.label.text !== '' && isTextOutOfPendant(this.app, this, pendantTexture, anchorY);
  }

  getCachedOutOfBounds(): boolean {
    if (this.outOfPendantDirty) {
      this.outOfPendantDirty = false;
      this.outOfPendant = this.calculateOutOfBounds(
        this.layout.labelMask.texture,
        this.layout.labelMask.anchor.y,
      );
    }

    return this.outOfPendant;
  }

  onRemove() {
    delete this.layout.labelById[this.id];
    this.label.destroy(true);
    this.root.destroy(true);
    this.inputArea.destroy(true);
    this.deformationPoints.length = 0;
  }
}
