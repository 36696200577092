import * as PIXI from 'pixi.js';
import { IconProps, PendantMaterialType } from '../../../hooks';
import { GestureHandler } from '../../common/controllers/types';
import { EngravingMaterial } from './EngravingMaterial';
import { svgToImageSrc } from '../../common/clipartSvgFixes';

export class ClipArt {
  public readonly id: string;

  public readonly root: PIXI.Container;

  public gestureHandler!: GestureHandler;

  private prevFile: string | null = null;

  private material: EngravingMaterial | null = null;

  private readonly onImageLoaded: () => void;

  private loaded = false;

  constructor(id: string, onImageLoaded: () => void) {
    this.id = id;
    this.onImageLoaded = onImageLoaded;

    this.root = new PIXI.Container();
  }

  render(iconProps: IconProps, material: PendantMaterialType) {
    this.root.x = iconProps.position.x;
    this.root.y = iconProps.position.y;
    this.root.scale.set(iconProps.scale);
    this.root.rotation = iconProps.rotation;

    if (iconProps.image !== this.prevFile) {
      this.prevFile = iconProps.image;
      this.loaded = false;

      const image = new Image();
      image.addEventListener('load', () => {
        this.loaded = true;

        setTimeout(() => {
          this.onImageLoaded();
        });
      });
      image.src = svgToImageSrc(iconProps.image).replace('fill="black"', 'fill="white"');

      this.material?.destroy();
      this.material = new EngravingMaterial(PIXI.Texture.from(image));
      this.root.addChild(this.material.root);
    }

    this.material?.render(material);
  }

  destroy() {
    this.root.destroy();
  }

  isLoaded() {
    return this.loaded;
  }
}
