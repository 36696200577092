import * as PIXI from 'pixi.js';
import {DashLine} from 'pixi-dashed-line';
import {NameplateLabel} from './NameplateLabel';
import {NameplateCanvasAppProps} from './NameplateCanvasApp';
import {nearestPowerOfTwo} from '../common/nearestPowerOfTwo';

enum RulerUnits {
  INCH = 'inch',
  CM = 'cm',
}

const labelModels = {
  [RulerUnits.CM]: Array.from({ length: 30 }).map((v, i) => {
    return {
      value: i * 10,
      sValue: `${i}`,
    };
  }),
  [RulerUnits.INCH]: Array.from({ length: 30 }).flatMap((v, i) => {
    return ['', '¼', '½', '¾'].map((s, j) => {
      return { value: i * 16 + j * 4, sValue: `${s ? i || '' : i}${s}` };
    });
  }),
};

const getEndPositionByMode = {
  [RulerUnits.CM]: (i: number) => {
    return i % 10 === 0 ? 30 : i % 5 === 0 ? 20 : 10;
  },
  [RulerUnits.INCH]: (i: number) => {
    return i % 8 === 0 ? 32 : i % 4 === 0 ? 24 : i % 2 === 0 ? 16 : 8;
  },
};

export class NameplateRuler {
  public readonly root: PIXI.Container;
  private readonly graphics: PIXI.Graphics;
  private readonly labelsPool: PIXI.Text[] = [];
  private readonly labels: PIXI.Text[] = [];

  constructor() {
    this.root = new PIXI.Container();

    this.graphics = new PIXI.Graphics();
    this.root.addChild(this.graphics);
  }

  private getLabel(): PIXI.Text {
    let label = this.labelsPool.pop();

    if (!label) {
      label = new PIXI.Text('', {
        fill: 'rgba(0, 0, 0, 1)',
        fontSize: 28,
        fontFamily: 'Arial',
      });
    }

    label.visible = true;
    this.root.addChild(label);
    this.labels.push(label);

    return label;
  }

  private releaseAllLabels() {
    const { labels, labelsPool } = this;

    labels.forEach((label) => {
      label.parent?.removeChild(label);
    });

    labelsPool.push(...labels);
    labels.length = 0;
  }

  render(props: NameplateCanvasAppProps, label: NameplateLabel, color: string) {
    const { rulerIsEnabled, rulerMode } = props;

    this.root.visible = rulerIsEnabled && props.label.text.length !== 0;

    if (!rulerIsEnabled) return;

    const hex = PIXI.utils.string2hex(color);
    const { graphics } = this;

    graphics.clear();

    const labelBounds = label.getBoundsInStageSpace();
    const lt = new PIXI.Point(labelBounds.minX, labelBounds.minY);
    const lb = new PIXI.Point(labelBounds.minX, labelBounds.maxY);
    const rb = new PIXI.Point(labelBounds.maxX, labelBounds.maxY);

    const dash = new DashLine(graphics, {
      dash: [3, 3],
      width: 1,
      color: hex,
    });

    dash.moveTo(lt.x, lt.y);
    dash.lineTo(rb.x, lt.y);
    dash.lineTo(rb.x, rb.y);

    graphics.blendMode = PIXI.BLEND_MODES.MULTIPLY;
    graphics.lineStyle(1.4, hex, 1, 0.5);
    graphics.moveTo(lt.x, lt.y);
    graphics.lineTo(lb.x, lb.y);
    graphics.lineTo(rb.x, rb.y);

    const width = rb.x - lb.x;
    const height = lb.y - lt.y;

    const heightMm = label.getHeightMm();
    const widthMm = label.getWidthMm();
    const inchStepToMm = (2.54 * 10) / 16;
    const stepSize = (width / widthMm) * (rulerMode === RulerUnits.CM ? 1 : inchStepToMm);

    const getEndPosition = (i: number) =>
      getEndPositionByMode[rulerMode](i) * (0.2 + props.zoom * 0.8);

    const getStartPosition = (i: number) => {
      return i === 0 ? 6 : 0;
    };

    for (let i = 0, steps = width / stepSize; i <= steps; i++) {
      const x = lb.x + i * stepSize;
      graphics.moveTo(x, lb.y + getStartPosition(i));
      graphics.lineTo(x, lb.y + getEndPosition(i));
    }

    for (let i = 0, steps = height / stepSize; i <= steps; i++) {
      const y = lb.y - i * stepSize;
      graphics.moveTo(lb.x - getStartPosition(i), y);
      graphics.lineTo(lb.x - getEndPosition(i), y);
    }

    // labels
    const textObject = this.getLabel();
    textObject.text = props.rulerMode === RulerUnits.CM ? '0' : '1¾';

    const textSize = Math.max(textObject.width, textObject.height);
    const distanceBetweenLabels = labelModels[rulerMode][1].value * stepSize;
    const showEvery = Math.max(
      1,
      nearestPowerOfTwo(Math.sqrt((textSize / distanceBetweenLabels) * 4)),
    );

    this.releaseAllLabels();

    const placeLabel = (text: string, i: number, forceVisible: boolean, rulerMode: string) => {
      const label = this.getLabel();
      label.blendMode = PIXI.BLEND_MODES.MULTIPLY;
      label.text = (text !== '0' && rulerMode === RulerUnits.CM) ? text + '0' : text;
      label.style.fill = color;
      label.scale.set(0.7 + props.zoom * 0.3);
      label.visible = forceVisible || i % showEvery === 0;

      return label;
    };

    const rulerValueToMm = rulerMode === RulerUnits.CM ? 1 : inchStepToMm;

    labelModels[rulerMode]
      .filter((item) => {
        const valueMm = item.value * rulerValueToMm;
        return valueMm <= widthMm;
      })
      .reverse()
      .forEach(({ value, sValue }, i) => {
        const label = placeLabel(sValue, i, value === 0, rulerMode);
        label.x = lb.x + value * stepSize;
        label.y = lb.y + getEndPosition(0) + 5;
        label.anchor.set(0.5, 0);
      });

    labelModels[rulerMode]
      .filter((item) => {
        const valueMm = item.value * rulerValueToMm;
        return valueMm <= heightMm;
      })
      .reverse()
      .forEach(({ value, sValue }, i) => {
        const label = placeLabel(sValue, i, value === 0, rulerMode);
        label.x = lb.x - getEndPosition(0) - 5;
        label.y = lb.y - value * stepSize;
        label.anchor.set(1, 0.5);
      });
  }
}
