import * as PIXI from 'pixi.js';
import Hammer from 'hammerjs';

export const createHammer = (app: PIXI.Application) => {
  const hammer = new Hammer.Manager(app.view);
  const pinch = new Hammer.Pinch();
  const rotate = new Hammer.Rotate();
  const pan = new Hammer.Pan();
  pinch.recognizeWith([rotate]);
  hammer.add([pinch, rotate, pan]);

  return hammer;
};
