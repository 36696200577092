import * as PIXI from 'pixi.js';
import * as TWEENS from '@tweenjs/tween.js';

export const createTweens = (app: PIXI.Application) => {
  const tweens = new TWEENS.Group();

  app.ticker.add(() => {
    tweens.update(app.ticker.lastTime);
  });

  return tweens;
};
