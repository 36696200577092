import { Constructor, ConstructorRootElement } from './constructor';
import * as PIXI from 'pixi.js';

export enum PendantMaterialType {
  GOLD = 'Gold',
  ROSE_GOLD = 'Rose_gold',
  SILVER = 'Silver',
}

export enum PendantShapeType {
  BUBBLE = 'bubble',
  CIRCLE = 'circle',
  HEART = 'heart', // heart "Classic"
  HEART2 = 'heart2', // heart "Two-in-town"
  HEART3 = 'heart3', // heart with arch
  OCTANGLE = 'octangle',
  RECTANGLE = 'rectangle',
}

export enum PendantSizeType {
  XSMALL = 'XSmall',
  SMALL = 'Small',
  MEDIUM = 'Medium',
  LARGE = 'Large',
  XLARGE = 'XLarge',
}

export enum PendantMessageShape {
  Normal = 'Normal',
  High = 'High',
  Long = 'Long',
  XHigh = 'XHigh',
  XLong = 'XLong',
}

export enum PendantImageFilterType {
  NoFilter = 'NoFilter',
}

export enum PendantSelectedItemType {
  Label = 'Label',
  Image = 'Image',
  Icon = 'Icon',
}

export type PendantSelectedItem = {
  type: PendantSelectedItemType;
  id: string;
};

export type IconProps = {
  id: string;
  image: string;
  position: {
    x: number;
    y: number;
  };
  scale: number;
  rotation: number;
};

export type LabelProps = {
  id: string;
  text: string;
  lineHeight: number;
  letterSpacing: number;
  textAlign: PIXI.TextStyleAlign;
  font: string;
  fontWeight: PIXI.TextStyleFontWeight;
  fontStyle: PIXI.TextStyleFontStyle;
  fontSize: number;
  textDeformation: number;
  textPosition: { x: number; y: number };
  textRotation: number;
  textSize: number;
};

export type PendantState = {
  price: {
    data: number;
    isLoading: boolean;
  };
  shape: PendantShapeType;
  messageShape: PendantMessageShape;
  selectedMaterial: PendantMaterialType;
  selectedSize: PendantSizeType;
  autoAdaptShapeEnabled: boolean;
  shapeAutoAdapted: boolean;
  currentText: string;
  labelById: Record<string, LabelProps | undefined>;
  img: {
    image: null | HTMLImageElement;
    imagePosition: { x: number; y: number };
    imageRotation: number;
    imageSize: number;
    imageFilter: PendantImageFilterType;
  };
  iconById: Record<string, IconProps>;
  currentIcon: string | null;
  texturesLoading: boolean;
  isSnapshot: boolean;
  snapshotIndex: number;
  snapshots: PendantState[];
  currentElement: ConstructorRootElement;
};

export interface PendantContextType {
  // UI
  state: PendantState;
  price: {
    data: number;
    isLoading: boolean;
  };
  shape: PendantShapeType;
  messageShape: PendantMessageShape;
  selectedMaterial: PendantMaterialType;
  selectedSize: PendantSizeType;
  imagePosition: { x: number; y: number };
  currentText: string;
  texturesLoading: boolean;
  clearState: () => void;
  onSetPrice: (cost: number) => void;
  onSelectShape: (shape: PendantShapeType) => void;
  onSelectMessageShape: (shape: PendantMessageShape) => void;
  onSelectMaterial: (material: PendantMaterialType) => void;
  onSelectSize: (size: PendantSizeType) => void;
  onChangeImagePosition: (position: { x: number; y: number } | null) => void;
  autoAdaptShapeEnabled: boolean;
  shapeAutoAdapted: boolean;
  onChangeAutoAdaptShape: (value: boolean) => void;
  onChangeShapeAutoAdapted: (value: boolean) => void;
  onSetSelectedItem: (item: PendantSelectedItem | null) => void;
  // text
  labelById: Record<string, LabelProps | undefined>;
  labelsNum: number;
  onCreateText: (newLabelId: string) => void;
  onChangeText: (id: string, text: string) => void;
  onChangeFontWeight: (id: string, fontWeight: string) => void;
  onChangeFontStyle: (id: string, fontStyle: string) => void;
  onChangeTextAlign: (id: string, align: string) => void;
  onChangeFont: (id: string, font: string) => void;
  onChangeFontSize: (id: string, size: number) => void;
  onChangeLetterSpacing: (id: string, space: number) => void;
  onChangeLineHeight: (id: string, space: number) => void;
  onChangeTextRotation: (id: string, rotation: number) => void;
  onChangeTextSize: (id: string, size: number) => void;
  onChangeTextDeformation: (id: string, deformation: number) => void;
  onChangeTextPosition: (id: string, position: { x: number; y: number } | null) => void;
  onTextRemove: (id: string) => void;
  // image
  image: HTMLImageElement | null;
  imageRotation: number;
  imageSize: number;
  imageFilter: PendantImageFilterType;
  onChangeImage: (image: HTMLImageElement | null) => void;
  onChangeImageRotation: (rotation: number) => void;
  onChangeImageSize: (size: number) => void;
  onChangeImageFilter: (filter: PendantImageFilterType) => void;
  // Icons
  iconById: Record<string, IconProps>;
  onAddIcon: (newId: string, image: string) => void;
  onRemoveIcon: (id: string) => void;
  currentIcon: string | null;
  onChangeIconPosition: (id: string, position: { x: number; y: number }) => void;
  onChangeIconScale: (id: string, scale: number) => void;
  onChangeIconRotation: (id: string, rotation: number) => void;
  // snapshots
  onChangeTexturesLoading: (isLoading: boolean) => void;
  onUndo: () => void;
  onRedo: () => void;
  isSnapshot: boolean;
  patchSnapshot: () => void;
  saveSnapshot: () => void;
  // constructor
  constructor: {
    config: Constructor;
    isLoading: boolean;
  };
  currentElement: ConstructorRootElement;
}
