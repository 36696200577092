import * as PIXI from 'pixi.js';

export class NameplateLabelMaterial {
  getTexture(
    app: PIXI.Application,
    label: PIXI.Text,
    textSize: number,
    materialTexture: PIXI.Texture,
  ) {
    const rt = PIXI.RenderTexture.create({
      width: label.canvas.width * textSize,
      height: label.canvas.height,
    });

    label.scale.x = textSize;
    label.anchor.set(0.5);
    label.x = rt.width / 2;
    label.y = rt.height / 2;

    const materialView = PIXI.Sprite.from(materialTexture);
    materialView.anchor.set(0.5);
    materialView.mask = label;
    materialView.width = rt.width * 2;
    materialView.height = rt.height * 2;
    materialView.x = rt.width / 2;
    materialView.y = rt.height / 2;

    // materialView.tint = 0x777777;
    //
    // app.renderer.render(materialView, {
    //   renderTexture: rt,
    //   clear: false,
    //   transform: new PIXI.Matrix(1, 0, 0, 1, -7, -7),
    // });
    //
    // this.getBoundsAndArea(app.renderer.plugins.extract.canvas(rt));
    //
    // materialView.tint = 0xffffff;

    app.renderer.render(materialView, { renderTexture: rt, clear: false });

    const texture = PIXI.Texture.from(rt.baseTexture);
    PIXI.Texture.removeFromCache(texture);

    return texture;
  }
}
