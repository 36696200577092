import {
  PendantMaterialType,
  PendantMessageShape,
  PendantShapeType,
  PendantSizeType,
} from './types';
import {
  ConstructorRootElement,
  getNestedShapes,
} from './constructor';
import { compareAndSaveEqualItems } from '@/utils/arrays';
import masksSpriteSheet from '../../../assets/images/pendants-texture-sheet/spritesheet.json';

export const pendantPivotByShape: Record<PendantShapeType, { x?: number; y?: number }> = {
  [PendantShapeType.HEART]: { y: 50 },
  [PendantShapeType.HEART2]: { y: 75 },
  [PendantShapeType.HEART3]: { y: 55 },
  [PendantShapeType.CIRCLE]: { y: 50 },
  [PendantShapeType.RECTANGLE]: { y: 50 },
  [PendantShapeType.BUBBLE]: { y: 50 },
  [PendantShapeType.OCTANGLE]: { y: 50 },
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const pendantAssetsContext = require.context('../../../assets/images/pendants/');

const assetByShape: Record<PendantShapeType, string> = {
  [PendantShapeType.HEART]: 'heart',
  [PendantShapeType.HEART2]: 'heart2',
  [PendantShapeType.HEART3]: 'heart3',
  [PendantShapeType.BUBBLE]: 'bubble',
  [PendantShapeType.OCTANGLE]: 'octangular',
  [PendantShapeType.RECTANGLE]: 'rectangular',
  [PendantShapeType.CIRCLE]: 'circle',
};

const assetByMessageShape: Record<PendantMessageShape, string> = {
  [PendantMessageShape.Normal]: 'normal',
  [PendantMessageShape.Long]: 'wide',
  [PendantMessageShape.High]: 'high',
  [PendantMessageShape.XLong]: 'xxwide',
  [PendantMessageShape.XHigh]: 'xxhigh',
};

const assetBySize: Record<PendantSizeType, string> = {
  [PendantSizeType.SMALL]: 's',
  [PendantSizeType.XSMALL]: 'xs',
  [PendantSizeType.LARGE]: 'l',
  [PendantSizeType.XLARGE]: 'xl',
  [PendantSizeType.MEDIUM]: 'm',
};

const assetByMaterial: Record<PendantMaterialType, string> = {
  [PendantMaterialType.SILVER]: 'silver',
  [PendantMaterialType.GOLD]: 'gold',
  [PendantMaterialType.ROSE_GOLD]: 'rose-gold',
};

export const getPendantAsset = (
  shape: PendantShapeType,
  material: PendantMaterialType,
  messageShape: PendantMessageShape,
  size: PendantSizeType,
): string => {
  return pendantAssetsContext(
    `./${assetByShape[shape]}-${assetByMessageShape[messageShape]}-${assetByMaterial[material]}-${assetBySize[size]}.png`,
  );
};

export const getPendantSVGAsset = (
  shape: PendantShapeType,
  messageShape: PendantMessageShape,
  size: PendantSizeType,
): string => {
  return pendantAssetsContext(
    `./svg/${assetByShape[shape]}-${assetByMessageShape[messageShape]}-${assetBySize[size]}.svg`,
  );
};

export const getLabelMaskAsset = (
  shape: PendantShapeType,
  messageShape: PendantMessageShape,
  size: PendantSizeType,
): string => {
  return pendantAssetsContext(
    `./masks/${assetByShape[shape]}-${assetByMessageShape[messageShape]}-${assetBySize[size]}.png`,
  );
};

export const getPossibleMessageShapes = (
  shape: PendantShapeType,
  size: PendantSizeType,
  currentElement: ConstructorRootElement,
): PendantMessageShape[] => {
  const allShapes = Object.entries(pendantSizeByShape[shape])
    .filter(([, value]) => value?.[size])
    .map(([messageShape]) => messageShape as PendantMessageShape);

  const enabledShapes = getNestedShapes(currentElement);
  const result = compareAndSaveEqualItems(allShapes, enabledShapes);

  return result;
};

export const PENDANT_MM_2_PIXEL = 20;

export const getPendantWidthMm = (
  shape: PendantShapeType,
  messageShape: PendantMessageShape,
  size: PendantSizeType,
): number => {
  if (!pendantSizeByShape[shape][messageShape]?.[size]) {
    console.error(
      `No pendant with specified parameters. Shape - ${shape}; MessageShape - ${messageShape}; Size - ${size}`,
    );
  }

  return pendantSizeByShape[shape][messageShape]?.[size]?.width ?? 10;
};

export const getPendantWidth = (
  shape: PendantShapeType,
  messageShape: PendantMessageShape,
  size: PendantSizeType,
): number => {
  return getPendantWidthMm(shape, messageShape, size) * PENDANT_MM_2_PIXEL;
};

export const getPendantTrimData = (
  shape: PendantShapeType,
  messageShape: PendantMessageShape,
  size: PendantSizeType,
) => {
  const frameName = `${assetByShape[shape]}-${assetByMessageShape[messageShape]}-${assetBySize[size]}.png`;
  const frameData = masksSpriteSheet.frames[frameName as keyof typeof masksSpriteSheet.frames];

  return {
    left: frameData.spriteSourceSize.x,
    top: frameData.spriteSourceSize.y,
    right: frameData.sourceSize.w - frameData.spriteSourceSize.x - frameData.spriteSourceSize.w,
    bottom: frameData.sourceSize.h - frameData.spriteSourceSize.y - frameData.spriteSourceSize.h,
    fullWidth: frameData.sourceSize.w,
    fullHeight: frameData.sourceSize.h,
    frameWidth: frameData.spriteSourceSize.w,
    frameHeight: frameData.spriteSourceSize.h,
  };
};

export const getPendantAnchorY = (
  shape: PendantShapeType,
  messageShape: PendantMessageShape,
  size: PendantSizeType,
) => {
  const trimData = getPendantTrimData(shape, messageShape, size);
  return (trimData.top + trimData.frameHeight / 2) / trimData.fullHeight;
}

export const pendantSizeByShape: Record<
  PendantShapeType,
  Record<
    PendantMessageShape,
    Record<PendantSizeType, { width: number; height: number } | null> | null
  >
> = {
  [PendantShapeType.BUBBLE]: {
    [PendantMessageShape.XLong]: null,
    [PendantMessageShape.Long]: {
      [PendantSizeType.XSMALL]: null,
      [PendantSizeType.SMALL]: { width: 13.4, height: 10 },
      [PendantSizeType.MEDIUM]: { width: 18.8, height: 14 },
      [PendantSizeType.LARGE]: { width: 24.2, height: 18 },
      [PendantSizeType.XLARGE]: null,
    },
    [PendantMessageShape.Normal]: {
      [PendantSizeType.XSMALL]: null,
      [PendantSizeType.SMALL]: { width: 11, height: 10 },
      [PendantSizeType.MEDIUM]: { width: 15.4, height: 14 },
      [PendantSizeType.LARGE]: { width: 19.8, height: 18 },
      [PendantSizeType.XLARGE]: null,
    },
    [PendantMessageShape.High]: {
      [PendantSizeType.XSMALL]: null,
      [PendantSizeType.SMALL]: { width: 10, height: 13.4 },
      [PendantSizeType.MEDIUM]: { width: 14, height: 18.8 },
      [PendantSizeType.LARGE]: { width: 18, height: 24.2 },
      [PendantSizeType.XLARGE]: null,
    },
    [PendantMessageShape.XHigh]: null,
  },
  [PendantShapeType.RECTANGLE]: {
    [PendantMessageShape.XLong]: {
      [PendantSizeType.XSMALL]: null,
      [PendantSizeType.SMALL]: { width: 14, height: 6.9 },
      [PendantSizeType.MEDIUM]: { width: 18, height: 8.9 },
      [PendantSizeType.LARGE]: { width: 22, height: 10.9 },
      [PendantSizeType.XLARGE]: null,
    },
    [PendantMessageShape.Long]: {
      [PendantSizeType.XSMALL]: { width: 13 + 1 / 3, height: 10 },
      [PendantSizeType.SMALL]: { width: 18 + 2 / 3, height: 14 },
      [PendantSizeType.MEDIUM]: { width: 24, height: 18 },
      [PendantSizeType.LARGE]: { width: 29 + 1 / 3, height: 22 },
      [PendantSizeType.XLARGE]: { width: 34 + 2 / 3, height: 26 },
    },
    [PendantMessageShape.Normal]: {
      [PendantSizeType.XSMALL]: { width: 10, height: 10 },
      [PendantSizeType.SMALL]: { width: 14, height: 14 },
      [PendantSizeType.MEDIUM]: { width: 18, height: 18 },
      [PendantSizeType.LARGE]: { width: 22, height: 22 },
      [PendantSizeType.XLARGE]: { width: 26, height: 26 },
    },
    [PendantMessageShape.High]: {
      [PendantSizeType.XSMALL]: { width: 10, height: 13 + 1 / 3 },
      [PendantSizeType.SMALL]: { width: 14, height: 18 + 2 / 3 },
      [PendantSizeType.MEDIUM]: { width: 18, height: 24 },
      [PendantSizeType.LARGE]: { width: 22, height: 29 + 1 / 3 },
      [PendantSizeType.XLARGE]: { width: 26, height: 34 + 2 / 3 },
    },
    [PendantMessageShape.XHigh]: {
      [PendantSizeType.XSMALL]: null,
      [PendantSizeType.SMALL]: { width: 6.9, height: 14 },
      [PendantSizeType.MEDIUM]: { width: 8.9, height: 18 },
      [PendantSizeType.LARGE]: { width: 10.9, height: 22 },
      [PendantSizeType.XLARGE]: null,
    },
  },
  [PendantShapeType.CIRCLE]: {
    [PendantMessageShape.XLong]: null,
    [PendantMessageShape.Long]: null,
    [PendantMessageShape.Normal]: {
      [PendantSizeType.XSMALL]: { width: 10, height: 10 },
      [PendantSizeType.SMALL]: { width: 14, height: 14 },
      [PendantSizeType.MEDIUM]: { width: 18, height: 18 },
      [PendantSizeType.LARGE]: { width: 22, height: 22 },
      [PendantSizeType.XLARGE]: { width: 26, height: 26 },
    },
    [PendantMessageShape.High]: null,
    [PendantMessageShape.XHigh]: null,
  },
  [PendantShapeType.OCTANGLE]: {
    [PendantMessageShape.XLong]: null,
    [PendantMessageShape.Long]: null,
    [PendantMessageShape.Normal]: {
      [PendantSizeType.XSMALL]: { width: 10, height: 10 },
      [PendantSizeType.SMALL]: { width: 14, height: 14 },
      [PendantSizeType.MEDIUM]: { width: 18, height: 18 },
      [PendantSizeType.LARGE]: { width: 22, height: 22 },
      [PendantSizeType.XLARGE]: null,
    },
    [PendantMessageShape.High]: null,
    [PendantMessageShape.XHigh]: null,
  },
  [PendantShapeType.HEART]: {
    [PendantMessageShape.XLong]: null,
    [PendantMessageShape.Long]: null,
    [PendantMessageShape.Normal]: {
      [PendantSizeType.XSMALL]: { width: 10, height: 10 + 5 / 9 },
      [PendantSizeType.SMALL]: { width: 14, height: 14 + 7 / 9 },
      [PendantSizeType.MEDIUM]: { width: 18, height: 19 },
      [PendantSizeType.LARGE]: { width: 22, height: 23 + 1 / 9 },
      [PendantSizeType.XLARGE]: null,
    },
    [PendantMessageShape.High]: null,
    [PendantMessageShape.XHigh]: null,
  },
  [PendantShapeType.HEART2]: {
    [PendantMessageShape.XLong]: null,
    [PendantMessageShape.Long]: null,
    [PendantMessageShape.Normal]: {
      [PendantSizeType.XSMALL]: { width: 10, height: 9 + 1 / 11 },
      [PendantSizeType.SMALL]: { width: 14, height: 12 + 8 / 11 },
      [PendantSizeType.MEDIUM]: { width: 18, height: 16 + 4 / 11 },
      [PendantSizeType.LARGE]: { width: 22, height: 20 },
      [PendantSizeType.XLARGE]: null,
    },
    [PendantMessageShape.High]: null,
    [PendantMessageShape.XHigh]: null,
  },
  [PendantShapeType.HEART3]: {
    [PendantMessageShape.XLong]: null,
    [PendantMessageShape.Long]: null,
    [PendantMessageShape.Normal]: {
      [PendantSizeType.XSMALL]: { width: 10, height: 9 + 1 / 11 },
      [PendantSizeType.SMALL]: { width: 14, height: 12 + 8 / 11 },
      [PendantSizeType.MEDIUM]: { width: 18, height: 16 + 4 / 11 },
      [PendantSizeType.LARGE]: { width: 22, height: 20 },
      [PendantSizeType.XLARGE]: null,
    },
    [PendantMessageShape.High]: null,
    [PendantMessageShape.XHigh]: null,
  },
};
