import React, { useState } from 'react';
// import { addMember } from '@/api';
import subPopup3 from '@assets/images/landing/svg/emily_img_mob.jpg'
import { Button } from '@mui/base';
import { ReactComponent as CrossIcon } from '@assets/images/icons/common/lightCross.svg';
import { motion } from 'framer-motion';
import classes from './MobileSubscriptionPopup.module.css';
import cls from 'classnames';

type Props = {
    onClose: () => void;
};

export const MobileSubscriptionPopup: React.FC<Props> = ({
    onClose,
}) => {

    const [userEmail, setUserEmail] = useState('');

    const handleChangeEmailText = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const newValue = e.currentTarget.value || '';
        setUserEmail(newValue);
    };

    const subscribeUser = async () => {
        // addMember({
        //     email: userEmail
        // }).then((data) => {
        //     // console.log(data.data.id)
        //     localStorage.setItem("mc_customerId", data.data.id.toString())
        //     onClose();
        // }).catch((err) => {
        //     alert('Sorry, something went wrong, please try again.');
        //     console.log(err);
        // });
    }


    return (
        <motion.div style={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            zIndex: 10001,
            // backgroundColor: '#3E4966',
            backdropFilter: 'blur(44px)',
        }}
            initial={{ opacity: 0, y: -100 }}
            animate={{ y: 0, opacity: 1, transition: { duration: 0.5 } }}
            className='bg-stylish-black/50'
        >
            <div className='shadow-3xlwarmContMob' style={{
                position: 'relative',
                width: 'full',
                height: window.innerHeight - 40,
                backgroundColor: '#F7E2D9',
                marginTop: 20,
                marginBottom: 20,
                marginLeft: 10,
                marginRight: 10,
                zIndex: 10001,
                borderRadius: 24
            }}>
                <img src={subPopup3} className='rounded-t-3xl' width={'100%'} height={391}></img>
                <Button
                    onClick={onClose}
                    className='border-0 w-[48px] h-[48px]'
                    style={{ position: 'absolute', right: 14, top: 8, background: 'rgba(0,0,0,0)' }}
                >
                    <CrossIcon />
                </Button>


                {/* <div style={{background: 'linear-gradient(0deg, rgba(252,225,216,1) 30%, rgba(255,233,225,0) 100%)', position: 'absolute', height: 0, top: 300, width: '100%'}}>

                </div> */}
                {/* <div
                    className='h-[381px] w-full left-0 top-[110px] right-0 pointer-events-none'
                    style={{
                        position: 'absolute',
                        background:
                            'linear-gradient(0deg, rgba(255,255,255,1) 30%, rgba(255,255,255,0) 100%, rgba(255,255,255,0) 100%)',
                    }}
                /> */}
                <div className='flex flex-col rounded-b-3xl top-[320px] justify-end items-center' style={{ position: 'absolute', bottom: window.innerHeight / 2 - 391 + 40 + 16 }}>
                    <p className='text-stylish-black font-extrabold text-[48px] text-center font-averta'>Get 15% off</p>
                    <p style={{ lineHeight: "28px" }} className='text-stylish-black leading-normal text-[21px] text-center pt-[12px] font-averta'>Subscribe to our newsletter and receive exclusive offers</p>
                    <div className='max-w-[330px] self-center w-80'>
                        <input
                            placeholder='Email'
                            onChange={handleChangeEmailText}
                            style={{
                                // backgroundColor: '#F5F5F5',
                                // fontFamily: 'Averta',
                                // fontWeight: 600,
                                // fontSize: '21px',
                                // lineHeight: '24px',
                                // color: '#3E4966',
                                // // position: relative;
                                // // transition: all .3s ease-in-out;
                                // border: '2px solid #fff'
                            }}
                            className={window.innerHeight < 700 ? cls(classes.TextInput, 'pl-4 rounded-2xl border-0 h-[56px] mt-[26px] w-full') : cls(classes.TextInput, 'pl-4 rounded-2xl border-0 h-[56px] mt-[36px] w-full')}
                        >
                        </input>
                    </div>
                    <Button
                        onClick={subscribeUser}
                        style={{ borderRadius: 30 }}
                        className={window.innerHeight < 700 ?
                            'self-center mt-[20px] w-[194px] border-0 font-averta py-4 text-white text-bold mb-6 font-bold text-[18px] cursor-pointer whitespace-nowrap bg-stylish-black normal-case'
                            :
                            'self-center mt-[20px] w-[194px] border-0 font-averta py-4 text-white text-bold -mb-8 font-bold text-[18px] cursor-pointer whitespace-nowrap bg-stylish-black normal-case'
                        }
                    >
                        Unlock discount
                    </Button>
                </div>
            </div>
        </motion.div>
    )
}

