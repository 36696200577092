import React, { useState } from 'react';
import { CloseButton } from '@/components/UI/buttons/CloseButton';
import { AppMode, useCommonState } from '@/hooks';
import { UndoRedoButtons } from '@/components/nameplate/page/UndoRedoButtons';
import { RulerButtons } from '@/components/UI/buttons/RulerButtons';
import { ZoomButton } from '@/components/UI/buttons/ZoomButton';
import { useMediaQuery } from '@mui/material';
import { MobileOrderButton } from '@/components/nameplate/page/MobileOrderButton';
import { useNavigate } from 'react-router-dom';
import ThreeDViewComponent from '@/pixi/nameplate-canvas-app/ThreedViewComponent';
import { Button } from '@/components/UI/buttons/Button';
import { ReactComponent as ThreedIcon } from "@assets/360.svg";

const NameplateHeader: React.FC = () => {
  const isMobile = useMediaQuery('(max-width: 1023px)');
  const { appMode, onChangeAppMode, setIs3dViewEnabled } = useCommonState();
  const isLanding = appMode === AppMode.LANDING;
  const navigate = useNavigate();
  const [count, setCount] = useState(0);

  const handleGoToCheckoutProcess = () => {
    navigate("/checkout");
  };

  const handle3DButton = React.useCallback(() => {
    setCount((count) => {
      if (count % 2 == 0) {
        setIs3dViewEnabled(true)
      } else {
        setIs3dViewEnabled(false)
      } 
      return count + 1
    });
  }, []);
  
  return appMode === AppMode.CONSTRUCTOR ? (
    <>
      {!isMobile ? <CloseButton /> : <ZoomButton />}
      {isMobile && (
        <div className={
          window.innerWidth < 390 ? 
          'flex justify-start items-start gap-1 fixed top-4 right-2 z-[1001]' 
          : 
          'flex justify-start items-start gap-1 fixed top-4 right-4 z-[1001]'
          }>
          <UndoRedoButtons whiteButtons={true} />
          <RulerButtons whiteButtons={true} />
          <div></div>
          <Button
              onClick={handle3DButton}
              isTransparent={true}
              style={{
                width: '96px',
                height: '48px',
                borderRadius: 123,
                backgroundColor: 'rgba(255,255,255,1)'
              }}
          >
            <ThreedIcon style={{
              position: 'relative',
              width: '44px',
              height: '24px',
            }} />
          </Button>
        </div>
      )}
    </>
  ) : (
    <></>
  );
};

export default NameplateHeader;
