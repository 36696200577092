import * as PIXI from 'pixi.js';
import { getBounds } from '../nameplate-canvas-app/LabelUtils';

const SCALE_FACTOR = 5;

export const getMeshLocalBounds = (app: PIXI.Application, mesh: PIXI.SimpleRope) => {
  const {
    x,
    y,
    mask,
    rotation,
    scale: { x: scaleX, y: scaleY },
  } = mesh;
  mesh.mask = null;
  mesh.rotation = 0;
  mesh.x = 0;
  mesh.y = 0;
  mesh.scale.set(1);

  const rt = PIXI.RenderTexture.create({
    width: (mesh.width * 2) / SCALE_FACTOR,
    height: (mesh.height * 2) / SCALE_FACTOR,
  });

  app.renderer.render(mesh, {
    renderTexture: rt,
    transform: new PIXI.Matrix(
      1 / SCALE_FACTOR,
      0,
      0,
      1 / SCALE_FACTOR,
      rt.width / 2,
      rt.height / 2,
    ),
  });

  mesh.x = x;
  mesh.y = y;
  mesh.mask = mask;
  mesh.rotation = rotation;
  mesh.scale.set(scaleX, scaleY);

  const canvas = app.renderer.plugins.extract.canvas(rt);
  const ctx = canvas.getContext('2d')!;
  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

  // const s = PIXI.Sprite.from(canvas);
  // app.stage.addChild(s);

  return getBounds(imageData, SCALE_FACTOR);
};
