import { PendantCanvasAppProps } from './PendantCanvasApp';
import { PendantCanvasAppRemoveIconViewState } from './PendantCanvasAppRemoveIcon';
import { Label } from './Label';
import { PendantCanvasAppLayout } from './PendantCanvasAppLayout';
import { GestureHandler } from '../common/controllers/types';
import { PendantSelectedItemType } from '../../hooks/pendant/use-pendant-settings';
import { ClipArt } from './ClipArts/ClipArt';

export const createLabelGesturesHandler = (
  layout: PendantCanvasAppLayout,
  label: Label,
): GestureHandler => {
  return {
    startPosition: null,
    rotationOffset: null,
    scaleMultiplier: null,
    target: label.inputArea,
    getSize(props: PendantCanvasAppProps): number {
      return label.root.scale.x;
    },
    setPosition(props: PendantCanvasAppProps, x: number, y: number) {
      const offsetX = label.root.x - label.inputArea.x;
      const offsetY = label.root.y - label.inputArea.y;
      props.setTextPosition(label.id, { x: x + offsetX, y: y + offsetY });
    },
    setRotation(props: PendantCanvasAppProps, rotation: number) {
      props.setTextRotation(label.id, rotation);
    },
    setSize(props: PendantCanvasAppProps, size: number) {
      props.setFontSize(label.id, size);
    },
    activate(props: PendantCanvasAppProps): void {
      props.setSelectedItem({ type: PendantSelectedItemType.Label, id: label.id });
    },
    onEnd(props: PendantCanvasAppProps) {
      if (props.removeIconViewState === PendantCanvasAppRemoveIconViewState.VisibleHover) {
        props.removeText(label.id);
        label.onRemove();
      }

      props.saveSnapshot();
    },
  };
};

export const createPendantImageGesturesHandler = (
  layout: PendantCanvasAppLayout,
): GestureHandler => {
  return {
    startPosition: null,
    rotationOffset: null,
    scaleMultiplier: null,
    target: layout.pendantImage,
    getSize(props: PendantCanvasAppProps): number {
      return layout.pendantImage.scale.x;
    },
    setPosition(props: PendantCanvasAppProps, x: number, y: number) {
      props.setImagePosition({ x, y });
    },
    setRotation(props: PendantCanvasAppProps, rotation: number) {
      props.setImageRotation(rotation);
    },
    setSize(props: PendantCanvasAppProps, size: number) {
      const pendantImageSizeMultiplier = Math.max(
        layout.pendant.texture.width / layout.pendantImage.texture.width,
        layout.pendant.texture.height / layout.pendantImage.texture.height,
      );

      props.setImageSize(size / pendantImageSizeMultiplier);
    },
    activate(props: PendantCanvasAppProps): void {
      props.setSelectedItem({ type: PendantSelectedItemType.Image, id: '' });
    },
    onEnd(props: PendantCanvasAppProps) {
      if (props.removeIconViewState === PendantCanvasAppRemoveIconViewState.VisibleHover) {
        props.setImage(null);
        props.setImageSize(1);
        props.setImagePosition(null);
        props.setImageRotation(0);
      }

      props.saveSnapshot();
    },
  };
};

export const createClipArtGesturesHandler = (clipArt: ClipArt): GestureHandler => {
  return {
    startPosition: null,
    rotationOffset: null,
    scaleMultiplier: null,
    target: clipArt.root,
    getSize(props: PendantCanvasAppProps): number {
      return clipArt.root.scale.x;
    },
    setPosition(props: PendantCanvasAppProps, x: number, y: number) {
      props.setClipArtPosition(clipArt.id, { x, y });
    },
    setRotation(props: PendantCanvasAppProps, rotation: number) {
      props.setClipArtRotation(clipArt.id, rotation);
    },
    setSize(props: PendantCanvasAppProps, size: number) {
      props.setClipArtScale(clipArt.id, size);
    },
    activate(props: PendantCanvasAppProps): void {
      props.setSelectedItem({ type: PendantSelectedItemType.Icon, id: clipArt.id });
    },
    onEnd(props: PendantCanvasAppProps) {
      if (props.removeIconViewState === PendantCanvasAppRemoveIconViewState.VisibleHover) {
        props.removeClipArt(clipArt.id);
      }

      props.saveSnapshot();
    },
  };
};
