import { Potrace } from './Potrace';
import svgpath from 'svgpath';
import potrace from 'potrace'


export type SvgExportData = { svg: string; bounds: SVGRect };

export const strokeImage = (originalCanvas: HTMLCanvasElement) => {
  const originalCtx = originalCanvas.getContext('2d')!;
  const imageData = originalCtx.getImageData(0, 0, originalCanvas.width, originalCanvas.height);

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d')!;
  canvas.width = originalCanvas.width;
  canvas.height = originalCanvas.height;

  ctx.fillStyle = 'white';
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  ctx.fillStyle = 'black';

  for (let x = 0; x < originalCanvas.width; x++) {
    for (let y = 0; y < originalCanvas.height; y++) {
      const i = (y * originalCanvas.width + x) * 4 + 3;
      const transparent = imageData.data[i] < 220;

      if (!transparent) {
        ctx.fillRect(x, Math.max(0, y + (transparent ? -1 : 0)), 1, 1);
      }
    }
  }

  return canvas;
};

export const strokeBlueImage = (originalCanvas: HTMLCanvasElement) => {
  const originalCtx = originalCanvas.getContext('2d')!;
  const imageData = originalCtx.getImageData(0, 0, originalCanvas.width, originalCanvas.height);

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d')!;
  canvas.width = originalCanvas.width;
  canvas.height = originalCanvas.height;

  // ctx.fillStyle = 'clear';
  // ctx.fillRect(0, 0, canvas.width, canvas.height);

  ctx.fillStyle = '#3E4966';

  for (let x = 0; x < originalCanvas.width; x++) {
    for (let y = 0; y < originalCanvas.height; y++) {
      const i = (y * originalCanvas.width + x) * 4 + 3;
      const transparent = imageData.data[i] < 220;

      if (!transparent) {
        ctx.fillRect(x, Math.max(0, y + (transparent ? -1 : 0)), 1, 1);
      }
    }
  }

  return canvas;
};

export const canvasToSvg = (canvas: HTMLCanvasElement): Promise<SvgExportData> => {

  // // Tracing

  // const trace = new potrace.Potrace();

  // // You can also pass configuration object to the constructor
  // trace.setParameters({
  //   threshold: -1,
  //   turdPolicy: 'black'
  // });

  // trace.loadImage(canvas.toDataURL(), function(err: any) {
  //   if (err) throw err;

  //   const svgString = trace.getSVG(); // returns SVG document contents
  //   console.log("svgString = " , svgString)
  // });
  Potrace.loadImageFromUrl(canvas.toDataURL());

  return new Promise((resolve) => {
    Potrace.process(function () {
      const svg = Potrace.getSVG(1);

      const el = document.createElement('div');
      el.innerHTML = svg;

      document.body.appendChild(el);

      const svgEl = el.querySelector('svg');
      const bounds = svgEl!.getBBox();

      document.body.removeChild(el);

      resolve({ svg, bounds });
    });
  });
};

export const scaleSvgPath = (svgData: SvgExportData, scale: number) => {
  const el = document.createElement('div');
  el.innerHTML = svgData.svg;

  document.body.appendChild(el);

  const svgEl = el.querySelector('svg')!;
  const pathEl = svgEl.childNodes[0] as SVGPathElement;

  let d = pathEl.getAttribute('d')!;
  d = d.replaceAll('M', 'ZM');
  d = d.startsWith('Z') ? d.slice(1) : d;
  d = svgpath(d).scale(scale).toString();
  pathEl.setAttribute('d', d);

  svgData.bounds = svgEl!.getBBox();
  svgData.svg = el.innerHTML;

  document.body.removeChild(el);
};
