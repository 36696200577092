import * as PIXI from 'pixi.js';

import * as TWEENS from '@tweenjs/tween.js';
import * as Hammer from 'hammerjs';

import { ICanvasApp } from '@/pixi';
import { RulerUnits, NameplateSizeLimitState, NameplateSizeType } from '@/hooks';
import { NameplateCanvasAppLayout } from './NameplateCanvasAppLayout';
import { NameplateCanvasAppAssetsLoader } from './NameplateCanvasAppAssetsLoader';
import { NameplateLabelProps } from './NameplateLabel';
import { createHammer } from '../common/createHammer';
import { ensureFullScreen } from '../common/ensureFullScreen';
import { createTweens } from '../common/createTweens';
import { BackgroundName } from './backgrounds';
import { NameplateCanvasAppExporter } from './NameplateCanvasAppExporter';

type Props = {
  appModeIsContructor: boolean;
  position: { x: number; y: number };
  backgroundName: BackgroundName;
  pendantSize: NameplateSizeType;
  zoom: number; // bg scale multiplier
  label: NameplateLabelProps;
  rulerMode: RulerUnits;
  rulerIsEnabled: boolean;
  isMobile: boolean;
  texturesLoading: boolean;
  setText: (text: string) => void;
  setFontSize: (size: number) => void;
  setLabelArea: (value: number) => void;
  setLabelWidth: (value: number) => void;
  setLabelHeight: (value: number) => void;
  setTexturesLoading: (isLoading: boolean) => void;
  chainSize: number;
  saveSnapshot: () => void;
  setNameplateSizeLimitState: (value: NameplateSizeLimitState) => void;
};

export class NameplateCanvasApp extends PIXI.Application implements ICanvasApp {
  private readonly assetsLoader: NameplateCanvasAppAssetsLoader;
  public readonly layout: NameplateCanvasAppLayout;

  public readonly hammer: InstanceType<typeof Hammer.Manager>;
  public readonly tweens: TWEENS.Group;

  private updateId = 1;

  constructor(parent: HTMLDivElement) {
    const isMac = navigator.platform.toUpperCase().indexOf('MAC')>=0;
    super({
      width: window.innerWidth,
      height: window.innerHeight,
      resolution: window.devicePixelRatio,
      antialias: !isMac,
      autoDensity: true,
      backgroundColor: 0xffffff,
      backgroundAlpha: 1,
    });

    parent.appendChild(this.view);

    this.tweens = createTweens(this);
    this.hammer = createHammer(this);

    this.assetsLoader = new NameplateCanvasAppAssetsLoader();
    this.layout = new NameplateCanvasAppLayout(this);
  }

  async update(props: Props) {
    if (!this.stage) return;

    this.updateId += 1;

    const updateId = this.updateId;
    let texturesLoaded = false;

    NameplateCanvasAppExporter.setApp(this);
    ensureFullScreen(this);

    setTimeout(() => {
      if (texturesLoaded || updateId !== this.updateId || !this.stage || props.texturesLoading)
        return;

      props.setTexturesLoading(true);
    }, 200);

    const textureByName = await this.assetsLoader.loadTextures(props);
    texturesLoaded = true;

    if (!this.stage) return;

    this.layout.render(props, textureByName);

    if (props.texturesLoading && updateId === this.updateId) {
      props.setTexturesLoading(false);
    }
  }

  destroy(): void {
    super.destroy(true, true);
    this.layout.controller?.destroy();
    this.hammer.destroy();
    this.tweens.removeAll();
  }
}

export type NameplateCanvasAppProps = Props;
