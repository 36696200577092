import * as PIXI from 'pixi.js';
import { roundToPrecision } from '@/utils/math';
import { strokeImage } from '../common/canvasToSvg';

const ALPHA_THRESHOLD = 10;
// const ALPHA_THRESHOLD_2 = 0;
const SCALE_FACTOR = 10;

export const getBoundsAndArea = (app: PIXI.Application, labelRoot: PIXI.Container) => {
  const rt = PIXI.RenderTexture.create({
    width: (labelRoot.width * 2) / SCALE_FACTOR,
    height: (labelRoot.height * 2) / SCALE_FACTOR,
  });

  app.renderer.render(labelRoot, {
    renderTexture: rt,
    transform: new PIXI.Matrix(
      1 / SCALE_FACTOR,
      0,
      0,
      1 / SCALE_FACTOR,
      rt.width / 2,
      rt.height / 2,
    ),
  });

  const canvas = app.renderer.plugins.extract.canvas(rt);
  const ctx = canvas.getContext('2d')!;
  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

  const area = getPixelsArea(imageData) * SCALE_FACTOR ** 2;

  const bounds = getBounds(imageData, SCALE_FACTOR, { minX: 0, minY: 0, maxX: 24, maxY: 24 });

  return { bounds, area };
};


export const getBoundsAndAreaAlternative = (app: PIXI.Application, labelRoot: PIXI.Container) => {
  const rt = PIXI.RenderTexture.create({
    width: (labelRoot.width * 2) / SCALE_FACTOR,
    height: (labelRoot.height * 2) / SCALE_FACTOR,
  });
  
  app.renderer.render(labelRoot, {
    renderTexture: rt,
    transform: new PIXI.Matrix(
      1 / SCALE_FACTOR,
      0,
      0,
      1 / SCALE_FACTOR,
      rt.width / 2,
      rt.height / 2,
    ),
  });

  const canvas = app.renderer.plugins.extract.canvas(rt);

  const blackCanvas = strokeImage(canvas)
  const ctx = blackCanvas.getContext('2d')!;
  const pixelsContata = ctx.getImageData(0,0, blackCanvas.width, blackCanvas.height);
  const pixelsCount = countWhitePixels(pixelsContata)
  // console.log(`Количество черных пикселей: ${pixelsCount}`);
  const boundingBox = getBoundingBox(pixelsContata);
  // console.log(`Ширина: ${boundingBox.width}`);
  // console.log(`Высота: ${boundingBox.height}`);
  const totalPixelsCount = boundingBox.width*boundingBox.height;
  return {pixelsCount: pixelsCount , totalPixelsCount: totalPixelsCount, newWidth: boundingBox.width, newHeight: boundingBox.height}
};

function countWhitePixels(imageData: any) {
  let whitePixelCount = 0;
  for (let i = 0; i < imageData.data.length; i += 4) {
    if (imageData.data[i] <= ALPHA_THRESHOLD && imageData.data[i + 1] <= ALPHA_THRESHOLD && imageData.data[i + 2] <= ALPHA_THRESHOLD ) {
      whitePixelCount++;
    }
  }
  return whitePixelCount;
}

function getBoundingBox(imageData: any) {
  let minX = Infinity;
  let minY = Infinity;
  let maxX = -Infinity;
  let maxY = -Infinity;

  for (let i = 0; i < imageData.data.length; i += 4) {
    if (imageData.data[i] <= ALPHA_THRESHOLD && imageData.data[i + 1] <= ALPHA_THRESHOLD && imageData.data[i + 2] <= ALPHA_THRESHOLD) {
      const x = (i/4) % imageData.width;
      const y = Math.floor((i/4) / imageData.width);

      minX = Math.min(minX, x);
      minY = Math.min(minY, y);
      maxX = Math.max(maxX, x);
      maxY = Math.max(maxY, y);
    }
  }

  return {
    width: maxX - minX + 1,
    height: maxY - minY + 1,
  };
}

const getColumnsAndRowsPixelsArea = (imageData: ImageData, width: number, height: number) => {
  const data = imageData.data;
  let result = 0;
  let topBorder = 0;
  let rightBorder = 0;
  let leftBorder = data.length;
  let bottomBorder = 0;
  console.log(data)
  let notWhite = 0;
  data.forEach((value, index) => {
    if (index%4 == 0) {
      if (value == 0) {
        notWhite+=1;
        result += 1;
        const iInWidth = (index/4)%width
        if (rightBorder <= iInWidth) {
          rightBorder = iInWidth;
        }
        if (leftBorder >= iInWidth) {
          leftBorder = iInWidth;
        }
        if (topBorder == 0) {
          topBorder = index;
        }

        bottomBorder = index;
      }
    }
  })

  const newTopPosition = Math.floor((topBorder/4)/width)
  const newBottomPosition = Math.ceil((bottomBorder/4)/width)


  let newWidth = rightBorder - leftBorder
  if (newWidth === 0) {
    newWidth = width
  }
  const newHeight = newBottomPosition - newTopPosition
 
  return {result, newWidth, newHeight};
};

const getPixelsArea = (imageData: ImageData) => {
  const data = imageData.data;
  let result = 0;

  for (let i = 3, l = data.length; i < l; i += 4) {
    if (data[i] > ALPHA_THRESHOLD) {
      result += 1;
    }
  }

  return result;
};

export const getBounds = (
  imageData: ImageData,
  scaleFactor: number,
  adjustments?: { minX: number; minY: number; maxX: number; maxY: number },
) => {
  const data = imageData.data;
  const result = {
    minX: Number.MAX_VALUE,
    minY: Number.MAX_VALUE,
    maxX: -Number.MAX_VALUE,
    maxY: -Number.MAX_VALUE,
  };

  for (let i = 3, l = data.length; i < l; i += 4) {
    if (data[i] > ALPHA_THRESHOLD) {
      const px = Math.floor(i / 4);
      const x = px % imageData.width;
      const y = Math.floor(px / imageData.width);

      result.minX = Math.min(result.minX, x);
      result.minY = Math.min(result.minY, y);
      result.maxX = Math.max(result.maxX, x);
      result.maxY = Math.max(result.maxY, y);
    }
  }

  result.minX -= imageData.width / 2;
  result.minY -= imageData.height / 2;
  result.maxX -= imageData.width / 2;
  result.maxY -= imageData.height / 2;

  result.minX *= scaleFactor;
  result.minY *= scaleFactor;
  result.maxX *= scaleFactor;
  result.maxY *= scaleFactor;

  // The adjustment may be needed when using high scale factor, which decreases precision
  if (adjustments) {
    result.minX += adjustments.minX;
    result.minY += adjustments.minY;
    result.maxX += adjustments.maxX;
    result.maxY += adjustments.maxY;
  }

  // todo UI fix because of different canvas extract results (see getBoundsAndArea method)

  result.minX = Number.isFinite(result.minX) ? roundToPrecision(result.minX, 1) : -10;
  result.minY = Number.isFinite(result.minY) ? roundToPrecision(result.minY, 1) : -10;
  result.maxX = Number.isFinite(result.maxX) ? roundToPrecision(result.maxX, 1) : 10;
  result.maxY = Number.isFinite(result.maxY) ? roundToPrecision(result.maxY, 1) : 10;

  // result.minX = Number.isFinite(result.minX) ? result.minX : -10;
  // result.minY = Number.isFinite(result.minY) ? result.minY : -10;
  // result.maxX = Number.isFinite(result.maxX) ? result.maxX : 10;
  // result.maxY = Number.isFinite(result.maxY) ? result.maxY : 10;

  return result;
};
