import * as PIXI from 'pixi.js';
import { BackgroundConfig, ChainSide } from '../nameplate-canvas-app/backgrounds';
import { PendantMaterialType } from '../../hooks';
import { NameplateMaterialType } from '../../hooks';
import { PendantCanvasAppTextureByName } from './PendantCanvasAppAssetsLoader';

export const nameplateMaterialByPendantMaterial: Record<
  PendantMaterialType,
  NameplateMaterialType
> = {
  [PendantMaterialType.SILVER]: NameplateMaterialType.SILVER,
  [PendantMaterialType.GOLD]: NameplateMaterialType.GOLD,
  [PendantMaterialType.ROSE_GOLD]: NameplateMaterialType.ROSE_GOLD,
};

export class PendantChain {
  public readonly chain: PIXI.Sprite;
  private readonly side: ChainSide;

  constructor(props: { chainsContainer: PIXI.Container; side: ChainSide }) {
    this.side = props.side;

    const { chainsContainer } = props;

    this.chain = new PIXI.Sprite();
    chainsContainer.addChild(this.chain);
  }

  setChain(
    bgConfig: BackgroundConfig,
    material: PendantMaterialType,
    chainRing: PIXI.Sprite,
    textureByName: PendantCanvasAppTextureByName,
  ) {
    const neckPosition = bgConfig[this.side].positionNeck;
    const pendantPosition = this.chain.parent.toLocal(bgConfig.chainRing.topPivot, chainRing);

    const assetProps = bgConfig[this.side].byMaterial[material];
    this.chain.x = pendantPosition.x;
    this.chain.y = pendantPosition.y;

    this.chain.pivot.x = assetProps.pivotNameplate.x;
    this.chain.pivot.y = assetProps.pivotNameplate.y;

    this.chain.texture = textureByName[this.side];

    const posDx = pendantPosition.x - neckPosition.x;
    const posDy = pendantPosition.y - neckPosition.y;

    const regDx = assetProps.pivotNameplate.x - assetProps.pivotNeck.x;
    const regDy = assetProps.pivotNameplate.y - assetProps.pivotNeck.y;

    this.chain.rotation = Math.atan2(posDy, posDx) - Math.atan2(regDy, regDx);
    this.chain.scale.set(Math.hypot(posDy, posDx) / Math.hypot(regDy, regDx));
  }
}
