import React from 'react';
import Lottie from 'lottie-react';
import placeholderJSON from '@assets/animations/two-in-town-preloader.json';
import { Fade } from '@mui/material';

type Props = {
  open: boolean;
  width?: number;
};

export const Preloader: React.FC<Props> = ({ open, width }) => {
  return (
    <Fade in={open} timeout={250}>
      <div className='fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-[104px] w-[104px] bg-white rounded-full z-10050 overflow-hidden flex justify-center items-center shadow-loaderWarm'>
        <Lottie
          style={{
            width: width || 55,
            background: 'transparent',
          }}
          animationData={placeholderJSON}
          loop={true}
        />
      </div>
    </Fade>
  );
};
