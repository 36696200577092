import React, { createContext, useCallback, useContext, useRef, useState } from 'react';

import { AppMode, CommonStateContext, RulerUnits } from './types';
import { DEFAULT_MESSAGE_TIMEOUT } from '../nameplate/use-nameplate-settings/messages';
import { boolean } from 'yup';

export const CommonState = createContext({} as CommonStateContext);

export const CommonStateContextProvider: React.FC<{ children: React.ReactElement }> = ({
  children,
}) => {
  const [isOrderAvailable, setIsOrderAvailable] = useState<boolean>(false);
  const [rulerIsEnabled, setRulerIsEnabled] = useState<boolean>(false);
  const [is3dViewEnabled, setIs3dViewEnabled] = useState<boolean>(false);
  const [rulerMode, setRulerMode] = useState<RulerUnits>(RulerUnits.CM);
  const [zoom, setZoom] = useState<number>(1);
  const [message, setMessage] = useState<{ content: string; isActive: boolean }>({
    content: '',
    isActive: false,
  });
  const [appMode, setAppMode] = useState(AppMode.LANDING);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const timer = useRef<any>(null);

  const setMenuState = useCallback((isOpen: boolean) => {
    setIsOpenMenu(isOpen);
  }, []);

  const setMode = (mode: AppMode) => {
    setAppMode(mode);
  }

  const handleChangeOrderAvailable = React.useCallback((isAvailable: boolean) => {
    setIsOrderAvailable(isAvailable);
  }, []);

  const handleChangeRulerIsEnabled = React.useCallback((isEnable: boolean) => {
    setRulerIsEnabled(isEnable);
  }, []);

  const handleSelectRulerMode = React.useCallback((mode: RulerUnits) => {
    setRulerMode(mode);
  }, []);


  const handleSelect3DMode = React.useCallback((isEnable: boolean) => {
    setIs3dViewEnabled(isEnable);
  }, []);

  const handleChangeZoom = React.useCallback((s: number) => {
    setZoom(s);
  }, []);

  const handleCallMessage = React.useCallback(
    (content: string, timeout = DEFAULT_MESSAGE_TIMEOUT) => {
      if (timer.current !== null) {
        clearTimeout(timer.current);
      }

      setMessage({ content, isActive: true });

      timer.current = setTimeout(() => {
        setMessage({ content: '', isActive: false });
      }, timeout);
    },
    [],
  );

  return (
    <CommonState.Provider
      value={{
        is3dViewEnabled,
        setIs3dViewEnabled: handleSelect3DMode,
        isOpenMenu,
        onChangeMenuState: setMenuState,
        appMode,
        isOrderAvailable,
        rulerIsEnabled,
        rulerMode,
        zoom,
        message,
        onChangeAppMode: setMode,
        onChangeOrderAvailable: handleChangeOrderAvailable,
        onChangeRulerIsEnabled: handleChangeRulerIsEnabled,
        onSelectRulerMode: handleSelectRulerMode,
        onChangeZoom: handleChangeZoom,
        callMessage: handleCallMessage,
      }}
    >
      {children}
    </CommonState.Provider>
  );
};

export const useCommonState = () => {
  const context = useContext(CommonState);
  return context;
};
