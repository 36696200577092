import { api } from '@/utils/axios';
import { PriceDto, ShippingRatesPayload, ShippingRatesResponse } from './dto';

export const getShippingRates = async (data: ShippingRatesPayload) => {
  const res = await api.post<ShippingRatesResponse>('/shipment/rates', data);
  return res.data;
};

export const getPrice = async (data: {
  weight: number;
  material: number;
  quantity: number;
  rateId?: string;
  chainLength: number;
}) => {
  const res = await api.post<PriceDto>('shipment/price', data);
  return res.data;
};
