import React from 'react';
import cls from "classnames";
import classes from './ZoomEditor.module.css';
import { ZoomPicker } from "../../../common/ZoomPicker";

export const ZoomEditor: React.FC = () => {
  return (
      <>
        <div className='w-full'>
          <div className={cls(classes.title, 'z-20 flex justify-center')}>
            <span className='text-[21px]'>Zoom</span>
          </div>

          <div className='w-full flex justify-center'>
            <ZoomPicker min={0.1} max={0.6} step={0.00001} isHorizontal={true} />
          </div>
        </div>
      </>
  );
}