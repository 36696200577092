import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';
import {
  ContextType,
  NameplateImages,
  NameplateMaterialType,
  NameplateSizeLimitState,
  NameplateSizeType,
  State,
} from './types';
import { patchSnapshot, redo, saveSnapshot, undo } from './snapshots';
import { useCommonState, useGetOrder } from '@/hooks';
import { DEFAULT_PRICE } from '@/hooks/use-common-state/types';
import {
  getSizeLimitEmptyMessage,
  getSizeLimitExceededMessage,
  getSizeLimitWarnMessage,
} from './messages';
import {
  Constructor,
  defaultNameplateConstructor,
  getDefaultFont,
  getDefaultMaterialElement,
  getDefaultText,
} from './constructor';
import { NameplateCanvasAppExporter } from '@/pixi/nameplate-canvas-app/NameplateCanvasAppExporter';
import { getMaterialNameByNumber } from '@/utils/nameplate';
import fonts from '@components/nameplate/editors/text/fonts';

export const NameplateSettings = createContext({} as ContextType);

enum ActionType {
  SAVE_SNAPSHOT = 'save_snapshot',
  PATCH_SNAPSHOT = 'patch_snapshot',
  UNDO = 'undo',
  REDO = 'redo',
  SET_PRICE = 'set_price',
  SET_AREA = 'set_area',
  SET_BASE_AREA = 'set_base_area',
  SET_BASE_WIDTH = 'set_base_width',
  SET_BASE_HEIGHT = 'set_base_height',
  SET_BASE_PRICE = 'set_base_price',
  SET_CHAIN_PRICE = 'set_chain_price',
  SET_MATERIAL_PRICE = 'set_material_price',
  SET_SELECTED_MATERIAL = 'set_selected_material',
  SET_TEXT = 'set_text',
  SET_FONT = 'set_font',
  SET_FONT_WEIGHT = 'set_font_weight',
  SET_FONT_STYLE = 'set_font_style',
  SET_FONT_SIZE = 'set_font_size',
  SET_LETTER_SPACING = 'set_letter_spacing',
  SET_TEXT_DEFORMATION = 'set_text_deformation',
  SET_TEXT_SIZE = 'set_text_size',
  SET_TEXTURES_LOADING = 'set_textures_loading',
  SET_SIZE_LIMIT_STATE = 'set_size_limit_state',
  SET_CHAIN_TYPE = 'set_chain_type',
  SET_CHAIN_SIZE = 'set_chain_size',
  SET_WIDTH_IN_MM = 'set_width_in_mm',
  SET_HEIGHT_IN_MM = 'set_height_in_mm',
  RESET_STATE = 'reset_state',
}

export const defaultArea = 1;

const initialState: State = {
  price: {
    data: DEFAULT_PRICE,
    isLoading: true,
  },
  area: defaultArea,
  baseArea: defaultArea,
  basePrice: 0,
  baseWidth: 0,
  baseHeight: 0,
  selectedMaterial: getDefaultMaterialElement(defaultNameplateConstructor)
    .title as NameplateMaterialType,
  text: getDefaultText(defaultNameplateConstructor),
  font: getDefaultFont(defaultNameplateConstructor),
  fontWeight: 400,
  fontStyle: 'normal',
  fontSize: NameplateSizeType.MEDIUM,
  letterSpacing: 0,
  textDeformation: 0, // text curving
  textSize: 1, // text stretching
  texturesLoading: true,
  sizeLimitState: NameplateSizeLimitState.Allowed,
  chainType: 'Cable link',
  chainSize: 16,
  widthInMM: 0,
  heightInMM: 0,
  chainPrice: 0,
  materialPrice: 0,
  isSnapshot: false,
  snapshotIndex: 0,
  snapshots: [],
};
initialState.snapshots.push(initialState);

function reducer(state: State, action: { type: ActionType; value: any }) {
  state = { ...state, isSnapshot: false };

  switch (action.type) {
    case ActionType.SAVE_SNAPSHOT:
      return saveSnapshot(state);
    case ActionType.PATCH_SNAPSHOT:
      return patchSnapshot(state);
    case ActionType.UNDO:
      return undo(state);
    case ActionType.REDO:
      return redo(state);
    case ActionType.SET_PRICE: {
      return { ...state, price: action.value };
    }
    case ActionType.SET_AREA: {
      return { ...state, area: action.value };
    }
    case ActionType.SET_BASE_HEIGHT: {
      return { ...state, baseHeight: action.value };
    }
    case ActionType.SET_BASE_PRICE: {
      return { ...state, basePrice: action.value };
    }
    case ActionType.SET_CHAIN_PRICE: {
      return { ...state, chainPrice: action.value };
    }
    case ActionType.SET_MATERIAL_PRICE: {
      return { ...state, materialPrice: action.value };
    }
    case ActionType.SET_BASE_WIDTH: {
      return { ...state, baseWidth: action.value };
    }
    case ActionType.SET_BASE_AREA: {
      return { ...state, baseArea: action.value };
    }
    case ActionType.SET_SELECTED_MATERIAL: {
      return { ...state, selectedMaterial: action.value };
    }
    case ActionType.SET_TEXT: {
      return { ...state, text: action.value };
    }
    case ActionType.SET_FONT: {
      return { ...state, font: action.value };
    }
    case ActionType.SET_FONT_WEIGHT: {
      return { ...state, fontWeight: action.value };
    }
    case ActionType.SET_FONT_STYLE: {
      return { ...state, fontStyle: action.value };
    }
    case ActionType.SET_FONT_SIZE: {
      return { ...state, fontSize: action.value };
    }
    case ActionType.SET_LETTER_SPACING: {
      return { ...state, letterSpacing: action.value };
    }
    case ActionType.SET_TEXT_DEFORMATION: {
      return { ...state, textDeformation: action.value };
    }
    case ActionType.SET_TEXT_SIZE: {
      return { ...state, textSize: action.value };
    }
    case ActionType.SET_TEXTURES_LOADING: {
      return { ...state, texturesLoading: action.value };
    }
    case ActionType.SET_SIZE_LIMIT_STATE: {
      return { ...state, sizeLimitState: action.value };
    }
    case ActionType.SET_CHAIN_TYPE: {
      return { ...state, chainType: action.value };
    }
    case ActionType.SET_CHAIN_SIZE: {
      return { ...state, chainSize: action.value };
    }
    case ActionType.SET_WIDTH_IN_MM: {
      return { ...state, widthInMM: action.value };
    }
    case ActionType.SET_HEIGHT_IN_MM: {
      return { ...state, heightInMM: action.value };
    }
    case ActionType.RESET_STATE: {
      return initialState;
    }

    default: {
      throw new Error('Used unsupported action type');
    }
  }
}

export const NameplateSettingsContextProvider: React.FC<{ children: React.ReactElement }> = ({
  children,
}) => {
  const { callMessage } = useCommonState();
  const [state, dispatch]: [State, any] = useReducer(reducer, initialState);
  const [images, setImages] = useState<NameplateImages | null>(null);

  const [constructor] = useState<{ config: Constructor; isLoading: boolean }>({
    config: defaultNameplateConstructor,
    isLoading: false,
  });

  const { orderData } = useGetOrder();

  useEffect(() => {
    if (!orderData) {
      return;
    }

    saveImages(orderData.product.models, orderData.product.images, "", orderData.product.pngImages);

    handleChangeWidthInMM(orderData.product.width);
    handleChangeHeightInMM(orderData.product.height);
    handleChangeChainSize(orderData.product.chainLength);
    handleSelectMaterial(getMaterialNameByNumber(orderData.product.material));
    handleChangeText(orderData.product.name);
    handleChangeFont(orderData.product.font || getDefaultFont(defaultNameplateConstructor));
    handleSetArea(orderData.product.area || defaultArea);
    handleSetBaseArea(orderData.product.area || defaultArea);
    handleSetBaseWidth(orderData.product.width);
    handleSetBaseHeight(orderData.product.height);
    handleSetBasePrice(orderData.product.price);
    handleSetPrice(orderData.product.price);
  }, [orderData?.id]);


  const handleUndo = () => dispatch({ type: ActionType.UNDO });
  const handleRedo = () => dispatch({ type: ActionType.REDO });
  const handleSaveSnapshot = () => dispatch({ type: ActionType.SAVE_SNAPSHOT });
  const handlePatchSnapshot = () => dispatch({ type: ActionType.PATCH_SNAPSHOT });

  const handleClearState = React.useCallback(() => {
    dispatch({ type: ActionType.SET_SELECTED_MATERIAL, value: NameplateMaterialType.GOLD });
    dispatch({ type: ActionType.SAVE_SNAPSHOT });
  }, []);

  const handleSetPrice = React.useCallback((price: number, isLoading = false) => {
    dispatch({ type: ActionType.SET_PRICE, value: { data: price, isLoading } });
  }, []);

  const handleSetArea = React.useCallback(
    (area: number) => {
      dispatch({ type: ActionType.SET_AREA, value: area });
    },
    [state],
  );

  const handleSetBaseArea = React.useCallback(
    (baseArea: number) => {
      dispatch({ type: ActionType.SET_BASE_AREA, value: baseArea });
    },
    [state],
  );

  const handleSetBaseWidth = React.useCallback(
    (baseWidth: number) => {
      dispatch({ type: ActionType.SET_BASE_WIDTH, value: baseWidth });
    },
    [state],
  );

  const handleSetBaseHeight = React.useCallback(
    (baseHeight: number) => {
      dispatch({ type: ActionType.SET_BASE_HEIGHT, value: baseHeight });
    },
    [state],
  );

  const handleSetBasePrice = React.useCallback(
    (basePrice: number) => {
      dispatch({ type: ActionType.SET_BASE_PRICE, value: basePrice });
    },
    [state],
  );

  const handleSetChainPrice = React.useCallback(
    (price: number) => {
      dispatch({ type: ActionType.SET_CHAIN_PRICE, value: price });
    },
    [state],
  );

  const handleSetMaterialPrice = React.useCallback(
    (price: number) => {
      dispatch({ type: ActionType.SET_MATERIAL_PRICE, value: price });
    },
    [state],
  );

  const handleSelectMaterial = React.useCallback(
    (material: NameplateMaterialType, saveSnapshot = true) => {
      if (state.selectedMaterial !== material) {
        dispatch({ type: ActionType.SET_SELECTED_MATERIAL, value: material });
        if (saveSnapshot) {
          dispatch({ type: ActionType.SAVE_SNAPSHOT });
        }
      }
    },
    [state],
  );

  const handleChangeText = React.useCallback(
    (text: string, saveSnapshot = true) => {
      if (state.text !== text) {
        dispatch({ type: ActionType.SET_TEXT, value: text });
        if (saveSnapshot) {
          dispatch({ type: ActionType.SAVE_SNAPSHOT });
        }
      }
    },
    [state],
  );

  const handleChangeFont = React.useCallback((font: string, saveSnapshot = true) => {
    dispatch({ type: ActionType.SET_FONT, value: font });
    if (saveSnapshot) {
      dispatch({ type: ActionType.SAVE_SNAPSHOT });
    }
  }, []);

  const handleChangeFontWeight = React.useCallback((fontWeight: string) => {
    dispatch({ type: ActionType.SET_FONT_WEIGHT, value: fontWeight });
    dispatch({ type: ActionType.SAVE_SNAPSHOT });
  }, []);

  const handleChangeFontStyle = React.useCallback((fontStyle: string) => {
    dispatch({ type: ActionType.SET_FONT_STYLE, value: fontStyle });
    dispatch({ type: ActionType.SAVE_SNAPSHOT });
  }, []);

  const handleChangeFontSize = React.useCallback(
    (size: number) => {
      dispatch({ type: ActionType.SET_FONT_SIZE, value: size });
    },
    [state],
  );

  const handleChangeLetterSpacing = React.useCallback((space: number) => {
    dispatch({ type: ActionType.SET_LETTER_SPACING, value: space });
  }, []);

  const handleChangeTextDeformation = React.useCallback((deformation: number) => {
    dispatch({ type: ActionType.SET_TEXT_DEFORMATION, value: deformation });
  }, []);

  const handleChangeTextSize = React.useCallback((size: number) => {
    dispatch({ type: ActionType.SET_TEXT_SIZE, value: size });
  }, []);

  const handleChangeTexturesLoading = React.useCallback((isLoading: boolean) => {
    dispatch({ type: ActionType.SET_TEXTURES_LOADING, value: isLoading });
  }, []);

  const handleChangeSizeLimitState = React.useCallback(
    (limitState: NameplateSizeLimitState) => {
      if (limitState !== state.sizeLimitState) {
        dispatch({ type: ActionType.SET_SIZE_LIMIT_STATE, value: limitState });

        if (limitState === NameplateSizeLimitState.Warn) {
          callMessage(getSizeLimitWarnMessage());
          return;
        } else if (limitState === NameplateSizeLimitState.Exceeded) {
          callMessage(getSizeLimitExceededMessage());
          return;
        } else if (limitState === NameplateSizeLimitState.Empty) {
          callMessage(getSizeLimitEmptyMessage());
          return;
        }
      }
    },
    [state],
  );

  const handleChangeChainType = React.useCallback((chainType: string) => {
    dispatch({ type: ActionType.SET_CHAIN_TYPE, value: chainType });
    dispatch({ type: ActionType.SAVE_SNAPSHOT });
  }, []);

  const handleChangeChainSize = React.useCallback((chainSize: number) => {
    dispatch({ type: ActionType.SET_CHAIN_SIZE, value: chainSize });
    dispatch({ type: ActionType.SAVE_SNAPSHOT });
  }, []);

  const handleChangeWidthInMM = React.useCallback((width: number) => {
    dispatch({ type: ActionType.SET_WIDTH_IN_MM, value: width });
  }, []);

  const handleChangeHeightInMM = React.useCallback((height: number) => {
    dispatch({ type: ActionType.SET_HEIGHT_IN_MM, value: height });
  }, []);

  const exportImages = async () => {
    handleChangeTexturesLoading(true);
    // setImages({
    //   preview: undefined,
    //   previewWithoutChain: undefined,
    //   engraving: undefined,
    //   pngEngraving: undefined,
    //   isLoading: true,
    // });
    // const images = await NameplateCanvasAppExporter.export();

    // setImages({
    //   preview: images?.previewBase64,
    //   engraving: images?.pendant.svg,
    //   pngEngraving: images?.pngPendant,
    //   previewWithoutChain: images?.previewWithoutChain,
    //   isLoading: false,
    // });
  };

  const saveImages = (engraving: string, preview: string, previewWithoutChain: string, pngNameplate: string) => {
    setImages({
      preview: preview,
      engraving: engraving,
      pngEngraving: pngNameplate,
      previewWithoutChain: previewWithoutChain,
      isLoading: false,
    });
  };

  const resetState = React.useCallback(() => {
    dispatch({ type: ActionType.RESET_STATE });
    setImages(null);
  }, []);

  return (
    <NameplateSettings.Provider
      value={{
        resetState,
        state,
        price: state.price,
        area: state.area,
        baseArea: state.baseArea,
        baseHeight: state.baseHeight,
        baseWidth: state.baseWidth,
        basePrice: state.basePrice,
        selectedMaterial: state.selectedMaterial,
        text: state.text,
        font: state.font,
        fontWeight: state.fontWeight,
        fontStyle: state.fontStyle,
        fontSize: state.fontSize,
        letterSpacing: state.letterSpacing,
        textDeformation: state.textDeformation,
        textSize: state.textSize,
        texturesLoading: state.texturesLoading,
        sizeLimitState: state.sizeLimitState,
        chainType: state.chainType,
        chainSize: state.chainSize,
        widthInMM: state.widthInMM,
        heightInMM: state.heightInMM,
        chainPrice: state.chainPrice,
        materialPrice: state.materialPrice,
        clearState: handleClearState,
        onSetPrice: handleSetPrice,
        onChangeArea: handleSetArea,
        onChangeBaseArea: handleSetBaseArea,
        onChangeBaseHeightInMM: handleSetBaseHeight,
        onChangeBaseWidthInMM: handleSetBaseWidth,
        onChangeBasePrice: handleSetBasePrice,
        onSetChainPrice: handleSetChainPrice,
        onSetMaterialPrice: handleSetMaterialPrice,
        onSelectMaterial: handleSelectMaterial,
        onChangeText: handleChangeText,
        onChangeFont: handleChangeFont,
        onChangeFontWeight: handleChangeFontWeight,
        onChangeFontStyle: handleChangeFontStyle,
        onChangeFontSize: handleChangeFontSize,
        onChangeLetterSpacing: handleChangeLetterSpacing,
        onChangeTextDeformation: handleChangeTextDeformation,
        onChangeTextSize: handleChangeTextSize,
        onChangeTexturesLoading: handleChangeTexturesLoading,
        onChangeSizeLimitState: handleChangeSizeLimitState,
        onChangeChainType: handleChangeChainType,
        onChangeChainSize: handleChangeChainSize,
        onChangeWidthInMM: handleChangeWidthInMM,
        onChangeHeightInMM: handleChangeHeightInMM,
        onUndo: handleUndo,
        onRedo: handleRedo,
        isSnapshot: state.isSnapshot,
        patchSnapshot: handlePatchSnapshot,
        saveSnapshot: handleSaveSnapshot,
        constructor,
        images,
        exportImages,
        saveImages: saveImages,
      }}
    >
      {children}
    </NameplateSettings.Provider>
  );
};

export const useNameplateSettings = () => {
  const context = useContext(NameplateSettings);
  return context;
};
