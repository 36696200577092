import * as PIXI from 'pixi.js';
import { Label } from './Label';

const scale = 0.2;

const createLabelCanvas = (
  app: PIXI.Application,
  label: Label,
  pendantTexture: PIXI.Texture,
  anchorY: number,
) => {
  const rootMask = label.root.mask;
  label.root.mask = null;

  const rt = PIXI.RenderTexture.create({
    width: (pendantTexture.width + 100) * scale,
    height: (pendantTexture.height + 100) * scale,
  });

  app.renderer.render(label.root, {
    renderTexture: rt,
    transform: new PIXI.Matrix(scale, 0, 0, scale, rt.width / 2, rt.height * anchorY),
  });

  const labelCanvas = app.renderer.plugins.extract.canvas(rt);
  rt.destroy(true);

  label.root.mask = rootMask;

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d')!;
  canvas.width = labelCanvas.width;
  canvas.height = labelCanvas.height;
  ctx.fillStyle = '#ffffff';
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.drawImage(labelCanvas, 0, 0);

  return canvas;
};

// NOTE: we check just red part for better performance
const everyPixelWhite = (pixels: Uint8ClampedArray) => {
  for (let i = 0; i < pixels.length; i += 4) {
    if (pixels[i] > 200) continue;
    return false;
  }

  return true;
};

export const isTextOutOfPendant = (
  app: PIXI.Application,
  label: Label,
  pendantTexture: PIXI.Texture,
  anchorY: number,
  debug = false,
): boolean => {
  const canvas = createLabelCanvas(app, label, pendantTexture, anchorY);
  const ctx = canvas.getContext('2d')!;

  if (everyPixelWhite(ctx.getImageData(0, 0, canvas.width, canvas.height).data)) {
    return true;
  }

  ctx.scale(scale, scale);

  ctx.filter = 'brightness(99999)';
  ctx.drawImage(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    pendantTexture.baseTexture.resource.source,
    canvas.width / 2 / scale - pendantTexture.width / 2,
    canvas.height * anchorY / scale - pendantTexture.height * anchorY,
  );

  if (debug) {
    canvas.style.position = 'absolute';
    canvas.style.top = '0';
    canvas.style.left = '0';
    document.body.appendChild(canvas);
  }

  if (!everyPixelWhite(ctx.getImageData(0, 0, canvas.width, canvas.height).data)) {
    return true;
  }

  return false;
};
