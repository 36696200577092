import React, { useEffect, useState } from 'react';
import { Container } from '../Container';
import { motion } from 'framer-motion';
import { ReactComponent as AbbySVG } from '../../../../assets/images/landing/svg/Abby.svg';
import { ReactComponent as MoreThanGiftLipsSVG } from '../../../../assets/images/landing/svg/more-than-gift-lips.svg';
import { cloudinaryImages } from '@/utils/cloudinary';
import { AppMode, useCommonState } from '@/hooks';
import ReactGA from 'react-ga4'
import { usePrice } from '@/hooks/use-price';

import { useMediaQuery } from '@mui/material';

const animationImageContainer = {
  hidden: { y: 100, opacity: 0 },
  visible: (custom: number) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: custom * 0.2,
      duration: 1,
    },
  }),
};

export const MoreThanGift = () => {
  const { onChangeAppMode } = useCommonState();
  const isMobile = useMediaQuery('(max-width: 1023px)');

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const { priceData } = usePrice()

  const handleOpenConstructor = () => {


    ReactGA.gtag("event", "view_item", {
      currency: "USD",
      value: priceData?.singleProductPrice
    })

    // onChangeAppMode(AppMode.CONSTRUCTOR);
    // window.scrollTo({ top: 0, behavior: 'smooth' });

    const cb = window.scrollTo(0, 0);
    setTimeout(function () { //Start the timer
        onChangeAppMode(AppMode.CONSTRUCTOR);
    }.bind(this), scrollPosition/10)
  };

  return (
    <motion.section
      initial='hidden'
      whileInView='visible'
      viewport={{ amount: 0.15, once: true }}
      className='pt-20'
    >
      <Container>
        <div className='flex flex-col lg:flex-row justify-between'>
          <motion.div
            custom={1}
            variants={animationImageContainer}
            className={'rounded-3xl relative flex flex-col items-center grow'}
          >
            <AbbySVG
              className={isMobile ? 'h-auto max-w-full mr-10 mt-10 mb-16' : 'max-w-full mt-24'}
            // src={cloudinaryImages.landing.images.moreThanGiftPlate}
            // alt='moreThanGiftPlate'
            />
          </motion.div>
          <motion.div
            custom={1}
            variants={animationImageContainer}
            className={isMobile ? 'flex flex-col items-center -mt-20' : 'flex flex-col items-center pt-60'}
          >
            <MoreThanGiftLipsSVG className={isMobile ? 'mb-4' : 'mb-6'} />
            <h3 style={isMobile ? { lineHeight: "48px" } : { lineHeight: "72px" }} className={isMobile ? 'text-center text-terracotta text-[56px] lg:text-[80px] leading-[72px] font-albra break-words mb-4' : 'text-center text-terracotta text-[56px] lg:text-[80px] leading-[72px] font-albra break-words mb-8 [text-wrap:balance]'}>
              Dare to Be Different
            </h3>
            <p style={isMobile ? { lineHeight: "24px" } : {}} className={isMobile ? 'max-w-[800px] text-center text-lg lg:text-2xl text-stylish-black font-averta break-words mb-12' : 'max-w-[800px] text-center text-lg lg:text-2xl text-stylish-black font-averta break-words mb-32'}>
              When words fall short, let our piece do the talking. Craft a piece that voices the whispers of your heart
            </p>
          </motion.div>
        </div>
        <motion.div
          custom={1}
          variants={animationImageContainer}
          className='flex flex-col lg:flex-row gap-x-[24px] gap-y-[20px] lg:gap-y-0 justify-between items-center lg:items-start mb-0'>
          <motion.div>
            <img
              className={isMobile ? 'shadow-3xlwarmContMob rounded-3xl h-auto max-w-full' : 'shadow-3xlwarmCont rounded-3xl h-auto max-w-full'}
              src={cloudinaryImages.landing.images.moreThanGiftNameplate1}
              alt='nameplate1'
            />
          </motion.div>
          <motion.div>
            <img
              className={isMobile ? 'shadow-3xlwarmContMob rounded-3xl h-auto max-w-full' : 'shadow-3xlwarmCont rounded-3xl h-auto max-w-full'}
              src={cloudinaryImages.landing.images.moreThanGiftNameplate2}
              alt='nameplate2'
            />
          </motion.div>
        </motion.div>
        <motion.div
          custom={1}
          variants={animationImageContainer}
          className='flex justify-center lg:hidden pt-[24px]'>
          <button
            onClick={handleOpenConstructor}
            style={{ height: '72px', width: '300px', borderRadius: 36 }}
            className='text-[21px] w-full font-averta text-white px-4 md:px-16 py-6 font-bold bg-terracotta border-0 whitespace-nowrap lg:break-words  shadow-3xlwarmContMob'
          >
            Design your own
          </button>
        </motion.div>
      </Container>
    </motion.section>
  );
};
