import React from 'react';

import { useNamePlateSnapshots } from '../../../../hooks';

import { Button } from '../../../UI/buttons/Button';

import { ReactComponent as UndoButton } from '../../../../assets/images/icons/common/undo.svg';
import { ReactComponent as RedoButton } from '../../../../assets/images/icons/common/redo.svg';
import { useMediaQuery } from '@mui/material';

interface Props {
  whiteButtons?: boolean
}

export const UndoRedoButtons: React.FC<Props> = ({ whiteButtons }) => {
  const { undo, redo, undoDisabled, redoDisabled } = useNamePlateSnapshots();
  const isMobile = useMediaQuery('(max-width: 1023px)');

  return (
    <div className='flex justify-center items-center pl-4'>
      <Button
        isDisabled={undoDisabled}
        onClick={undo}
        isTransparent={!isMobile}
        fullContent={true}
        style={
          isMobile ? 
          {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            paddingLeft: '10px',
            paddingTop: '5px',
            borderTopLeftRadius: 24,
            borderBottomLeftRadius: 24,
            ...(whiteButtons && {
              background: 'rgba(255,255,255,0.6)',
              border: 'none',
              backdropFilter: 'blur(44px)',
            })
          }
          :
          {
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          paddingLeft: '10px',
          paddingTop: '5px',
          borderTopLeftRadius: 24,
          borderBottomLeftRadius: 24,
          ...(whiteButtons && {
            background: 'rgba(255,255,255,0.6)',
          })
        }}
      >
        <UndoButton/>
      </Button>

      <Button
        isDisabled={redoDisabled}
        onClick={redo}
        isTransparent={!isMobile}
        fullContent={true}
        style={
          isMobile ? 
          {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderTopRightRadius: 24,
            borderBottomRightRadius: 24,
            paddingRight: '10px',
            paddingTop: '5px',
            ...(whiteButtons && {         
              background: 'rgba(255,255,255,0.6)',
              border: 'none',
              backdropFilter: 'blur(44px)',
              position: 'relative',
              // left: '-3px',
            })
          }
          :
          {
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          borderTopRightRadius: 24,
          borderBottomRightRadius: 24,
          paddingRight: '10px',
          paddingTop: '5px',
          ...(whiteButtons && {         
            background: 'rgba(255,255,255,0.1)',
            position: 'relative',
            left: '-3px',
          })
        }}
      >
        <RedoButton/>
      </Button>
    </div>
  );
};
