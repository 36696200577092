import axios from 'axios';

import { getBaseUrl } from '../config';

const api = axios.create({
  baseURL: `${getBaseUrl()}/api/`,
  timeout: 30000, // 30 sec
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export {
  api
}
