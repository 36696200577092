import React from 'react';
import classes from './Button.module.css';

import classNames from 'classnames';
import { useMediaQuery } from '@mui/material';

type Props = {
  children: React.ReactNode;
  style?: object;
  className?: string;
  isDisabled?: boolean;
  isTransparent?: boolean;
  fullContent?: boolean;
  isActive?: boolean;
  onClick?: (val: any) => void;
};

export const Button: React.FC<Props> = ({
  children,
  style,
  className,
  isDisabled,
  isTransparent,
  fullContent,
  isActive,
  onClick,
}) => {
  const isMobile = useMediaQuery('(max-width: 1023px)');

  const handleOnClick = React.useCallback((val: any) => {
    if (onClick) {
      onClick(val);
    }
  }, []);

  return (
    <button
      style={style}
      className={classNames(
        className,
        classes.Btn,
        isMobile ? classes.BtnMobile : '',
        isDisabled && classes.BtnDisabled,
        isTransparent && classes.BtnTransparent,
        fullContent && classes.BtnFullContent,
        isActive && classes.BtnActive
      )}
      onClick={handleOnClick}
      disabled={isDisabled}
    >
      {children}
    </button>
  );
};
