import { api } from '@/utils/axios';
import { OrderPayload, OrderDto } from '@api/cms/orders/dto';

export const createOrder = async (data: OrderPayload) => {
  const res = await api.post<OrderDto>('/v2/orders', data);
  return res.data;
};

export const getOrder = async (orderId: number | string) => {
  const res = await api.get<OrderDto>(`/v2/orders/${orderId}`);
  return res.data;
};

export const putOrder = async (orderId: number | string, data: OrderPayload) => {
  const res = await api.put<OrderDto>(`/v2/orders/${orderId}`, data);
  return res.data;
};
