import * as PIXI from 'pixi.js';
import * as Hammer from 'hammerjs';
import { MobileTransformView } from './MobileTransformView';
import { GestureHandler, IController } from './types';

type LayoutProps = any;

export class MobileController implements IController {
  private readonly app: PIXI.Application;
  private props!: LayoutProps;
  private readonly view: MobileTransformView;

  private pointerDownIds: number[] = [];
  private panAllowed = true;

  private activeHandler: GestureHandler | null = null;

  private readonly panningCallback: () => void;
  private readonly pointerUpCallback: () => void;
  private readonly deactivateCallback: () => void;

  private rotationDisabled = false;
  private panDisabled = false;

  constructor(params: {
    app: PIXI.Application;
    panningCallback: () => void;
    pointerUpCallback: () => void;
    deactivateCallback: () => void;
    hammer: InstanceType<typeof Hammer.Manager>;
    parent: PIXI.Container;
  }) {
    this.app = params.app;
    this.panningCallback = params.panningCallback;
    this.pointerUpCallback = params.pointerUpCallback;
    this.deactivateCallback = params.deactivateCallback;

    this.view = new MobileTransformView();
    params.parent.addChild(this.view.root);

    this.app.renderer.plugins.interaction.on('pointerdown', this.handlePointerDown);
    this.app.renderer.plugins.interaction.on('pointerup', this.handlePointerUp);
    this.app.renderer.plugins.interaction.on('pointerupoutside', this.handlePointerUp);
    params.hammer.on('pan pinch rotate', this.handleHammerEvents);
  }

  private readonly handlePointerDown = (e: PIXI.InteractionEvent) => {
    this.pointerDownIds.push(e.data.identifier);
  };

  private readonly handlePointerUp = (e: PIXI.InteractionEvent) => {
    const handler = this.activeHandler;
    this.pointerDownIds = this.pointerDownIds.filter((id) => id !== e.data.identifier);

    if (this.pointerDownIds.length !== 0 || !this.props) return;

    this.panAllowed = true;

    if (!handler) return;

    handler.startPosition = null;
    handler.scaleMultiplier = null;
    handler.rotationOffset = null;
    handler.onEnd(this.props);

    this.deactivate();
    this.pointerUpCallback();
  };

  private readonly handleHammerEvents = (e: HammerInput) => {
    const handler = this.activeHandler;

    if (!handler || !this.props) return;

    if (e.type === 'rotate' && !this.rotationDisabled) {
      this.panAllowed = false;

      handler.rotationOffset =
        handler.rotationOffset ?? handler.target.rotation - e.rotation * PIXI.DEG_TO_RAD;
      handler.setRotation(this.props, handler.rotationOffset + e.rotation * PIXI.DEG_TO_RAD);
    }

    if (e.type === 'pinch') {
      this.panAllowed = false;

      handler.scaleMultiplier = handler.scaleMultiplier ?? handler.getSize(this.props);
      handler.setSize(this.props, handler.scaleMultiplier * e.scale);
    }

    if (e.type === 'pan' && this.panAllowed && !this.panDisabled) {
      const point = new PIXI.Point(e.deltaX, e.deltaY);
      handler.target.parent.toLocal(point, void 0, point);

      handler.startPosition = handler.startPosition ?? {
        x: handler.target.x - point.x,
        y: handler.target.y - point.y,
      };

      handler.setPosition(
        this.props,
        handler.startPosition.x + point.x,
        handler.startPosition.y + point.y,
      );

      this.panningCallback();
    }

    this.refreshBoundsView();
  };

  private refreshBoundsView() {
    this.view.root.visible = !!this.activeHandler;

    if (!this.activeHandler) return;

    this.view.render(this.activeHandler.target);
  }

  add(handler: GestureHandler) {
    handler.target.interactive = true;

    handler.target.on('pointerdown', () => {
      this.activate(handler);
    });
  }

  isActive(): boolean {
    return Boolean(this.activeHandler) && this.pointerDownIds.length !== 0;
  }

  isEditing(target: PIXI.Container): boolean {
    return this.activeHandler?.target === target;
  }

  activate(handler: GestureHandler): void {
    this.activeHandler = handler;
    this.refreshBoundsView();

    if (this.props) {
      handler.activate(this.props);
    }
  }

  deactivate() {
    this.activeHandler = null;
    this.refreshBoundsView();
    this.deactivateCallback();
  }

  render(props: LayoutProps) {
    this.props = props;
    this.refreshBoundsView();
  }

  destroy(): void {
    //
  }

  disableRotation(): void {
    this.rotationDisabled = true;
  }

  disablePan(): void {
    this.panDisabled = true;
  }
}
