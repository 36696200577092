import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { MaterialDesignContent, SnackbarProvider } from 'notistack';
import { styled } from '@mui/material';
import { useMediaQuery } from '@mui/material';

import {
  CommonStateContextProvider,
  NameplateMenuContextProvider,
  NameplateSettingsContextProvider,
  ServerOrderStateContextProvider,
} from '@/hooks';

import { Message } from '@/components/UI/Message';
import { LandingPage } from './landing';
import { AnimatePresence } from 'framer-motion';
import { PriceStateContextProvider } from '@hooks/use-price';
import { ShippingDataContext } from '@hooks/use-shipping';
import { Preloader } from '@components/common/Preloader';

import {
  APP_LINK_CHECKOUT_PAGE,
  APP_LINK_MAIN_PAGE,
  APP_LINK_NO_FOUND_PAGE,
  APP_LINK_THANK_YOU_PAGE,
} from '@/constants/common';

const ThankYou = React.lazy(() => import('./thank-you'));
const Checkout = React.lazy(() => import('./checkout'));

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-error': {
    backgroundColor: '#90B9C6',
    opacity: '90%',
    fontFamily: 'Averta',
    fontSize: '18px',
    fontWeight: '600',
    borderRadius: '10px',
    minWidth: '30vw',
    display: 'flex',
    justifyContent: 'center',
  },
  '&.notistack-MuiContent-default': {
    backgroundColor: '#FFFFFF',
    opacity: '80%',
    fontFamily: 'Averta',
    fontSize: '18px',
    color: '#3E4966',
    fontWeight: '600',
    borderRadius: '10px',
    minWidth: '30vw',
    display: 'flex',
    justifyContent: 'center',
  },
}));

const withContext = (Component: React.FC) => {
  return function mixin() {
    return (
      <SnackbarProvider
        autoHideDuration={2000}
        maxSnack={2}
        Components={{
          default: StyledMaterialDesignContent,
          error: StyledMaterialDesignContent,
        }}
        hideIconVariant
      >
        <CommonStateContextProvider>
          <ServerOrderStateContextProvider>
            <NameplateSettingsContextProvider>
              <ShippingDataContext>
                <PriceStateContextProvider>
                  <NameplateMenuContextProvider>
                    <>
                      <Component />
                      <Message />
                    </>
                  </NameplateMenuContextProvider>
                </PriceStateContextProvider>
              </ShippingDataContext>
            </NameplateSettingsContextProvider>
          </ServerOrderStateContextProvider>
        </CommonStateContextProvider>
      </SnackbarProvider>
    );
  };
};

const AppRouter: React.FC = () => {
  const isMobile = useMediaQuery('(max-width: 1023px)');
  const location = useLocation();

  return (
    <div style={isMobile ? {
      position: 'relative',
      overflow: 'hidden',
      height: '100%'
    } : {
      minHeight: '100vh',
    }}>
      <React.Suspense fallback={<Preloader open={true} />}>
        <AnimatePresence mode={'wait'}>
          <Routes location={location} key={location.pathname}>
            {isMobile && (
              <>
                <Route path={APP_LINK_MAIN_PAGE} element={<LandingPage />} />
                <Route path={APP_LINK_CHECKOUT_PAGE} element={<Checkout />} />
                <Route path={APP_LINK_THANK_YOU_PAGE} element={<ThankYou />} />
                <Route
                  path={APP_LINK_NO_FOUND_PAGE}
                  element={<Navigate to={APP_LINK_MAIN_PAGE} replace />}
                />
              </>
            )}
            {!isMobile && (
              <>
                <Route path={APP_LINK_MAIN_PAGE} element={<LandingPage />} />
                <Route path={APP_LINK_CHECKOUT_PAGE} element={<Checkout />} />
                <Route path={APP_LINK_THANK_YOU_PAGE} element={<ThankYou />} />
                <Route
                  path={APP_LINK_NO_FOUND_PAGE}
                  element={<Navigate to={APP_LINK_MAIN_PAGE} replace />}
                />
              </>
            )}
          </Routes>
        </AnimatePresence>
      </React.Suspense>
    </div>
  );
};

export default withContext(AppRouter);
