export const textHasLineBreak = (text: string): boolean => {
  const match = /\r|\n/.exec(text);
  return match !== null;
};

export const textTruncate = (str: string, n: number): string => {
  return str.length > n ? str.substr(0, n - 1) + '…' : str;
};

export const capitalizeFirstLetter = (str: string) => {
  return str[0].toUpperCase() + str.slice(1);
};

export const removeUnderscores = (str: string) => {
  return str.split('_').join(' ');
};

export const getSizeShortName = (str: string) => {
  const name = str.toUpperCase();
  const isExtra = name[0] === 'X';

  if (isExtra) {
    return name[0] + name[1];
  }
  return name[0];
};
