import React from 'react';
import cls from 'classnames';
import classes from './ChainEditor.module.css';

import { ReactComponent as ChainIcon } from '../../../../../assets/images/icons/common/chain.svg';
import chains from '../chains';
import { useNameplateSettings } from '../../../../../hooks';

interface Props {
  withLabels?: boolean;
}

export const ChainEditor: React.FC<Props> = ({ withLabels}) => {
  const {
    chainType,
    chainSize,
    onChangeChainType,
    onChangeChainSize
  } = useNameplateSettings();

  const handleSetChainType = (type: string) => onChangeChainType(type);
  const handleSetChainSize = (size: number) => onChangeChainSize(size);

  const getTitle = (size: number) => {
    if (size === 18) {
      return 'Large';
    } else {
      return 'Medium';
    }
  }

  return (
    <>
      <div className='w-full'>
        <div className={cls(classes.title, 'z-20 flex justify-center', withLabels && 'mb-1')}>
          <span className={cls(withLabels && 'text-[21px]')}>Chain {withLabels && ' Length'}</span>
        </div>

        <div className='flex w-full justify-between items-center px-5 pt-2'>
          <div className={cls('flex justify-evenly w-full items-center', withLabels && 'relative -right-[25px] mt-2')}>
            {chains.sizes.map((sz, idx) => (
              <div className='flex flex-col justify-center items-center mr-[10px]' key={idx}>
                <div
                  className={cls(classes.styleItem, chainSize === sz ? classes.styleItemActive : '', withLabels ? classes.styleItemMobile : '')}
                  onClick={() => handleSetChainSize(sz)}
                >
                  {sz + '’’'}
                </div>
                {withLabels && (
                  <span className={cls(classes.styleItemLabel, chainSize === sz ? classes.styleItemLabelActive : '')}>{getTitle(sz)}</span>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}