import React from 'react';

import classNames from 'classnames';
import classes from './Range.module.css';
import rangeGreyImage from '../../../assets/images/UI/range-grey.png';

type Props = {
  value: number;
  min: number;
  max: number;
  step: number;
  onChange: (e: any) => any;
  onMouseUp?: (e: any) => any;
  onTouchEnd?: (e: any) => any;
  className?: string;
  style?: object;
  isRed?: boolean;
  isWhite?: boolean;
  withoutStrip?: boolean;
};

export const Range: React.FC<Props> = (props) => {
  return (
    <div className={classes.Line} style={{ backgroundImage: `url(${rangeGreyImage})` }}>
      <input
        type='range'
        value={props.value}
        min={props.min}
        max={props.max}
        step={props.step}
        onChange={props.onChange}
        onMouseUp={props.onMouseUp}
        onTouchEnd={props.onTouchEnd}
        className={classNames(
          props.className,
          classes.Range,
          props.withoutStrip ? classes.withoutStrip : '',
        )}
        style={{
          ...props.style,
        }}
      />
    </div>
  );
};
