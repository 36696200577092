import * as PIXI from 'pixi.js';

import { NameplateCanvasAppProps } from './NameplateCanvasApp';
import { PendantMaterialType, PendantMessageShape } from '..';
import { PendantShapeType, PendantSizeType } from '../../hooks';
import { NameplateMaterialType } from '../../hooks';
import { backgroundConfigByName, ChainSide, getChainAsset, getChainRingAsset } from './backgrounds';
import { getPendantAsset } from '../../hooks/pendant/use-pendant-settings/pendants';

export type NameplateCanvasAppTextureByName = {
  materialTexture: PIXI.Texture;
  chainRingTexture: PIXI.Texture;
} & Record<ChainSide, PIXI.Texture>;

const pendantMaterialByNameplateMaterial: Record<NameplateMaterialType, PendantMaterialType> = {
  [NameplateMaterialType.GOLD]: PendantMaterialType.GOLD,
  [NameplateMaterialType.SILVER]: PendantMaterialType.SILVER,
  [NameplateMaterialType.ROSE_GOLD]: PendantMaterialType.ROSE_GOLD,
};

export class NameplateCanvasAppAssetsLoader {
  async loadTextures(props: NameplateCanvasAppProps): Promise<NameplateCanvasAppTextureByName> {
    const { label, backgroundName } = props;
    const bgConfig = backgroundConfigByName[backgroundName];

    const [materialTexture, chainLeftTexture, chainRightTexture, chainRingTexture] = await Promise.all(
      [
        getPendantAsset(
          PendantShapeType.RECTANGLE,
          pendantMaterialByNameplateMaterial[label.materialType],
          PendantMessageShape.Normal,
          PendantSizeType.MEDIUM,
        ),
        getChainAsset(bgConfig, ChainSide.ChainLeft, label.materialType),
        getChainAsset(bgConfig, ChainSide.ChainRight, label.materialType),
        getChainRingAsset(bgConfig, label.materialType),
      ].map((asset) => PIXI.Texture.fromURL(asset)),
    );

    await document.fonts.ready;

    return {
      materialTexture,
      [ChainSide.ChainLeft]: chainLeftTexture,
      [ChainSide.ChainRight]: chainRightTexture,
      chainRingTexture
    };
  }
}
