import React, { useEffect } from 'react';
import Grow from '@mui/material/Grow';
import Button from '@mui/material/Button';
import { styled } from '@mui/material';
import cls from 'classnames';

import { usd } from '@/utils/format';
import { usePrice } from '@/hooks/use-price';
import { useCommonState, useNameplateSettings } from '@/hooks';

import { NameplateSizeLimitState } from '@/hooks/nameplate/use-nameplate-settings/types';
import ReactGA from 'react-ga4'
import { useMediaQuery } from '@mui/material';

interface Props {
  isStatic?: boolean;
  onClick?: () => void;
}

const StyledButton = styled(Button)`
  ${({ theme }) => `
    transition: ${theme.transitions.create(['background-color', 'transform'], {
      duration: theme.transitions.duration.standard,
    })};
    &:hover {
      transform: scale(1.1) !important;
    }
  `}
`;

export const OrderButton: React.FC<Props> = ({ isStatic, onClick = () => {} }) => {
  const isMobile = useMediaQuery('(max-width: 1023px)');
  const { priceData, isLoading } = usePrice();
  const { onChangeOrderAvailable } = useCommonState();
  const { text, sizeLimitState, exportImages } = useNameplateSettings();

  const [isBtnDisabled, setIsBtnDisabled] = React.useState<boolean>(!(text.length > 0));

  React.useEffect(() => {
    onChangeOrderAvailable(text.length > 0);
    setIsBtnDisabled(!(text.length > 0));
  }, [text]);

  useEffect(() => {
    ReactGA.gtag("event","item_price_change", {
      currency: "USD",
      value: priceData?.singleProductPrice
    })

  }, [priceData])

  const handleOnClick = async () => {
    ReactGA.gtag("event","add_to_cart", {
      currency: "USD",
      value: priceData?.singleProductPrice
    })
    await exportImages();
    onClick();
  };

  const isButtonVisible = priceData?.singleProductPrice && priceData.singleProductPrice !== 3457.93;

  if (!isButtonVisible) return null;

  const BtnComponent = isMobile ? Button : StyledButton;

  const sizeLimitError = sizeLimitState === NameplateSizeLimitState.Exceeded;

  return (
    <Grow in timeout={500}>
      <BtnComponent
        className={cls(
          'w-[340px] py-4 text-white text-normal mb-4 font-semibold text-[21px] cursor-pointer whitespace-nowrap bg-stylish-black normal-case',
          {
            'mt-0 mb-4': isStatic && isMobile,
            'absolute bottom-20 right-20': !isStatic || !isMobile,
            'bg-stylish-black/25': isBtnDisabled || sizeLimitError,
          },
        )}
        disabled={isBtnDisabled || sizeLimitError || isLoading}
        onClick={handleOnClick}
        style={isStatic ? { width: '100%', height: '64px', borderRadius: 36 } : {borderRadius: 36}}
      >
        {sizeLimitError
          ? 'Get it!'
          : `Get it! ${usd.format(priceData.singleProductPrice)}`
        }
      </BtnComponent>
    </Grow>
  );
};
