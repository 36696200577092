import { TextMetrics, Text, utils } from 'pixi.js';

const { hex2rgb, string2hex } = utils;

TextMetrics.measureText = function (text, style, wordWrap, canvas) {
  if (canvas === void 0) {
    canvas = TextMetrics._canvas;
  }

  wordWrap = wordWrap === undefined || wordWrap === null ? style.wordWrap : wordWrap;

  const font = style.toFontString();
  const fontProperties = TextMetrics.measureFont(font);

  // fallback in case UA disallow canvas data extraction
  // (toDataURI, getImageData functions)
  if (fontProperties.fontSize === 0) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    fontProperties.fontSize = style.fontSize;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    fontProperties.ascent = style.fontSize;
  }

  const context = canvas.getContext('2d')!;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  context.font = font;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const outputText = wordWrap ? TextMetrics.wordWrap(text, style, canvas) : text;
  const lines = outputText.split(/(?:\r\n|\r|\n)/);
  const lineWidths = new Array(lines.length);
  let maxLineWidth = 0;

  for (let i = 0; i < lines.length; i++) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const metrics = context.measureText(lines[i]);
    const width = metrics.actualBoundingBoxRight + metrics.actualBoundingBoxLeft;
    const lineWidth = width + (lines[i].length - 1) * style.letterSpacing;
    lineWidths[i] = lineWidth;
    maxLineWidth = Math.max(maxLineWidth, lineWidth);
  }

  let width = maxLineWidth + style.strokeThickness;

  if (style.dropShadow) {
    width += style.dropShadowDistance;
  }

  const lineHeight = style.lineHeight || fontProperties.fontSize + style.strokeThickness;

  let height =
    Math.max(lineHeight, fontProperties.fontSize + style.strokeThickness) +
    (lines.length - 1) * (lineHeight + style.leading);

  if (style.dropShadow) {
    height += style.dropShadowDistance;
  }

  return new TextMetrics(
    text,
    style,
    width,
    height,
    lines,
    lineWidths,
    lineHeight + style.leading,
    maxLineWidth,
    fontProperties,
  );
};

Text.prototype.updateText = function (respectDirty) {
  const self = this as any;
  const style = self._style;

  // check if style has changed..
  if (self.localStyleID !== style.styleID) {
    self.dirty = true;
    self.localStyleID = style.styleID;
  }

  if (!self.dirty && respectDirty) {
    return;
  }

  self._font = self._style.toFontString();

  const context = self.context;

  const measured = TextMetrics.measureText(
    self._text || ' ',
    self._style,
    self._style.wordWrap,
    self.canvas,
  );

  const width = measured.width;
  const height = measured.height;
  const lines = measured.lines;
  const lineHeight = measured.lineHeight;
  const lineWidths = measured.lineWidths;
  const maxLineWidth = measured.maxLineWidth;
  const fontProperties = measured.fontProperties;

  self.canvas.width = Math.ceil(
    Math.ceil(Math.max(1, width) + style.padding * 2) * self._resolution,
  );
  self.canvas.height = Math.ceil(
    Math.ceil(Math.max(1, height) + style.padding * 2) * self._resolution,
  );

  context.scale(self._resolution, self._resolution);
  context.clearRect(0, 0, self.canvas.width, self.canvas.height);
  context.font = self._font;
  context.lineWidth = style.strokeThickness;
  context.textBaseline = style.textBaseline;
  context.lineJoin = style.lineJoin;
  context.miterLimit = style.miterLimit;

  let linePositionX;
  let linePositionY;
  // require 2 passes if a shadow; the first to draw the drop shadow, the second to draw the text
  const passesCount = style.dropShadow ? 2 : 1;
  // For v4, we drew text at the colours of the drop shadow underneath the normal text. This gave the correct zIndex,
  // but features such as alpha and shadowblur did not look right at all, since we were using actual text as a shadow.
  //
  // For v5.0.0, we moved over to just use the canvas API for drop shadows, which made them look much nicer and more
  // visually please, but now because the stroke is drawn and then the fill, drop shadows would appear on both the fill
  // and the stroke; and fill drop shadows would appear over the top of the stroke.
  //
  // For v5.1.1, the new route is to revert to v4 style of drawing text first to get the drop shadows underneath normal
  // text, but instead drawing text in the correct location, we'll draw it off screen (-paddingY), and then adjust the
  // drop shadow so only that appears on screen (+paddingY). Now we'll have the correct draw order of the shadow
  // beneath the text, whilst also having the proper text shadow styling.
  for (let i = 0; i < passesCount; ++i) {
    const isShadowPass = style.dropShadow && i === 0;
    // we only want the drop shadow, so put text way off-screen
    const dsOffsetText = isShadowPass ? Math.ceil(Math.max(1, height) + style.padding * 2) : 0;
    const dsOffsetShadow = dsOffsetText * self._resolution;

    if (isShadowPass) {
      // On Safari, text with gradient and drop shadows together do not position correctly
      // if the scale of the canvas is not 1: https://bugs.webkit.org/show_bug.cgi?id=197689
      // Therefore we'll set the styles to be a plain black whilst generating this drop shadow
      context.fillStyle = 'black';
      context.strokeStyle = 'black';

      const dropShadowColor = style.dropShadowColor;
      const rgb = hex2rgb(
        typeof dropShadowColor === 'number' ? dropShadowColor : string2hex(dropShadowColor),
      );
      const dropShadowBlur = style.dropShadowBlur * self._resolution;
      const dropShadowDistance = style.dropShadowDistance * self._resolution;

      context.shadowColor =
        'rgba(' +
        rgb[0] * 255 +
        ',' +
        rgb[1] * 255 +
        ',' +
        rgb[2] * 255 +
        ',' +
        style.dropShadowAlpha +
        ')';
      context.shadowBlur = dropShadowBlur;
      context.shadowOffsetX = Math.cos(style.dropShadowAngle) * dropShadowDistance;
      context.shadowOffsetY = Math.sin(style.dropShadowAngle) * dropShadowDistance + dsOffsetShadow;
    } else {
      context.fillStyle = self._generateFillStyle(style, lines, measured);

      // TODO: Can't have different types for getter and setter. The getter shouldn't have the number type as
      //       the setter converts to string. See this thread for more details:
      //       https://github.com/microsoft/TypeScript/issues/2521

      context.strokeStyle = style.stroke;
      context.shadowColor = 'black';
      context.shadowBlur = 0;
      context.shadowOffsetX = 0;
      context.shadowOffsetY = 0;
    }

    let linePositionYShift = (lineHeight - fontProperties.fontSize) / 2;

    if (!Text.nextLineHeightBehavior || lineHeight - fontProperties.fontSize < 0) {
      linePositionYShift = 0;
    }

    // draw lines line by line
    for (let i_1 = 0; i_1 < lines.length; i_1++) {
      const m = context.measureText(lines[i_1]);
      linePositionX = style.strokeThickness / 2 + m.actualBoundingBoxLeft;
      linePositionY =
        style.strokeThickness / 2 + i_1 * lineHeight + fontProperties.ascent + linePositionYShift;

      if (style.align === 'right') {
        linePositionX += maxLineWidth - lineWidths[i_1];
      } else if (style.align === 'center') {
        linePositionX += (maxLineWidth - lineWidths[i_1]) / 2;
      }

      if (style.stroke && style.strokeThickness) {
        self.drawLetterSpacing(
          lines[i_1],
          linePositionX + style.padding,
          linePositionY + style.padding - dsOffsetText,
          true,
        );
      }

      if (style.fill) {
        self.drawLetterSpacing(
          lines[i_1],
          linePositionX + style.padding,
          linePositionY + style.padding - dsOffsetText,
        );
      }
    }
  }

  self.updateTexture();
};
