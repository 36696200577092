import React from 'react';
import { AxiosError } from 'axios';
import { useLocation } from 'react-router-dom';

import { getMaterialNumber, getWeightInGrams } from '@/utils/nameplate';
import { getPriceMessage } from '@hooks/nameplate/use-nameplate-settings/messages';
import { preciseRound } from '@/utils/math';
import { getPrice } from '@/api';

import { useCommonState } from '@hooks/use-common-state';
import { useShippingData } from '@hooks/use-shipping';
import { useNameplateSettings } from '@hooks/nameplate/use-nameplate-settings';


import { PriceStateContext } from './types';
import { PriceDto } from '@api/cms/shipping/dto';
import {
  NameplateSizeLimitState,
  NameplateMaterialType,
  DEFAULT_PRICE
} from '@/hooks';

import { APP_LINK_CHECKOUT_PAGE } from '@/constants/common';
import ReactGA from 'react-ga4'

export const PriceState = React.createContext({} as PriceStateContext);

let debounceTimer: any;
let tempDebounceTimer: any;

export const PriceStateContextProvider: React.FC<{ children: React.ReactElement }> = ({
  children,
}) => {
  const [isFetching, setFetching] = React.useState<boolean>(true);
  const [state, setState] = React.useState<null | PriceDto>(null);
  const [error, setError] = React.useState<null | AxiosError>(null);

  const { pathname } = useLocation();

  const { callMessage } = useCommonState();
  const { shippingData, onShippingData } = useShippingData();
  const {
    baseArea,
    baseWidth,
    baseHeight,
    texturesLoading,
    constructor,
    images,
    fontSize,
    sizeLimitState,
    price,
    chainSize,
    onSetChainPrice,
    onSetMaterialPrice,
    onSetPrice,
    onChangeBasePrice,
    text,
    area,
    selectedMaterial
  } = useNameplateSettings();

  const getPendantPrice = async ({
    pendantText,
    pendantArea,
    pendantMaterial,
    pendantChainSize,
    showMessage
  }: {
    pendantText: string;
    pendantArea: number;
    pendantMaterial: NameplateMaterialType;
    pendantChainSize: number;
    showMessage: boolean;
  }) => {

    console.log(`Get pendant price for ${pendantText} & ${pendantArea}`)
    if (pendantText === '') {
      console.log("SET BASE PRICE TO ", 0)
      onSetPrice(0);
      onChangeBasePrice(0);
      return false;
    }

    const weight = preciseRound(getWeightInGrams(pendantArea, pendantMaterial));
    console.log("weight = ", weight)
    const material = getMaterialNumber(pendantMaterial);

    if (weight <= 0.1) {
      onSetPrice(0);
      return false
    }

    try {
      setFetching(true);

      const res = await getPrice({
        weight,
        material,
        quantity: shippingData.quantity,
        chainLength: pendantChainSize,
        ...(shippingData.selectedDelivery && {
          rateId: shippingData.selectedDelivery.object_id,
        }),
      });

      if (res) {
        if (showMessage) {
          setState(res);

          onShippingData({
            ratePrice: res.ratePrice,
            total: res.total,
          });

          onSetPrice(res.singleProductPrice);
          console.log("SET BASE PRICE TO ", res.singleProductPrice)
          onChangeBasePrice(res.singleProductPrice);

          if (price.data !== DEFAULT_PRICE && sizeLimitState === NameplateSizeLimitState.Allowed) {
            if (pathname === APP_LINK_CHECKOUT_PAGE) {
              callMessage(getPriceMessage(res.total));
            } else {
              if (shippingData.total != res.total) {
                callMessage(getPriceMessage(res.singleProductPrice));
              }
            }
          }

        }
        return res.singleProductPrice
      }
    } catch (err) {
      const errTyped = err as AxiosError;
      setError(errTyped);
    } finally {
      setFetching(false);
    }
  };

  const getTempPendantPrice = async ({
    pendantText,
    pendantArea,
    pendantMaterial,
    pendantChainSize,
  }: {
    pendantText: string;
    pendantArea: number;
    pendantMaterial: NameplateMaterialType;
    pendantChainSize: number;
  }) => {

    console.log(`Get pendant price for ${pendantText} & ${pendantArea}`)
    if (pendantText === '') {
      console.log("SET TEMP BASE PRICE TO ", 0)
      onSetPrice(0);
      // onChangeBasePrice(0);
      return false;
    }


    const weight = preciseRound(getWeightInGrams(pendantArea, pendantMaterial));
    const material = getMaterialNumber(pendantMaterial);

    if (weight <= 0.1) {
      onSetPrice(0);
      return false
    }

    try {
      setFetching(true);

      const res = await getPrice({
        weight,
        material,
        quantity: shippingData.quantity,
        chainLength: pendantChainSize,
        ...(shippingData.selectedDelivery && {
          rateId: shippingData.selectedDelivery.object_id,
        }),
      });

      if (res) {
        setState(res);

        onShippingData({
          ratePrice: res.ratePrice,
          total: res.total,
        });

        onSetPrice(res.singleProductPrice);
        console.log("SET TEMP BASE PRICE TO ", res.singleProductPrice)
        // onChangeBasePrice(res.singleProductPrice);

        if (price.data !== DEFAULT_PRICE && sizeLimitState === NameplateSizeLimitState.Allowed) {
          if (pathname === APP_LINK_CHECKOUT_PAGE) {
            // callMessage(getPriceMessage(res.total));
          } else {
            if (shippingData.total != res.total) {
              // callMessage(getPriceMessage(res.singleProductPrice));
            }
          }
        }
      }
    } catch (err) {
      const errTyped = err as AxiosError;
      setError(errTyped);
    } finally {
      setFetching(false);
    }
  };

  function calculatePricePerGramAndChainPrice(
    productPrice1: number,
    weight1: number,
    productPrice2: number,
    weight2: number
  ): { pricePerGram: number, chainPrice: number } {
    if (weight1 === weight2) {
      throw new Error("Weights must be different to solve for pricePerGram and chainPrice.");
    }

    // Calculate price per gram
    const pricePerGram = (productPrice2 - productPrice1) / (weight2 - weight1);

    // Calculate chain price
    const chainPrice = productPrice1 - (weight1 * pricePerGram);

    return { pricePerGram, chainPrice };
  }

  function calculateOriginalProductPrice(newProductPrice: number): number {
    return newProductPrice
  }


  React.useEffect(() => {
    const debouncedGetPrice = () => {
      clearTimeout(debounceTimer);

      debounceTimer = setTimeout(async () => {
        const price1 = await getPendantPrice({
          pendantArea: area - 1,
          pendantText: text,
          pendantMaterial: selectedMaterial,
          pendantChainSize: chainSize,
          showMessage: false
        });

        const price2 = await getPendantPrice({
          pendantArea: area,
          pendantText: text,
          pendantMaterial: selectedMaterial,
          pendantChainSize: chainSize,
          showMessage: true
        });

        const weight1 = preciseRound(getWeightInGrams(area - 1, selectedMaterial));
        const weight2 = preciseRound(getWeightInGrams(area, selectedMaterial));
        if (price1 && price2) {
          const newPrice1 = calculateOriginalProductPrice(price1)
          const newPrice2 = calculateOriginalProductPrice(price2)
          const resultCalc = calculatePricePerGramAndChainPrice(newPrice1, weight1, newPrice2, weight2)
          onSetChainPrice(resultCalc.chainPrice)
          onSetMaterialPrice(resultCalc.pricePerGram)
        }

      }, 1000);
    };

    // if (!texturesLoading && !constructor?.isLoading && !images?.isLoading) {

    // }

    debouncedGetPrice();
  }, [
    // for init
    texturesLoading,
    constructor?.isLoading,
    images?.isLoading,
    baseArea,
    // check changes
    chainSize,
    sizeLimitState,
    selectedMaterial,
    shippingData?.selectedDelivery?.object_id,
    shippingData?.id,
  ]);

  React.useEffect(() => {
    const tempDebouncedGetPrice = () => {
      clearTimeout(tempDebounceTimer);

      tempDebounceTimer = setTimeout(() => {
        getTempPendantPrice({
          pendantArea: area,
          pendantText: text,
          pendantMaterial: selectedMaterial,
          pendantChainSize: chainSize,
        });
      }, 1000);
    };

    tempDebouncedGetPrice();
  }, [
    area
  ]);

  return (
    <PriceState.Provider
      value={{
        priceData: state,
        isLoading: isFetching,
        error: error,
      }}
    >
      {children}
    </PriceState.Provider>
  );
};

export const usePrice = () => {
  const context = React.useContext(PriceState);

  if (!context) {
    throw Error("usePrice has to uses inside 'PriceStateContextProvider' provider");
  }

  return context;
};
