import * as PIXI from 'pixi.js';
import { fontsData, connectionsData, defaultConnectionsPairs } from '../fonts-data';

export type DrawLetterSpacingOut = {
  firstCharBaseX: number;
  firstCharBaseY: number;
  lastCharBaseX: number;
  lastCharBaseY: number;
  leftCircleCenterX: number;
  leftCircleCenterY: number;
  leftCircleEdgeX: number;
  leftCircleEdgeY: number;
  rightCircleCenterX: number;
  rightCircleCenterY: number;
  rightCircleEdgeX: number;
  rightCircleEdgeY: number;
  minLetterHeight: number;
};

export const createDrawLetterManifest = (): DrawLetterSpacingOut => {
  return {
    firstCharBaseX: 0,
    firstCharBaseY: 0,
    lastCharBaseX: 0,
    lastCharBaseY: 0,
    leftCircleCenterX: 0,
    leftCircleCenterY: 0,
    leftCircleEdgeX: 0,
    leftCircleEdgeY: 0,
    rightCircleCenterX: 0,
    rightCircleCenterY: 0,
    rightCircleEdgeX: 0,
    rightCircleEdgeY: 0,
    minLetterHeight: 0,
  };
};

export function drawLetterSpacing(
  self: PIXI.Text,
  out: DrawLetterSpacingOut,
  text: string,
  x: number,
  y: number,
  isStroke = false,
) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const style = self._style;
  const letterSpacing = style.letterSpacing;
  const stringArray = Array.from ? Array.from(text) : text.split('');
  let currentPosition = x;
  let previousWidth = self.context.measureText(text).width;
  let currentWidth = 0;

  out.minLetterHeight = Number.MAX_VALUE;

  for (let i = 0; i < stringArray.length; ++i) {
    const currentChar = stringArray[i];
    const data = fontsData[style.fontFamily as string]?.[currentChar];
    const metrics = self.context.measureText(currentChar);

    out.minLetterHeight = Math.min(
      out.minLetterHeight,
      metrics.actualBoundingBoxDescent + metrics.actualBoundingBoxAscent,
    );

    if (i === 0) {
      out.firstCharBaseX = currentPosition;
      out.firstCharBaseY = y;

      if (data) {
        out.leftCircleCenterX = data.leftCircleCenter.x;
        out.leftCircleCenterY = data.leftCircleCenter.y;
        out.leftCircleEdgeX = data.leftCircleEdge.x;
        out.leftCircleEdgeY = data.leftCircleEdge.y;
      } else {
        out.leftCircleCenterX = 0;
        out.leftCircleCenterY = -metrics.actualBoundingBoxAscent;
        out.leftCircleEdgeX = out.leftCircleCenterX - 1;
        out.leftCircleEdgeY = out.leftCircleCenterY;
      }
    }

    if (i === stringArray.length - 1) {
      out.lastCharBaseX = currentPosition;
      out.lastCharBaseY = y;

      if (data) {
        out.rightCircleCenterX = data.rightCircleCenter.x;
        out.rightCircleCenterY = data.rightCircleCenter.y;
        out.rightCircleEdgeX = data.rightCircleEdge.x;
        out.rightCircleEdgeY = data.rightCircleEdge.y;
      } else {
        out.rightCircleCenterX = metrics.width;
        out.rightCircleCenterY = -metrics.actualBoundingBoxAscent;
        out.rightCircleEdgeX = out.rightCircleCenterX + 1;
        out.rightCircleEdgeY = out.rightCircleCenterY;
      }
    }

    let textStr = '';

    for (let j = i + 1; j < stringArray.length; ++j) {
      textStr += stringArray[j];
    }

    const currentX = currentPosition;
    currentWidth = self.context.measureText(textStr).width;
    currentPosition += previousWidth - currentWidth + letterSpacing;
    previousWidth = currentWidth;

    // todo Refactor

    if (['i','j'].includes(currentChar)) {
      const dataA = connectionsData[style.fontFamily as string]?.[currentChar];

      if (!dataA) return;

      dataA.innerConnections.forEach((circleA, i) => {
        const circleB = dataA.innerConnections[i + 1];

        if (!circleB) return;

        const itemA = { char: currentChar, x: currentX, y };

        drawConnection(
          self.context,
          getCircleInTextCoords(itemA, circleA),
          getCircleInTextCoords(itemA, circleB),
        );
      });
    }

    const pairExists = (pair: string) => defaultConnectionsPairs[style.fontFamily as string]?.includes(pair);

    let textToFill = currentChar;
    for (let j = i; j < stringArray.length; ++j) {
      const currChar = stringArray[j];
      const nextChar = stringArray[j + 1];

      if (!nextChar) break;

      if (pairExists(currChar + nextChar)) {
        textToFill += nextChar;
        continue;
      }
      break;
    }

    const prevChar = stringArray[i - 1];
    if (!(prevChar && pairExists(prevChar + currentChar))) {
      if (isStroke) {
        self.context.strokeText(textToFill, currentX, y);
      } else {
        self.context.fillText(textToFill, currentX, y);
      }
    }

    const nextChar = stringArray[i + 1];
    if (nextChar && !pairExists(currentChar + nextChar)) {
      drawConnections(
        self,
        style.fontFamily as string,
        {
          char: textToFill[textToFill.length - 1],
          x: currentX,
          y,
        },
        {
          char: nextChar,
          x: currentPosition,
          y,
        },
      );
    }
  }

  const halfCanvasWidth = self.canvas.width / 2;
  const halfCanvasHeight = self.canvas.height / 2;
  out.firstCharBaseX -= halfCanvasWidth;
  out.firstCharBaseY -= halfCanvasHeight;
  out.lastCharBaseX -= halfCanvasWidth;
  out.lastCharBaseY -= halfCanvasHeight;
}

type Circle = { x: number; y: number; radius: number };
type Pos = { x: number; y: number };

const getCircleInTextCoords = (letter: Pos, circle: Circle) => {
  return {
    x: letter.x + circle.x,
    y: letter.y + circle.y,
    radius: circle.radius,
  };
};

const drawConnections = (
  self: PIXI.Text,
  fontFamily: string,
  itemA: { char: string; x: number; y: number },
  itemB: { char?: string; x: number; y: number },
) => {
  const dataA = connectionsData[fontFamily]?.[itemA.char];

  if (!dataA) return;

  const fillStyle = self.context.fillStyle;
  self.context.fillStyle = '#fff';

  dataA.innerConnections.forEach((circleA, i) => {
    const circleB = dataA.innerConnections[i + 1];

    if (!circleB) return;

    drawConnection(
      self.context,
      getCircleInTextCoords(itemA, circleA),
      getCircleInTextCoords(itemA, circleB),
    );
  });

  const dataB = itemB.char ? connectionsData[fontFamily]?.[itemB.char] : null;

  if (dataB) {
    const closestConnection = findClosestConnection(
      dataA.rightConnections.map((circle) => getCircleInTextCoords(itemA, circle)),
      dataB.leftConnections.map((circle) => getCircleInTextCoords(itemB, circle)),
    );

    if (closestConnection) {
      drawConnection(self.context, closestConnection.circleA, closestConnection.circleB);
    }
  }

  self.context.fillStyle = fillStyle;
};

const drawConnection = (ctx: CanvasRenderingContext2D, circleA: Circle, circleB: Circle) => {
  let normX = circleB.x - circleA.x;
  let normY = circleB.y - circleA.y;

  const magnitude = Math.hypot(normY, normX);
  normX /= magnitude;
  normY /= magnitude;

  const perpX = +normY;
  const perpY = -normX;

  ctx.beginPath();
  ctx.moveTo(circleA.x + perpX * circleA.radius, circleA.y + perpY * circleA.radius);
  ctx.lineTo(circleA.x - perpX * circleA.radius, circleA.y - perpY * circleA.radius);
  ctx.lineTo(circleB.x - perpX * circleB.radius, circleB.y - perpY * circleB.radius);
  ctx.lineTo(circleB.x + perpX * circleB.radius, circleB.y + perpY * circleB.radius);
  ctx.closePath();
  ctx.fill();

  ctx.beginPath();
  ctx.arc(circleA.x, circleA.y, circleA.radius, 0, PIXI.PI_2);
  ctx.closePath();
  ctx.fill();

  ctx.beginPath();
  ctx.arc(circleB.x, circleB.y, circleB.radius, 0, PIXI.PI_2);
  ctx.closePath();
  ctx.fill();
};

const findClosestConnection = (circlesA: Circle[], circlesB: Circle[]) => {
  let result;

  for (let i = 0; i < circlesA.length; i++) {
    for (let j = 0; j < circlesB.length; j++) {
      const circleA = circlesA[i];
      const circleB = circlesB[j];

      const dx = circleA.x - circleB.x;
      const dy = circleA.y - circleB.y;
      const distanceSq = dx * dx + dy * dy;

      if (!result || distanceSq < result.distanceSq) {
        result = {
          distanceSq,
          circleA,
          circleB,
        };
      }
    }
  }

  return result;
};
