export const preciseRound = (n: number) => {
  return Math.round(n * 100) / 100;
};

export const mmToInch = (n: number) => {
  return n / 25.4;
};

export const inchToMM = (n: number) => {
  return n * 25.4;
};

export const addPercent = (n: number, percent: number) => {
  return preciseRound(n + (n / 100) * percent);
};

export const cast = (val: number | string) => {
  return parseFloat(parseFloat(`${val}`).toFixed(2));
};

export const roundToPrecision = (value: number, precision: number): number => {
  const factor = 10 ** precision;
  return Math.round(value / factor) * factor;
}
