import * as PIXI from 'pixi.js';
import { Cursor, CursorType } from './Cursor';

export type TransformViewPartPointerDownHandler = (e: PIXI.InteractionEvent) => void;

export class DesktopTransformView {
  root: PIXI.Container;
  private readonly lines: PIXI.Sprite[];
  private readonly corners: PIXI.Sprite[];
  private readonly rotationCorners: PIXI.Sprite[];
  private readonly parts: PIXI.Sprite[];
  private readonly cursor: Cursor;

  constructor(props: {
    handleCornerPointerDown: TransformViewPartPointerDownHandler;
    handleRotationCornerPointerDown: TransformViewPartPointerDownHandler;
    cursor: Cursor;
  }) {
    this.cursor = props.cursor;

    this.root = new PIXI.Container();

    this.lines = Array.from({ length: 4 }).map(() => {
      const line = PIXI.Sprite.from(PIXI.Texture.WHITE);
      line.height = 2;
      this.root.addChild(line);

      return line;
    });

    this.rotationCorners = Array.from({ length: 4 }).map(() => {
      const rotationCorner = PIXI.Sprite.from(PIXI.Texture.WHITE);
      rotationCorner.width = 40;
      rotationCorner.height = 40;
      rotationCorner.anchor.set(0.75);
      rotationCorner.alpha = 0;
      this.root.addChild(rotationCorner);

      rotationCorner.interactive = true;
      rotationCorner.on('pointerdown', props.handleRotationCornerPointerDown);

      this.cursor.subscribe({
        target: rotationCorner,
        getHoverType: () => CursorType.RotateHover,
        getClickType: () => CursorType.Rotate,
        getRotation: () => rotationCorner.rotation,
      });

      return rotationCorner;
    });

    this.corners = Array.from({ length: 4 }).map(() => {
      const corner = PIXI.Sprite.from(PIXI.Texture.WHITE);
      corner.width = 20;
      corner.height = 20;
      corner.anchor.set(0.5);
      this.root.addChild(corner);

      corner.interactive = true;
      corner.on('pointerdown', props.handleCornerPointerDown);

      this.cursor.subscribe({
        target: corner,
        getHoverType: () => CursorType.PinchHover,
        getClickType: () => CursorType.Pinch,
        getRotation: () => corner.rotation + Math.PI / 4,
      });

      return corner;
    });

    this.parts = [...this.lines, ...this.corners, ...this.rotationCorners];
  }

  isPartOfTransformView(target: any) {
    return this.parts.includes(target);
  }

  render(target: PIXI.Container) {
    const hw = target.width / 2 / target.scale.x;
    const hh = target.height / 2 / target.scale.y;

    [
      { x: -hw, y: -hh },
      { x: +hw, y: -hh },
      { x: +hw, y: +hh },
      { x: -hw, y: +hh },
    ]
      .map((point) => {
        return this.root.toLocal(point, target);
      })
      .forEach((point, i, arr) => {
        const next = arr[i + 1] ?? arr[0];
        const line = this.lines[i];
        const corner = this.corners[i];
        const rotationCorner = this.rotationCorners[i];
        const dx = next.x - point.x;
        const dy = next.y - point.y;

        line.x = point.x;
        line.y = point.y;
        line.width = Math.hypot(dy, dx);
        line.rotation = Math.atan2(dy, dx);

        corner.x = point.x;
        corner.y = point.y;
        corner.rotation = line.rotation;

        rotationCorner.x = point.x;
        rotationCorner.y = point.y;
        rotationCorner.rotation = line.rotation;
      });
  }

  disableRotation() {
    this.rotationCorners.forEach((corner) => {
      corner.interactive = false;
    });
  }
}
