const _getConfigVal = (val: string) => {
  return !!window && !!window.env
    ? window.env[val]
    : null;
}

export const getBaseUrl = () => _getConfigVal('apiBaseUrl');
export const getGoogleApiKey = () => _getConfigVal('googleApiKey');
export const getStripeApiKey = () => _getConfigVal('stripeApiKey');
export const terminalID = (): string => _getConfigVal('terminalID');
export const merchantID = (): string => _getConfigVal('merchantID');

