import React from 'react';
import { Container } from '../Container';
import { ReactComponent as DeliahSVG } from '../../../../assets/images/landing/svg/Deliah.svg';
import { ReactComponent as LipsDareToBeDifferentSVG } from '../../../../assets/images/landing/svg/lips-dare-to-be-different.svg';
import { motion } from 'framer-motion';
import { cloudinaryImages } from '@/utils/cloudinary';
import { useMediaQuery } from '@mui/material';

const animationContainer = {
  hidden: {
    opacity: 0,
  },
  visible: (custom: number) => ({
    opacity: 1,
    transition: {
      delay: custom * 0.2,
      duration: 0.8,
    },
  }),
};

const animationImageContainer = {
  hidden: { y: 100, opacity: 0 },
  visible: (custom: number) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: custom * 0.2,
      duration: 1,
    },
  }),
};

export const DareToBeDifferent = () => {
  const isMobile = useMediaQuery('(max-width: 1023px)');

  return (
    <motion.section
      initial='hidden'
      whileInView='visible'
      viewport={{ amount: 0.15, once: true }}
      className={isMobile ? 'pb-[35px] pt-0' : 'pb-[180px] pt-0'}
    >
      <Container>
        <div className='flex flex-col lg:flex-row justify-between gap-x-4 lg:gap-x-14 xl:gap-x-32 items-center lg:items-start py-5'>
          <div className={'rounded-3xl flex-shrink-0 order-2 lg:order-1'}>
            <motion.div
              whileInView={'visible'}
              initial={'hidden'}
              viewport={{ amount: 0.1, once: true }}
              className='relative z-10 flex group max-w-lg justify-end'
              custom={1}
              variants={animationImageContainer}
            >
              <div
                className='blur-2xl bg-shadow-warm'
                style={{ width: '80%', zIndex: -10, top: 48, height: '100%', position: 'absolute' }}
              ></div>
              <motion.div
                className='rounded-3xl bg-peach h-full w-full max-w-[90%] md:max-w-[90%] absolute right-0 bottom-0 -z-10'
              ></motion.div>
              <motion.img
                className='rounded-3xl object-cover max-w-full h-auto transition-transform !duration-700 origin-bottom-right group-hover:!scale-105'
                src={cloudinaryImages.landing.images.g1}
                alt='g1'
              />
            </motion.div>
          </div>

          <div className='flex flex-col justify-center items-center order-1 lg:order-2'>
            <DeliahSVG className={isMobile ? 'max-w-full -mb-0 h-auto -mt-10' : 'max-w-full -mb-24 -mt-24' } />
            <motion.div custom={4} variants={animationContainer}>
              <LipsDareToBeDifferentSVG className='mb-3 -mt-16' />
            </motion.div>
            <motion.div
              custom={4}
              variants={animationContainer}
              className='text-center text-terracotta text-[56px] lg:text-[10px] font-albra break-words mb-2 leading-[72px]'
            >
              <h3 style={isMobile ? {lineHeight: "48px"} : { lineHeight: "72px" }} className='max-w-[487px] text-terracota text-[56px] lg:text-[80px] leading-[72px] mb-25 font-albra text-center [text-wrap:balance]'>
                More Than Just a Gift
              </h3>
              {/* <h3 style={{ lineHeight:1 }}>Dare to Be Different</h3> */}
            </motion.div>
            <motion.p
              custom={4}
              variants={animationContainer}
              style={isMobile ? {lineHeight: "24px"} : {}}
              className={isMobile ? 'text-center text-lg lg:text-2xl text-stylish-black font-averta break-words mb-12 mt-3 lg:mb-0' : 'text-center text-lg lg:text-2xl text-stylish-black font-averta break-words mb-12 mt-6 lg:mb-0'}
            >
              Stand out from the crowd with an <br /> accessory that&apos;s as one-of-a-kind as you
            </motion.p>
          </div>
        </div>
      </Container>
    </motion.section>
  );
};
