import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import React, { ReactNode } from 'react';

type Props = {
  title: React.ReactNode;
  onClose: () => void;
  renderButtons?: ReactNode;
  children: JSX.Element;
  isOpen: boolean;
};

export function ModalUI(props: Props) {
  const { title, onClose, renderButtons, children, isOpen } = props;

  const closeModal = () => {
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      maxWidth={'xl'}
      fullWidth={true}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '16px',
        },
      }}
    >
      <Box className='flex justify-end px-5 pt-3'>
        <CloseIcon className={'text-[#3E4966] cursor-pointer text-[36px]'} onClick={closeModal} />
      </Box>
      <Box>
        <DialogTitle
          className={'font-averta text-2xl lg:text-3xl leading-7]'}
          sx={{
            color: '#3E4966',
            maxWidth: '1224px',
            margin: '0 auto',
            fontWeight: 900,
            textWrap: 'pretty',
          }}
        >
          {title}
        </DialogTitle>
      </Box>
      <DialogContent
        sx={{
          maxWidth: '1224px',
          margin: '0 auto',
          pt: 0,
          '&::-webkit-scrollbar-thumb': {
            background: '#3E4966',
          },
        }}
      >
        <DialogContentText component={'span'}>{children}</DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'center',
          pt: 2,
        }}
      >
        {renderButtons && renderButtons}
      </DialogActions>
    </Dialog>
  );
}
