import * as PIXI from 'pixi.js';
import { PendantCanvasAppProps } from './PendantCanvasApp';
import { PendantCanvasAppTextureByName } from './PendantCanvasAppAssetsLoader';
import {
  BackgroundConfig,
  backgroundConfigByName,
  getBackgroundSlicesAssets,
  getBgAsset,
} from '../nameplate-canvas-app/backgrounds';

export class Background {
  public readonly root: PIXI.Container;
  private slices: PIXI.Sprite[];
  private assetsHash = '';

  public readonly slicesContainer: PIXI.Container;
  public readonly chainsContainer: PIXI.Container;

  constructor() {
    this.root = new PIXI.Container();

    this.slicesContainer = new PIXI.Container();
    this.root.addChild(this.slicesContainer);

    this.chainsContainer = new PIXI.Container();
    this.root.addChild(this.chainsContainer);

    this.slices = [];
  }

  render(
    props: PendantCanvasAppProps,
    textureByName: PendantCanvasAppTextureByName,
    pendantHeight: number,
  ) {
    const { root } = this;
    const { backgroundName, zoom, position, isMobile } = props;
    const { pendantTexture } = textureByName;
    const bgConfig = backgroundConfigByName[backgroundName];

    const pendantCenterX = bgConfig.pendantProps.x;
    const pendantCenterY = pendantHeight / 2 + bgConfig.pendantProps.y;

    const rightLimit = window.innerWidth - (isMobile ? 0 : 450);
    const bottomLimit = window.innerHeight - (isMobile ? 300 : 0);
    const minBgScale = Math.max(rightLimit / bgConfig.width, bottomLimit / bgConfig.height);

    const maxScaleMultiplier = isMobile ? 2 : 2;
    const maxBgScale = maxScaleMultiplier / bgConfig.pendantProps.scale;
    const bgScale = minBgScale + (maxBgScale - minBgScale) * zoom;
    const bgX = position.x - pendantCenterX * bgScale;
    const bgY = position.y - pendantCenterY * bgScale;

    root.x = bgX;
    root.y = bgY;
    root.scale.set(bgScale);

    const width = bgConfig.width * bgScale;
    const height = bgConfig.height * bgScale;

    if (root.y > 0) {
      root.y = 0;
    } else if (root.y + height < bottomLimit) {
      root.y += bottomLimit - (root.y + height);
    }

    if (root.x > 0) {
      root.x = 0;
    } else if (root.x + width < window.innerWidth) {
      root.x += window.innerWidth - (root.x + width);
    }

    const assets = getBackgroundSlicesAssets(bgConfig);
    const assetsHash = String(assets.flat());

    if (assetsHash !== this.assetsHash) {
      this.assetsHash = assetsHash;

      this.destroySlices();
      this.createSlices(bgConfig, assets);
    }

    // Use this to setup pendantProps.scale
    // const ruler = PIXI.Sprite.from(getBgAsset(`${bgConfig.assetName}/ruler.png`));
    // root.addChild(ruler);
  }

  private createSlices(bgConfig: BackgroundConfig, assets: string[][]) {
    const sliceWidth = bgConfig.width / bgConfig.slicesCols;
    const sliceHeight = bgConfig.height / bgConfig.slicesRows;
    this.slices = [];

    assets.forEach((colAssets, i) => {
      colAssets.forEach((asset, j) => {
        const slice = PIXI.Sprite.from(asset);
        slice.x = i * sliceWidth;
        slice.y = j * sliceHeight;
        slice.scale.set((sliceHeight + 1) / sliceHeight);
        this.slicesContainer.addChild(slice);
        this.slices.push(slice);
      });
    });
  }

  private destroySlices() {
    this.slices.forEach((slice) => {
      slice.destroy();
    });
    this.slices = [];
  }
}
