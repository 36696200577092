import React from 'react';
import { useSnackbar, VariantType } from 'notistack';

import { useCommonState, AppMode } from '@/hooks';

export const Message: React.FC = () => {
  const [msg, setMsg] = React.useState<string>('');

  const { enqueueSnackbar } = useSnackbar();
  const { message, appMode } = useCommonState();

  React.useEffect(() => {
    setMsg((oldMessage) => {
      const newMesage = message?.content;

      if (newMesage && oldMessage !== newMesage) {
        return newMesage;
      }

      return oldMessage;
    });
  }, [message?.content]);

  React.useEffect(() => {
    if (appMode === AppMode.LANDING || window.location.pathname.includes('checkout')) {
      return;
    }

    if (msg && !window.location.pathname.includes('thank-you')) {
      enqueueSnackbar(msg, {
        variant: (message.type || 'default') as VariantType,
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'top',
        },
      });
    }
  }, [msg, appMode]);

  return <></>;
};
