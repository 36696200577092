import React, { createContext, useContext, useState } from 'react';
import { NameplateMenuContext, NameplateMenuPages, NameplateModeType } from './types';

export const NameplateMenu = createContext({} as NameplateMenuContext);

export const NameplateMenuContextProvider: React.FC<{ children: React.ReactElement }> = ({
  children,
}) => {
  const [mode, setMode] = useState<NameplateModeType>(NameplateModeType.TEXT);
  const [menuPage, setMenuPage] = useState<NameplateMenuPages>(NameplateMenuPages.MENU);

  const handleSelectMode = React.useCallback((mode: NameplateModeType) => {
    setMode(mode);
  }, []);

  const handleSelectMenuPage = React.useCallback((page: NameplateMenuPages) => {
    setMenuPage(page);
  }, []);

  return (
    <NameplateMenu.Provider
      value={{
        mode,
        menuPage,
        onSelectMode: handleSelectMode,
        onSelectMenuPage: handleSelectMenuPage,
      }}
    >
      {children}
    </NameplateMenu.Provider>
  );
};

export const useNamePlateMenu = () => {
  const context = useContext(NameplateMenu);
  return context;
};
