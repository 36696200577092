import React from 'react';

import { AppMode, NameplateMenuPages, NameplateModeType, useCommonState, useNamePlateMenu } from '../../../hooks';
import { SettingsEditor } from '../../../components/nameplate/editors/settings/SettingsEditor';
import { Menu } from '../../../components/nameplate/editors/settings/Menu';
import { TextEditor } from '../../../components/nameplate/editors/text/TextEditor';
import { MobileOrderButton } from '../../../components/nameplate/page/MobileOrderButton';
import { MaterialMenu } from '../../../components/nameplate/editors/settings/MaterialMenu';
import { DoneButton } from '../../../components/UI/buttons/DoneButton';
import { SizeMenu } from '../../../components/nameplate/editors/settings/SizeMenu';
import { ChainEditor } from '../../../components/nameplate/editors/chains/ChainEditor';
import { ZoomEditor } from "../../../components/nameplate/editors/ZoomEditor";
import { useNavigate } from 'react-router-dom';
import cls from 'classnames';
import ReactGA from 'react-ga4'
import { usePrice } from '@/hooks/use-price';
import { PriceSlider } from './PriceSlider';
import { OrderButton } from '@/components/nameplate/page/OrderButton';

const NameplateMenuActions: React.FC = () => {
  const { mode } = useNamePlateMenu();
  const { menuPage, onSelectMenuPage } = useNamePlateMenu();
  const { appMode, onChangeAppMode } = useCommonState();
  const navigate = useNavigate();

  const isSelectedTextEditor = mode === NameplateModeType.TEXT;
  const isSelectedSettingsEditor = mode === NameplateModeType.SETTINGS;

  const handleCloseMenu = () => onSelectMenuPage(NameplateMenuPages.MENU);

  const handleGoToCheckoutProcess = () => {
    navigate("/checkout");
  };

  const { priceData } = usePrice()
  
  const handleOpenConstructor = () => {
    if (appMode !== AppMode.CONSTRUCTOR) {

      ReactGA.gtag("event","view_item", {
        currency: "USD",
        value: priceData?.singleProductPrice
      })

      onChangeAppMode(AppMode.CONSTRUCTOR);
    }
  }

  const isLanding = appMode === AppMode.LANDING;

  return (
    <div
      className={cls('flex flex-col items-center w-full px-[20] z-[1000] pb-0')}
      onClick={handleOpenConstructor}
    >
      {isSelectedTextEditor && <>
        {menuPage === NameplateMenuPages.MENU ? (<>
        
          {appMode === AppMode.CONSTRUCTOR && <PriceSlider />}
          <TextEditor />
          <Menu/>
          {appMode === AppMode.CONSTRUCTOR && 
          <div style={{
            width: '100%',
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: 28,
            height: 64
          }}>
            <div
            style={{
              width: '100%',
              paddingLeft: 20,
              paddingRight: 20,
              backgroundColor: "#3E4966",
              height: 64,
              borderRadius: 123,
              justifyItems: 'center',
              alignContent: 'center'
            }}
            onClick={handleGoToCheckoutProcess}
            >
              <p
              style={{
                textAlign: 'center',
                fontSize: 20,
                fontWeight: 700
              }}
              >Order {priceData?.total}$</p>
            </div>
          </div>
          }
        </>) : (<>
          {menuPage === NameplateMenuPages.MATERIAL ? (
            <MaterialMenu/>
          ) : menuPage === NameplateMenuPages.SIZE ? (
            <>
              <SizeMenu/>
              <div className='h-2' />
            </>
          ) : menuPage === NameplateMenuPages.CHAIN ? (
            <>
              <ChainEditor withLabels={false}/>
              <div className='h-2' />
            </>
          ) : menuPage === NameplateMenuPages.ZOOM && (
              <>
                <ZoomEditor/>
                <div className='h-2' />
              </>
          )}
          <DoneButton onClick={handleCloseMenu}/>
        </>)}
      </>}
      {isSelectedSettingsEditor && <SettingsEditor />}
    </div>
  );
};

export default NameplateMenuActions;
