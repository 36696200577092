import React, {useEffect, useState} from 'react';
import cls from 'classnames';
import classes from './SizeMenu.module.css';
import { useMediaQuery } from '@mui/material';

import {
  NameplateSizeType,
  RulerUnits,
  useCommonState,
  useNameplateSettings
} from '../../../../../hooks';
import {Range} from '../../../../UI/Range';
import {getWeightInGrams, getWeightInOunces} from '../../../../../utils/nameplate';
import {mmToInch, preciseRound} from '../../../../../utils/math';

export const SizeMenu: React.FC = () => {
  const { text, fontSize, onChangeFontSize, saveSnapshot, selectedMaterial, widthInMM, heightInMM, area } = useNameplateSettings();
  const [isSizeCustom, setIsSizeCustom] = useState<boolean>();
  const { rulerMode } = useCommonState();
  const isMobile = useMediaQuery('(max-width: 1023px)');

  const smallChosen = fontSize == NameplateSizeType.SMALL || fontSize < NameplateSizeType.SMALL + (NameplateSizeType.MEDIUM - NameplateSizeType.SMALL) / 2;
  const mediumChosen = fontSize == NameplateSizeType.MEDIUM || (fontSize >= NameplateSizeType.MEDIUM / 2 && fontSize <= NameplateSizeType.MEDIUM + (NameplateSizeType.LARGE - NameplateSizeType.MEDIUM) / 2);
  const largeChosen = fontSize == NameplateSizeType.LARGE || fontSize > NameplateSizeType.MEDIUM + (NameplateSizeType.LARGE - NameplateSizeType.MEDIUM) / 2;

  const getTitle = () => {
    let res = '';

    if (smallChosen) {
      res = 'S';
    } else if (mediumChosen) {
      res = 'M';
    } else if (largeChosen) {
      res = 'L'
    }

    return res;
  }

  useEffect(() => {
    setIsSizeCustom(!smallChosen && !mediumChosen && !largeChosen);
  }, [fontSize]);

  const handleChangeFontSize = React.useCallback(
    (e: any) => {
      e.preventDefault();
      onChangeFontSize(e.target.value);
      handleOnCompleteRangeChange(e);
    },
    [fontSize],
  );

  const handleOnCompleteRangeChange = React.useCallback(
    (e: any) => {
      saveSnapshot();
    },
    [fontSize],
  );

  const getSizes = (mode: RulerUnits) => {
    const isCM = mode === RulerUnits.CM;
    const width = isCM ? widthInMM : mmToInch(widthInMM);
    const height = isCM ? heightInMM : mmToInch(heightInMM);
    const weight = isCM ? getWeightInGrams(area, selectedMaterial) : getWeightInOunces(area, selectedMaterial)
    const units = isCM ? ['mm', 'g'] : ['inch', 'oz']

    return (
        <>
          {isMobile && <span>{getTitle()}, </span>}
          {text.length > 0 ? (
              <span>{preciseRound(width)} x {preciseRound(height)} {units[0]}</span> //, {preciseRound(weight)} {units[1]}
          ) : (
              <span>0 x 0 {units[0]}, 0 {units[1]}</span>
          )}
        </>
    )
  };

  return (
    <>
      <span className={cls(classes.selectedSize, 'z-20 self-center relative -bottom-2', isMobile && 'bottom-2')}>
        <span className={cls(isMobile && 'text-[21px]')}>Size</span> {!isMobile && (<span>: {getTitle()}</span>)}
      </span>

      <div className='pb-5 sm:pb-3'>
        <Range
          value={fontSize}
          min={NameplateSizeType.SMALL}
          max={NameplateSizeType.LARGE}
          step={0.1}
          onChange={handleChangeFontSize}
          isRed={isSizeCustom}
        />
      </div>

      <span className={cls(classes.selectedSizeInfo, isMobile && classes.selectedSizeInfoMobile)}>
        {getSizes(rulerMode)}
      </span>
    </>
  );
};
