export type Constructor = {
  defaultText: string;
  defaultFont: string;
  materials: MaterialElement[];
};

type MaterialElement = {
  title: string;
  carat: string;
  isDefault: boolean;
};

export const getDefaultText = (config: Constructor): string => {
  return config.defaultText;
};

export const getDefaultFont = (config: Constructor): string => {
  return config.defaultFont;
};

export const getDefaultMaterialElement = (config: Constructor): MaterialElement => {
  const el = config.materials.find((e) => e.isDefault);

  if (el) {
    return el;
  }
  return config.materials[0];
};

export const testConstructorConfig: Constructor = {
  defaultText: 'Love',
  defaultFont: 'Anderlecht',
  materials: [
    {
      title: 'Gold',
      carat: '14k',
      isDefault: true,
    },
  ],
};

export const defaultNameplateConstructor: Constructor = {
  defaultText: 'Emma',
  defaultFont: 'Anderlecht',
  materials: [
    {
      title: 'Silver',
      carat: '925',
      isDefault: true,
    },
    {
      title: 'Gold',
      carat: '14k',
      isDefault: false,
    },
    // {
    //   title: 'White_Gold',
    //   isDefault: false,
    // },
    {
      title: 'Rose_gold',
      carat: '14k',
      isDefault: false,
    },
  ],
};
