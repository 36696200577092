import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { OrderStateContext } from './types';
import { getOrder } from '@api/cms/orders';
import { Order } from '@api/cms/orders/dto';
import { AxiosError } from 'axios';

export const OrderServerState = createContext({} as OrderStateContext);

export const ServerOrderStateContextProvider: React.FC<{ children: React.ReactElement }> = ({
  children,
}) => {
  const [state, setState] = useState<null | Order>(null);
  const [isFetching, setFetching] = useState(true);
  const [error, setError] = useState<null | AxiosError>(null);

  useEffect(() => {
    const idOrder = localStorage.getItem('orderId');

    if (!idOrder) {
      setFetching(false);
      return;
    }

    (async () => {
      try {
        const { data: order } = await getOrder(idOrder as unknown as number);
        setState(order);
      } catch (err) {
        const errorTyped = err as AxiosError;
        setError(errorTyped);
        localStorage.removeItem('orderId');
        console.error('err => ', errorTyped);
      } finally {
        setFetching(false);
      }
    })();
  }, []);

  const resetState = useCallback(() => {
    setState(null);
    setError(null);
  }, []);

  return (
    <OrderServerState.Provider
      value={{
        orderData: state,
        isLoading: isFetching,
        onResetState: resetState,
        error,
      }}
    >
      {children}
    </OrderServerState.Provider>
  );
};

export const useGetOrder = () => {
  const context = useContext(OrderServerState);

  if (!context) {
    throw Error("useGetOrder has to uses inside 'ServerOrderStateContextProvider' provider");
  }

  return context;
};
