import * as PIXI from 'pixi.js';
import { NameplateCanvasAppLayout } from './NameplateCanvasAppLayout';
import { GestureHandler } from '../common/controllers/types';
import { createNameplateLabelGesturesHandler } from './gestureHandlers';
import { createDrawLetterManifest, drawLetterSpacing } from './TextOverride';
import { NameplateMaterialType } from '@/hooks';
import { NameplateLabelMaterial } from './NameplateLabelMaterial';
import { getBoundsAndArea, getBoundsAndAreaAlternative } from './LabelUtils';
import { NameplateCircle } from './NameplateCircle';
import axios from 'axios';
import imageSrc from '@assets/images/icons/common/font1.png'

export type NameplateLabelProps = {
  text: string;
  letterSpacing: number;
  fontFamily: string;
  fontWeight: PIXI.TextStyleFontWeight;
  fontStyle: PIXI.TextStyleFontStyle;
  fontSize: number;
  deformation: number;
  textSize: number;
  materialType: NameplateMaterialType;
  sizeLimitMm: number;
};

const strokeThicknessMap: Record<string, number> = {
  Anderlecht: 2,
};

const DEFORMATION_LIMIT = 0.001;

export class NameplateLabel {
  public readonly mesh: PIXI.SimpleRope;
  public readonly root: PIXI.Container;
  public readonly inputArea: PIXI.Sprite;

  private readonly label: PIXI.Text;
  // private textImage: PIXI.Sprite;
  private readonly app: PIXI.Application;
  private readonly layout: NameplateCanvasAppLayout;
  private readonly deformationPoints = Array.from({ length: 1000 }).map(() => new PIXI.Point());
  private props: NameplateLabelProps | null = null;
  private prevMeshTexture: PIXI.Texture | null = null;
  private gestureHandler!: GestureHandler;
  private material: NameplateLabelMaterial;
  private materialTexture: PIXI.Texture = PIXI.Texture.EMPTY;
  private special_K_area = 0;
  private special_K_pixels_area = 0.00020318692352377365;

  private koef_total_pixels = 0;
  private koef_emma_pixels = 0;
  private width_koef = 0;
  private height_koef = 0;

  public readonly circleLeft: NameplateCircle;
  public readonly circleRight: NameplateCircle;

  public readonly drawLetterSpacingOut = createDrawLetterManifest();
  private bounds: { minX: number; minY: number; maxX: number; maxY: number } = {
    minX: -100,
    minY: -100,
    maxX: -100,
    maxY: -100,
  };

  constructor(app: PIXI.Application, layout: NameplateCanvasAppLayout) {
    this.app = app;
    this.layout = layout;

    this.root = new PIXI.Container();

    this.label = new PIXI.Text('', {
      fill: '#ffffff',
      stroke: '#ffffff',
      strokeThickness: 0,
      fontSize: 160,
      padding: 10,
    });

    this.circleLeft = new NameplateCircle(this.root);
    this.circleRight = new NameplateCircle(this.root);

    const texture = PIXI.Texture.from(this.label.canvas);
    PIXI.Texture.removeFromCache(texture);

    this.mesh = new PIXI.SimpleRope(texture, this.deformationPoints);
    this.root.addChild(this.mesh);

    this.inputArea = PIXI.Sprite.from(PIXI.Texture.EMPTY);
    this.inputArea.anchor.set(0.5);
    this.root.addChild(this.inputArea);

    this.material = new NameplateLabelMaterial();

    // this.textImage = PIXI.Sprite.from(`http://127.0.0.1:3334/image?text=${this.props?.text}&font=${this.props?.fontFamily}`)

    // this.root.addChild(this.textImage)

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore NOTE: This is pixi override to get some info
    this.label.drawLetterSpacing = (text: string, x: number, y: number, isStroke = false) => {
      drawLetterSpacing(this.label, this.drawLetterSpacingOut, text, x, y, isStroke);
    };
  }

  addGestureHandler() {
    this.gestureHandler = createNameplateLabelGesturesHandler(this.layout, this);
    this.layout.controller.add(this.gestureHandler);
  }

  render(labelProps: NameplateLabelProps, materialTexture: PIXI.Texture) {
    const prev = this.props;
    const materialChanged = materialTexture !== this.materialTexture;
    this.props = labelProps;
    this.materialTexture = materialTexture;

    const labelDirty =
      materialChanged ||
      labelProps.text !== prev?.text ||
      labelProps.letterSpacing !== prev?.letterSpacing ||
      labelProps.fontFamily !== prev?.fontFamily ||
      labelProps.fontStyle !== prev?.fontStyle ||
      labelProps.textSize !== prev?.textSize;

    const meshDirty = labelDirty || labelProps.deformation !== prev?.deformation;

    const transformDirty = meshDirty || labelProps.fontSize !== prev?.fontSize;

    if (labelDirty) {
      this.renderLabel(labelProps);
      // if (this.timeout) {
      //   clearTimeout(this.timeout)
      // }
      // this.timeout = setTimeout(() => {
      //   // const textureImage = PIXI.Texture.from(`http://127.0.0.1:3334/image?text=${this.props?.text}&font=${this.props?.fontFamily}`)
      //   const bounds = this.root.getBounds()
      //   const labelBounds = this.label.getBounds()
      //   console.log(bounds)
      //   console.log(labelBounds)
      //   console.log(this.root.width , this.root.height)
      //   // this.textImage.texture = textureImage
      //   this.textImage.width = this.root.width
      //   this.textImage.height = this.root.height
      //   this.textImage.x = -1400
      //   this.textImage.y = -650
      // }, 500);
    
    }

    if (meshDirty) {
      this.renderMesh(labelProps);
    }

    if (transformDirty) {
      this.renderTransform(labelProps);
    }
  }

  private timeout: NodeJS.Timeout | null = null;

  private renderLabel(labelProps: NameplateLabelProps) {
    const { label } = this;
    const { text, letterSpacing, fontFamily, fontStyle, textSize } = labelProps;

    label.text = text;
    label.style.letterSpacing = letterSpacing;
    label.style.fontFamily = fontFamily;
    label.style.strokeThickness = strokeThicknessMap[fontFamily] ?? 0;
    label.style.fontStyle = fontStyle;
    label.scale.x = textSize;
    label.updateText(false);
  }

  private renderMesh(labelProps: NameplateLabelProps) {
    const { mesh } = this;
    const { deformation, textSize } = labelProps;

    const texture = this.material.getTexture(this.app, this.label, textSize, this.materialTexture);
    mesh.texture = texture;

    const direction = deformation * PIXI.DEG_TO_RAD;

    if (Math.abs(Math.sin(direction)) > DEFORMATION_LIMIT) {
      const radius = texture.width / 2 / Math.tan(direction);
      const stepAngle = texture.width / radius / (this.deformationPoints.length - 1);
      const startAngle = -Math.PI / 2 - (this.deformationPoints.length / 2) * stepAngle;

      this.deformationPoints.forEach((point, i) => {
        const angle = startAngle + i * stepAngle;
        point.x = Math.cos(angle) * radius;
        point.y = Math.sin(angle) * radius + radius;
      });
    } else {
      this.deformationPoints.forEach((point, i) => {
        point.x =
          ((i - this.deformationPoints.length / 2) * texture.width) /
          (this.deformationPoints.length - 1);
        point.y = 0;
      });
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    mesh._render(this.app.renderer);

    this.prevMeshTexture?.destroy(true);
    this.prevMeshTexture = texture;
  }

  private renderTransform(labelProps: NameplateLabelProps) {
    const { mesh, inputArea } = this;
    const { fontSize, fontFamily, text } = labelProps;


    mesh.scale.set(fontSize);
    this.placeCircles();

    const { bounds, area } = getBoundsAndArea(this.app, this.root);
    const { pixelsCount, totalPixelsCount, newWidth, newHeight } = getBoundsAndAreaAlternative(this.app, this.root);

    this.bounds = bounds;
    const pixToMM = this.koefPxToMm()
    const labelArea = pixelsCount*(pixToMM**2)
    this.layout.setLabelArea(labelArea);

    const widthInMm = newWidth*pixToMM;
    const heightInMm = newHeight*pixToMM;

    this.layout.setLabelWidth(widthInMm);
    this.layout.setLabelHeight(heightInMm);
  }

  private koefPxToMm() {
    return 0.26458333333719/2
  }

  private pxToMm() {
    const smallestLetterHeightMm = this.props!.fontSize;
    // const smallestLetterHeightPx = Number.isFinite(this.drawLetterSpacingOut.minLetterHeight)
    //   ? this.drawLetterSpacingOut.minLetterHeight || 1
    //   : 1;
    //
    // return smallestLetterHeightMm / smallestLetterHeightPx;

    return smallestLetterHeightMm / 68;
  }

  getHeightMm() {
    const { newHeight } = getBoundsAndAreaAlternative(this.app, this.root);
    const pixToMM = this.koefPxToMm();
    const heightInMm = newHeight*pixToMM;
    return heightInMm
  }

  getWidthMm() {
    const { newWidth } = getBoundsAndAreaAlternative(this.app, this.root);
    const pixToMM = this.koefPxToMm();
    const widthInMm = newWidth*pixToMM;
    return widthInMm
  }

  getBoundsInStageSpace(): { minX: number; minY: number; maxX: number; maxY: number } {
    const { minX, minY, maxX, maxY } = this.bounds;

    const lt = new PIXI.Point(minX, minY);
    const rb = new PIXI.Point(maxX, maxY);

    const points = [lt, rb].map((point) => {
      this.mesh.parent.toGlobal(point, point);
      return point;
    });

    const xs = points.map((p) => p.x);
    const ys = points.map((p) => p.y);

    return {
      minX: Math.min(...xs),
      minY: Math.min(...ys),
      maxX: Math.max(...xs),
      maxY: Math.max(...ys),
    };
  }

  applyDeformation(x: number, y: number) {
    const texture = this.mesh.texture;
    const direction = (this.props?.deformation ?? 0) * -PIXI.DEG_TO_RAD;
    const textSize = this.props?.textSize ?? 1;

    if (Math.abs(Math.sin(direction)) > DEFORMATION_LIMIT) {
      const radius = texture.width / 2 / Math.tan(direction);
      const angle = (x * textSize) / radius;

      const dist = radius + y;
      const point = { x: 0, y: 0 };
      point.x = Math.sin(angle) * dist;
      point.y = Math.cos(angle) * dist - radius;

      return point;
    }

    return { x: x * textSize, y };
  }

  placeCircles() {
    const {
      firstCharBaseX,
      firstCharBaseY,
      lastCharBaseX,
      lastCharBaseY,
      leftCircleCenterX,
      leftCircleCenterY,
      leftCircleEdgeX,
      leftCircleEdgeY,
      rightCircleCenterX,
      rightCircleCenterY,
      rightCircleEdgeX,
      rightCircleEdgeY,
    } = this.drawLetterSpacingOut;

    const leftCircleCenter = this.applyDeformation(
      firstCharBaseX + leftCircleCenterX,
      firstCharBaseY + leftCircleCenterY,
    );
    const rightCircleCenter = this.applyDeformation(
      lastCharBaseX + rightCircleCenterX,
      lastCharBaseY + rightCircleCenterY,
    );

    const leftCircleEdge = this.applyDeformation(
      firstCharBaseX + leftCircleEdgeX,
      firstCharBaseY + leftCircleEdgeY,
    );
    const rightCircleEdge = this.applyDeformation(
      lastCharBaseX + rightCircleEdgeX,
      lastCharBaseY + rightCircleEdgeY,
    );

    const circleCenter = new PIXI.Point(leftCircleCenterX, leftCircleCenterY);
    const circleEdge = new PIXI.Point(leftCircleEdgeX, leftCircleEdgeY);

    [
      leftCircleCenter,
      rightCircleCenter,
      leftCircleEdge,
      rightCircleEdge,
      circleCenter,
      circleEdge,
    ].forEach((point) => {
      this.root.toLocal(point, this.mesh, point);
    });

    this.circleLeft.set(leftCircleEdge, leftCircleCenter, this.materialTexture);
    this.circleRight.set(rightCircleEdge, rightCircleCenter, this.materialTexture);
  }
}
