import React, { useEffect, useRef, useState } from 'react';
import classes from './RulerButtons.module.css';
import classNames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import { useMediaQuery } from '@mui/material';

import { RulerUnits, useCommonState } from '@/hooks';
import { Button } from '../Button';

import { ReactComponent as RulerIcon } from '../../../../assets/images/icons/common/ruler.svg';
import { ReactComponent as UnitsArrowsIcon } from '../../../../assets/images/icons/common/units-arrows.svg';

interface Props {
  whiteButtons?: boolean;
}

export const RulerButtons: React.FC<Props> = ({ whiteButtons }) => {
  const { rulerIsEnabled, onChangeRulerIsEnabled, rulerMode, onSelectRulerMode } = useCommonState();
  const isMobile = useMediaQuery('(max-width: 1023px)');
  const [count, setCount] = useState(0);
  const handleSelectRulerInchMode = () => onSelectRulerMode(RulerUnits.INCH);
  const handleSelectRulerCmMode = () => onSelectRulerMode(RulerUnits.CM);

  // const [rulerModeVal, setRulerMode] = useState<RulerModes>(RulerModes.disable);

  const handleRulerTap = React.useCallback(() => {
    setCount((count) => {
      if (count % 3 == 0) {
        onChangeRulerIsEnabled(true)
      } else if (count % 3 == 1) {
        onSelectRulerMode(RulerUnits.INCH)
      } else {
        onSelectRulerMode(RulerUnits.CM)
        onChangeRulerIsEnabled(false)
      }
      return count + 1
    });
  }, []);

  return (
    <div className='relative pr-[3px]'>
      {/* Ruler */}
      <Button
        isTransparent={true}
        fullContent={true}
        onClick={handleRulerTap}
        isActive={rulerIsEnabled}
        style={whiteButtons ? {
          background: 'rgba(255,255,255,0.5)',
          borderRadius: '50%'
        } : {}}
      >
        {
          rulerIsEnabled ?
            rulerMode == RulerUnits.INCH ?
              <div>
                <div className={classNames(
                  classes.UnitsButton + ' mt-1',
                  rulerIsEnabled && classes.UnitsButtonActive
                )}>
                  <span className='mb-1'>inch</span>
                </div>
                {!isMobile ? <UnitsArrowsIcon /> : <></>}
              </div>
              :
              <div>
                <div className={classNames(
                  classes.UnitsButton + ' mt-1',
                  rulerIsEnabled && classes.UnitsButtonActive
                )}>
                  <span className='mb-1'>mm</span>
                </div>
                {!isMobile ? <UnitsArrowsIcon /> : <></>}
              </div>
            :
            <RulerIcon/>
        }
      </Button>

      {/* <AnimatePresence>
        {(rulerIsEnabled || !isMobile) && (
          <motion.div
            initial={{ opacity: 0, x: 0, y: !isMobile ? -60 : -52 }}
            animate={{ opacity: 1, x: !isMobile ? 0 : 0, y: !isMobile ? -60 : 0 }}
            exit={{ opacity: 0, x: 0, y: !isMobile ? -60 : -52 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
          >
            {rulerMode === RulerUnits.INCH && (
              <Button
                isTransparent={true}
                fullContent={true}
                className={classNames(
                  classes.UnitsButton + ' mt-1 absolute',
                  !isMobile && ' mt-1 absolute right-full mr-[78px] top-0',
                  rulerIsEnabled && classes.UnitsButtonActive
                )}
                style={whiteButtons ? {
                  border: !isMobile ? '3px solid rgba(255,255,255,0.5)' : '2px solid rgba(255,255,255,0.5)',
                  fontSize: !isMobile ? '16px' : '14px',
                  borderRadius: '50%',
                } : {}}
                onClick={handleSelectRulerCmMode}
              >
                <span className='mb-1'>inch</span>
                <UnitsArrowsIcon/>
              </Button>
            )}

            {rulerMode === RulerUnits.CM && (
              <Button
                isTransparent={true}
                fullContent={true}
                className={classNames(
                  classes.UnitsButton + ' mt-1',
                  !isMobile && ' mt-1 absolute right-full mr-[78px] top-0',
                  rulerIsEnabled && classes.UnitsButtonActive
                )}
                style={whiteButtons ? {
                  border: !isMobile ? '3px solid rgba(255,255,255,0.5)' : '2px solid rgba(255,255,255,0.5)',
                  fontSize: !isMobile ? '16px' : '14px',
                  borderRadius: '50%',
                } : {}}
                onClick={handleSelectRulerInchMode}
              >
                <span className='mb-1'>mm</span>
                <UnitsArrowsIcon/>
              </Button>
            )}
          </motion.div>
        )}
      </AnimatePresence> */}
    </div>
  );
};
