import React from 'react';

import { useCommonState } from '../../../hooks';

import { ZoomRange } from '../ZoomRange';
import cls from "classnames";

type Props = {
  min: number;
  max: number;
  step: number;
  isHorizontal?: boolean;
};

export const ZoomPicker: React.FC<Props> = ({ min, max, step, isHorizontal }) => {
  const { zoom, onChangeZoom } = useCommonState();

  const handleChangeRange = React.useCallback((e: any) => {
    onChangeZoom(Number(e.target.value));
  }, []);

  return (
    <div className={cls('px-3 pt-5 pb-5 mb-5 z-10', !isHorizontal ? 'absolute bottom-1/2 -right-4 -rotate-90' : 'scale-125 mb-7')}>
      <ZoomRange
        className='block'
        value={zoom}
        min={min}
        max={max}
        step={step}
        onChange={handleChangeRange}
        isWhite={true}
        withoutStrip={true}
        isHorizontal={isHorizontal}
      />
    </div>
  );
};
