import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';

type Props = {
  className?: string;
  style?: React.CSSProperties;
};

export const Container = (props: PropsWithChildren<Props>) => {
  const { children, className, style } = props;

  return (
    <div
      style={style}
      className={clsx({
        [`${className}`]: className,
        'px-2 lg:px-20 xxls:max-w-[1440px] xxls:mx-auto': !className,
      })}
    >
      {children}
    </div>
  );
};
