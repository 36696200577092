import React, { PropsWithChildren, useState } from 'react';
import { AccordionSummary, AccordionDetails, Accordion } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import clsx from 'clsx';

export const ContactsDisclosure = (props: PropsWithChildren) => {
  const { children } = props;
  const [open, setOpen] = useState(false);

  const toggleAccordion = () => {
    setOpen(!open);
  };

  return (
    <Accordion expanded={open} sx={{ border: 0, boxShadow: 'none' }}>
      <AccordionSummary
        sx={{ m: 0, p: 0 }}
        onClick={toggleAccordion}
        aria-controls='panel-content'
        id='panel-header'
      >
        <div
          className={clsx('transition-colors duration-300 rounded-2xl py-0 w-full', {
            'bg-[#90B9C6]/10': open,
          })}
        >
          <button className='text-xl text-stylish-black font-averta font-bold flex gap-x-2 items-center bg-transparent border-0'>
            <span>Contacts</span>
            <ChevronRightIcon
              className={clsx('text-stylish-black w-8 h-8', {
                'rotate-90 transform': open,
              })}
            />
          </button>
        </div>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
