import * as PIXI from 'pixi.js';
import {NameplateSizeLimitState} from '../../hooks/nameplate/use-nameplate-settings/types';
import {NameplateCanvasAppProps} from './NameplateCanvasApp';
import {NameplateCanvasAppLayout} from './NameplateCanvasAppLayout';
import {DashLine} from 'pixi-dashed-line';

export class NameplateSizeLimits {
  public readonly root: PIXI.Container;
  private readonly graphics: PIXI.Graphics;
  private readonly layout: NameplateCanvasAppLayout;
  // private readonly maxSizeLabel: PIXI.Text;

  constructor(params: { layout: NameplateCanvasAppLayout }) {
    this.layout = params.layout;

    this.root = new PIXI.Container();

    this.graphics = new PIXI.Graphics();
    this.root.addChild(this.graphics);

    // this.maxSizeLabel = new PIXI.Text('Max size', {
    //   fill: '#ffffff',
    //   fontSize: 24,
    //   fontFamily: 'Arial',
    // });
    // this.maxSizeLabel.anchor.set(0.5);
    // this.root.addChild(this.maxSizeLabel);
  }

  render(props: NameplateCanvasAppProps) {
    // const { layout, graphics, maxSizeLabel } = this;
    const { layout, graphics } = this;

    const labelWidthMm = layout.label.getWidthMm();
    const percent = labelWidthMm / props.label.sizeLimitMm;

    const limitState =
      percent === Infinity
        ? NameplateSizeLimitState.Empty
        : percent > 1
        ? NameplateSizeLimitState.Exceeded
        : percent >= 0.8
        ? NameplateSizeLimitState.Warn
        : NameplateSizeLimitState.Allowed;

    props.setNameplateSizeLimitState(limitState);

    const labelBounds = layout.label.getBoundsInStageSpace();
    const centerX = (labelBounds.minX + labelBounds.maxX) / 2;
    const centerY = (labelBounds.minY + labelBounds.maxY) / 2;
    const mmToPx = (labelBounds.maxX - labelBounds.minX) / labelWidthMm;

    this.root.x = centerX;
    this.root.y = centerY;
    this.root.visible = limitState !== NameplateSizeLimitState.Allowed;

    const halfSize = 10000;
    const halfHoleSize = (props.label.sizeLimitMm / 2) * mmToPx;

    graphics.clear();

    const hex = 0xe57266;

    // if (limitState === NameplateSizeLimitState.Empty) {
    //   maxSizeLabel.text = 'Empty';
    // }

    if (limitState === NameplateSizeLimitState.Exceeded) {
      graphics
        .beginFill(hex, 0.2)
        .moveTo(-halfSize, -halfSize)
        .lineTo(halfSize, -halfSize)
        .lineTo(halfSize, halfSize)
        .lineTo(-halfSize, halfSize)
        .closePath()
        .beginHole()
        .moveTo(-halfHoleSize, -halfHoleSize)
        .lineTo(halfHoleSize, -halfHoleSize)
        .lineTo(halfHoleSize, halfHoleSize)
        .lineTo(-halfHoleSize, halfHoleSize)
        .closePath()
        .endHole()
        .endFill();
    }

    const dash = new DashLine(graphics, {
      dash: [3, 3],
      width: 1,
      color: hex,
    });

    dash.drawRect(-halfHoleSize, -halfHoleSize, halfHoleSize * 2, halfHoleSize * 2);
    dash.closePath();

    const frameSize = halfHoleSize * 0.4;

    [
      { x: -halfHoleSize, y: -halfHoleSize },
      { x: halfHoleSize, y: -halfHoleSize },
      { x: halfHoleSize, y: halfHoleSize },
      { x: -halfHoleSize, y: halfHoleSize },
    ].forEach((position, i) => {
      graphics
        .lineStyle(3, hex)
        .moveTo(position.x - Math.sign(position.x) * frameSize, position.y)
        .lineTo(position.x, position.y)
        .lineTo(position.x, position.y - Math.sign(position.y) * frameSize);
    });

    // // label
    // {
    //   maxSizeLabel.y = -halfHoleSize - maxSizeLabel.height / 2 - 20;
    //
    //   graphics.beginFill(hex, 1);
    //   graphics.drawRoundedRect(
    //     maxSizeLabel.x - maxSizeLabel.width / 2 - 20,
    //     maxSizeLabel.y - maxSizeLabel.height / 2 - 5,
    //     maxSizeLabel.width + 40,
    //     maxSizeLabel.height + 10,
    //     20,
    //   );
    // }
  }
}
