export const DEFAULT_PRICE = 0;

export enum RulerUnits {
  INCH = 'inch',
  CM = 'cm',
}

export enum AppMode {
  LANDING = 1,
  CONSTRUCTOR = 2
}

export interface CommonStateContext {
  isOpenMenu: boolean;
  isOrderAvailable: boolean;
  rulerIsEnabled: boolean;
  is3dViewEnabled: boolean;
  setIs3dViewEnabled: (isEnable: boolean) => void;
  rulerMode: RulerUnits;
  zoom: number;
  appMode: AppMode;
  message: {
    id?: number;
    type?: string;
    content: string;
    isActive: boolean;
  };
  onChangeOrderAvailable: (isAvailable: boolean) => void;
  onChangeRulerIsEnabled: (isEnable: boolean) => void;
  onSelectRulerMode: (mode: RulerUnits) => void;
  onChangeZoom: (zoom: number) => void;
  onChangeMenuState: (isOpenMenu: boolean) => void;
  callMessage: (content: string) => void;
  onChangeAppMode: (mode: AppMode) => void;
}
