import * as PIXI from 'pixi.js';
import { PendantCanvasApp, PendantCanvasAppProps } from './PendantCanvasApp';
import { PendantCanvasAppTextureByName } from './PendantCanvasAppAssetsLoader';
import {
  getPendantAnchorY,
  getPendantWidth,
  pendantPivotByShape,
} from '../../hooks/pendant/use-pendant-settings/pendants';
import { PendantImageFilterType } from '../../hooks';
import {
  PendantCanvasAppRemoveIcon,
  PendantCanvasAppRemoveIconViewState,
} from './PendantCanvasAppRemoveIcon';
import { MobileController } from '../common/controllers/MobileController';
import { Label } from './Label';
import { tryToAdaptPendantShape } from './adaptPendantShape';
import { Ruler } from './Ruler';
import { adjustLabelsOnShapeChange } from './adjustLabelsOnShapeChange';
import { createPendantImageGesturesHandler } from './gestureHandlers';
import { DesktopController } from '../common/controllers/DesktopController';
import { GestureHandler, IController } from '../common/controllers/types';
import { Background } from './Background';
import { backgroundConfigByName, ChainSide } from '../nameplate-canvas-app/backgrounds';
import { PendantChain } from './PendantChain';
import { ClipArts } from './ClipArts/ClipArts';

export class PendantCanvasAppLayout {
  private readonly app: PendantCanvasApp;
  public props: PendantCanvasAppProps | null = null;
  public controller!: IController;

  public readonly bg: Background;
  public readonly chainLeft: PendantChain;
  public readonly chainRight: PendantChain;
  public readonly removeIcon: PendantCanvasAppRemoveIcon;
  public readonly pendantContainer: PIXI.Container;
  public readonly pendant: PIXI.Sprite;
  public readonly imageMask: PIXI.Sprite;
  public readonly labelMask: PIXI.Sprite;
  public readonly pendantImage: PIXI.Sprite;
  public readonly chainsRing: PIXI.Sprite;
  public readonly labelsContainer: PIXI.Container;
  public readonly transformViewContainer: PIXI.Container;
  public readonly labelById: Record<string, Label> = {};
  public readonly ruler: Ruler;
  public readonly clipArts: ClipArts;

  private imageInputHandler!: GestureHandler;
  private readonly filtersByType: Record<PendantImageFilterType, PIXI.Filter[] | null>;

  constructor(app: PendantCanvasApp) {
    this.app = app;

    this.bg = new Background();
    app.stage.addChild(this.bg.root);

    this.chainLeft = new PendantChain({
      chainsContainer: this.bg.chainsContainer,
      side: ChainSide.ChainLeft,
    });
    this.chainRight = new PendantChain({
      chainsContainer: this.bg.chainsContainer,
      side: ChainSide.ChainRight,
    });

    this.removeIcon = new PendantCanvasAppRemoveIcon(app);
    app.stage.addChild(this.removeIcon.root);

    this.pendantContainer = new PIXI.Container();
    app.stage.addChild(this.pendantContainer);

    this.pendant = new PIXI.Sprite();
    this.pendant.anchor.set(0.5);
    this.pendantContainer.addChild(this.pendant);

    this.imageMask = new PIXI.Sprite();
    this.imageMask.anchor.set(0.5);
    this.pendant.addChild(this.imageMask);

    this.labelMask = new PIXI.Sprite();
    this.labelMask.anchor.set(0.5);
    this.pendant.addChild(this.labelMask);

    this.pendantImage = new PIXI.Sprite();
    this.pendantImage.anchor.set(0.5);
    this.pendantImage.mask = this.imageMask;
    this.pendantImage.alpha = 0.9;
    this.pendant.addChild(this.pendantImage);

    this.clipArts = new ClipArts(this);
    this.clipArts.root.mask = this.labelMask;
    this.pendant.addChild(this.clipArts.root);

    this.labelsContainer = new PIXI.Container();
    this.pendant.addChild(this.labelsContainer);

    this.chainsRing = new PIXI.Sprite();
    this.pendant.addChild(this.chainsRing);

    this.ruler = new Ruler();
    app.stage.addChild(this.ruler.root);

    this.transformViewContainer = new PIXI.Container();
    app.stage.addChild(this.transformViewContainer);

    this.filtersByType = {
      [PendantImageFilterType.NoFilter]: (() => {
        const filter = new PIXI.filters.ColorMatrixFilter();
        filter.greyscale(0.4, true);

        return [filter];
      })(),
    };
  }

  ensureControllerCreated(isMobile: boolean) {
    if (this.controller) return;

    this.controller = new (isMobile ? MobileController : DesktopController)({
      app: this.app,
      hammer: this.app.hammer,
      panningCallback: this.controllerPanningCallback,
      pointerUpCallback: this.controllerPointerUpCallback,
      parent: this.transformViewContainer,
      deactivateCallback: () => {
        this.props?.setSelectedItem(null);
      },
    });
    this.imageInputHandler = createPendantImageGesturesHandler(this);
    this.controller.add(this.imageInputHandler);
  }

  render(props: PendantCanvasAppProps, textureByName: PendantCanvasAppTextureByName) {
    this.ensureControllerCreated(props.isMobile);

    const {
      backgroundName,
      shape,
      messageShape,
      pendantSize,
      pendantMaterial,
      image,
      imageRotation,
      imageSize,
      imagePosition,
      imageFilter,
      labels,
      shapeAutoAdapted,
      activeTextId,
      isSnapshot,
    } = props;
    const { pendantTexture, labelMaskTexture } = textureByName;
    const {
      bg,
      pendantContainer,
      pendant,
      imageMask,
      labelMask,
      pendantImage,
      removeIcon,
      controller,
      labelById,
      ruler,
      imageInputHandler,
      clipArts,
      chainsRing,
    } = this;
    const prev = this.props;
    this.props = props;

    const bgConfig = backgroundConfigByName[backgroundName];

    const prevPendantTexture = pendant.texture;
    pendant.texture = pendantTexture;

    pendant.scale.set(
      (getPendantWidth(shape, messageShape, pendantSize) / pendant.texture.width) *
        bgConfig.pendantProps.scale,
    );

    bg.render(props, textureByName, pendant.height);
    clipArts.render(props, prev);

    const pendantPivotX = pendantPivotByShape[shape]?.x ?? pendantTexture.width / 2;
    const pendantPivotY = pendantPivotByShape[shape]?.y ?? 0;

    const anchorY = getPendantAnchorY(shape, messageShape, pendantSize);
    pendant.anchor.y = anchorY;
    labelMask.anchor.y = anchorY;
    imageMask.anchor.y = anchorY;

    pendant.x = bgConfig.pendantProps.x - pendantPivotX * pendant.scale.x + pendant.width / 2;

    pendant.y =
      bgConfig.pendantProps.y - pendantPivotY * pendant.scale.y + pendant.height * anchorY;

    chainsRing.texture = textureByName.chainRingTexture;
    chainsRing.x = -bgConfig.chainRing.bottomPivot.x;
    chainsRing.y =
      pendantPivotY - pendantTexture.height * anchorY - bgConfig.chainRing.bottomPivot.y;

    imageMask.texture = labelMaskTexture;
    labelMask.texture = labelMaskTexture;
    imageInputHandler.target.interactive = image !== null;

    if (
      pendant.texture !== prevPendantTexture ||
      imageSize !== prev?.imageSize ||
      imagePosition !== prev?.imagePosition ||
      imageRotation !== prev?.imageRotation ||
      image !== prev?.image ||
      imageFilter !== prev?.imageFilter
    ) {
      pendantImage.texture = image ? PIXI.Texture.from(image) : PIXI.Texture.EMPTY;
      pendantImage.filters = this.filtersByType[imageFilter];

      const pendantImageSizeMultiplier = Math.max(
        pendantTexture.width / pendantImage.texture.width,
        pendantTexture.height / pendantImage.texture.height,
      );
      pendantImage.scale.set(imageSize * pendantImageSizeMultiplier);
      pendantImage.x = imagePosition.x;
      pendantImage.y = imagePosition.y;
      pendantImage.rotation = imageRotation;
    }

    pendantContainer.position.copyFrom(bg.root.position);
    pendantContainer.scale.copyFrom(bg.root.scale);

    const shapeChanged = shape !== prev?.shape || messageShape !== prev?.messageShape;

    labels.forEach((labelProps) => {
      if (!labelProps) return null;

      if (!labelById[labelProps.id]) {
        labelById[labelProps.id] = new Label(labelProps.id, this.app, this);
      }

      const label = labelById[labelProps.id];
      label.outOfPendantDirty = label.outOfPendantDirty || shapeChanged;
      label.render(labelProps, pendantMaterial);
    });

    Object.values(labelById).forEach((label) => {
      if (!labels.some((l) => l?.id === label.id)) {
        label.onRemove();
      }
    });

    if (activeTextId !== prev?.activeTextId) {
      const label = labelById[activeTextId];

      if (label) {
        controller.activate(label.gestureHandler);
      }
    }

    ruler.render(props, imageMask, bgConfig.rulerColor);
    controller.render(props);
    removeIcon.render(props, pendant);

    this.chainLeft.setChain(bgConfig, pendantMaterial, chainsRing, textureByName);
    this.chainRight.setChain(bgConfig, pendantMaterial, chainsRing, textureByName);

    if (
      (shape !== prev?.shape ||
        messageShape !== prev?.messageShape ||
        pendantSize !== prev?.pendantSize) &&
      prevPendantTexture.width > 1 &&
      !shapeAutoAdapted
    ) {
      adjustLabelsOnShapeChange(props, this, prevPendantTexture);
    }

    if (!isSnapshot && !this.controller.isActive()) {
      const adapted = this.tryToAdaptPendantShape();

      if (adapted) {
        props.patchSnapshot();
      }
    }
  }

  tryToAdaptPendantShape() {
    return this.props ? tryToAdaptPendantShape(this.props, this) : false;
  }

  private readonly controllerPanningCallback = () => {
    const viewState =
      this.app.renderer.plugins.interaction.eventData.data.global.y > this.removeIcon.root.y - 100
        ? PendantCanvasAppRemoveIconViewState.VisibleHover
        : PendantCanvasAppRemoveIconViewState.Visible;

    this.props?.setRemoveIconViewState(viewState);
  };

  private readonly controllerPointerUpCallback = () => {
    if (this.props?.removeIconViewState === PendantCanvasAppRemoveIconViewState.VisibleHover) {
      this.controller.deactivate();
    }

    this.props?.setRemoveIconViewState(PendantCanvasAppRemoveIconViewState.Hidden);
  };
}
