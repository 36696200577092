import React, {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { CheckoutContext, IShippingData } from './types';
import { useGetOrder } from '@/hooks';
import { Order } from '@api/cms/orders/dto';

export const ShippingState = createContext({} as CheckoutContext);

const getInitialState = (order: Order | null) => {
  return {
    id: null,
    quantity: 1,
    total: order?.totalPrice || 0,
    user: {
      firstName: order?.firstName || '',
      lastName: order?.lastName || '',
      phone: order?.phone || '',
      email: order?.email || '',
      address1: order?.address1 || '',
      address2: order?.address2 || '',
      city: order?.city || '',
      state: '',
      zip: order?.zip || '',
    },
    singleProductPrice: order?.product?.price || 0,
    ratePrice: 0,
    selectedDelivery: null,
    isConfirmTerms: false,
    rates: [],
    shipments: order?.shipments || [],
  };
};

export const ShippingDataContext: React.FC<{ children: ReactElement }> = ({ children }) => {
  const { orderData } = useGetOrder();
  const [shippingData, setShippingData] = useState<IShippingData>(() => getInitialState(orderData));

  useEffect(() => {
    if (!orderData) {
      return;
    }

    const newShippingData = {
      id: orderData.id,
      user: {
        email: orderData.email,
        phone: orderData.phone,
        address1: orderData.address1,
        address2: orderData.address2,
        city: orderData.city,
        state: orderData.country,
        zip: orderData.zip,
        firstName: orderData.firstName,
        lastName: orderData.lastName,
      },
      total: orderData.totalPrice,
    };

    handleShippingData(newShippingData);
  }, [orderData?.id]);

  const handleShippingData = useCallback((values: Partial<IShippingData>) => {
    setShippingData((prevValues) => ({
      ...prevValues,
      ...values,
    }));
  }, []);

  const onChangeSingleProductPrice = useCallback((price: number) => {
    setShippingData((prevValues) => ({
      ...prevValues,
      singleProductPrice: price,
    }));
  }, []);

  const resetState = useCallback(() => {
    // localStorage.removeItem('orderId');
    setShippingData(getInitialState(null));
  }, []);

  const setOrderId = useCallback((orderId: string | number) => {
    // localStorage.setItem('orderId', JSON.stringify(orderId));
    setShippingData((prevValues) => ({
      ...prevValues,
      id: orderId,
    }));
  }, []);

  return (
    <ShippingState.Provider
      value={{
        shippingData: shippingData,
        onShippingData: handleShippingData,
        onChangeSingleProductPrice,
        resetState,
        setOrderId,
      }}
    >
      {children}
    </ShippingState.Provider>
  );
};

export const useShippingData = () => {
  const context = useContext(ShippingState);

  if (!context) {
    throw Error("useShippingData has to uses inside 'ShippingDataContext' provider");
  }

  return context;
};
