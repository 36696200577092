import * as PIXI from 'pixi.js';
import { DesktopTransformView } from './DesktopTransformView';
import { Cursor, CursorType } from './Cursor';
import { GestureHandler, IController } from './types';

type LayoutProps = any;

export class DesktopController implements IController {
  private readonly app: PIXI.Application;
  private props!: LayoutProps;
  private readonly view: DesktopTransformView;
  private readonly cursor: Cursor;

  private isPointerDown = false;
  private activeHandler: GestureHandler | null = null;
  private moveHandler: (e: PIXI.InteractionEvent) => void;
  private readonly panningCallback: () => void;
  private readonly pointerUpCallback: () => void;
  private readonly deactivateCallback: () => void;

  private panDisabled = false;

  constructor(params: {
    app: PIXI.Application;
    panningCallback: () => void;
    pointerUpCallback: () => void;
    deactivateCallback: () => void;
    parent: PIXI.Container;
  }) {
    this.app = params.app;
    this.moveHandler = this.emptyMoveHandler;
    this.panningCallback = params.panningCallback;
    this.pointerUpCallback = params.pointerUpCallback;
    this.deactivateCallback = params.deactivateCallback;

    this.cursor = new Cursor(this.app);

    this.view = new DesktopTransformView({
      handleCornerPointerDown: this.handleCornerPointerDown,
      handleRotationCornerPointerDown: this.handleRotationCornerPointerDown,
      cursor: this.cursor,
    });
    params.parent.addChild(this.view.root);

    this.app.renderer.plugins.interaction.on('pointerdown', this.handlePointerDown);
    this.app.renderer.plugins.interaction.on('pointermove', this.handlePointerMove);
    this.app.renderer.plugins.interaction.on('pointerup', this.handlePointerUp);
    this.app.renderer.plugins.interaction.on('pointerupoutside', this.handlePointerUp);

    window.addEventListener('mousedown', this.handleMouseDown);
  }

  private readonly handleMouseDown = (e: MouseEvent) => {
    if (e.target !== this.app.view) {
      this.deactivate();
    }
  };

  private readonly handlePointerDown = (e: PIXI.InteractionEvent) => {
    this.isPointerDown = true;

    if (this.activeHandler?.target !== e.target && !this.view.isPartOfTransformView(e.target)) {
      this.deactivate();
    }
  };

  private readonly handlePointerUp = (e: PIXI.InteractionEvent) => {
    const handler = this.activeHandler;
    this.isPointerDown = false;
    this.moveHandler = this.emptyMoveHandler;

    if (!handler) return;

    handler.startPosition = null;
    handler.scaleMultiplier = null;
    handler.rotationOffset = null;
    handler.onEnd(this.props);

    this.pointerUpCallback();
  };

  private readonly handlePointerMove = (e: PIXI.InteractionEvent) => {
    if (!this.isPointerDown || !this.activeHandler) return;

    this.moveHandler(e);
    this.refreshBoundsView();
  };

  private refreshBoundsView() {
    this.view.root.visible = !!this.activeHandler;

    if (!this.activeHandler) return;

    this.view.render(this.activeHandler.target);
  }

  private readonly handleCornerPointerDown = (e: PIXI.InteractionEvent) => {
    if (!this.activeHandler) return;

    e.stopPropagation();

    const downPos = this.activeHandler.target.parent.toLocal(e.data.global);
    const downDx = downPos.x - this.activeHandler.target.x;
    const downDy = downPos.y - this.activeHandler.target.y;
    const scaleM = this.activeHandler.getSize(this.props) / Math.hypot(downDy, downDx);

    this.moveHandler = (e: PIXI.InteractionEvent) => {
      const handler = this.activeHandler;

      if (!handler) return;

      const local = handler.target.parent.toLocal(e.data.global);
      const dx = local.x - handler.target.x;
      const dy = local.y - handler.target.y;
      const distance = Math.hypot(dy, dx);
      const scale = distance * scaleM;

      handler.setSize(this.props, scale);
    };
  };

  private readonly handleRotationCornerPointerDown = (e: PIXI.InteractionEvent) => {
    if (!this.activeHandler) return;

    e.stopPropagation();

    const downPos = this.activeHandler.target.parent.toLocal(e.data.global);
    const downDx = downPos.x - this.activeHandler.target.x;
    const downDy = downPos.y - this.activeHandler.target.y;
    const rotationOffset = this.activeHandler.target.rotation - Math.atan2(downDy, downDx);

    this.moveHandler = (e: PIXI.InteractionEvent) => {
      const handler = this.activeHandler;

      if (!handler) return;

      const local = handler.target.parent.toLocal(e.data.global);
      const dx = local.x - handler.target.x;
      const dy = local.y - handler.target.y;
      const rotation = Math.atan2(dy, dx);

      handler.setRotation(this.props, rotation + rotationOffset);
    };
  };

  private readonly emptyMoveHandler = () => {
    return;
  };

  private readonly panMoveHandler = (e: PIXI.InteractionEvent) => {
    const handler = this.activeHandler;

    if (!handler) return;

    const local = handler.target.parent.toLocal(e.data.global);

    handler.startPosition = handler.startPosition ?? {
      x: handler.target.x - local.x,
      y: handler.target.y - local.y,
    };

    handler.setPosition(
      this.props,
      handler.startPosition.x + local.x,
      handler.startPosition.y + local.y,
    );

    this.panningCallback();
  };

  add(handler: GestureHandler) {
    handler.target.interactive = true;

    handler.target.on('pointerdown', () => {
      this.activate(handler);

      if (!this.panDisabled) {
        this.moveHandler = this.panMoveHandler;
      }
    });

    this.cursor.subscribe({
      target: handler.target,
      getHoverType: () => (this.panDisabled ? CursorType.Default : CursorType.PanHover),
      getClickType: () => (this.panDisabled ? CursorType.Default : CursorType.Pan),
      getRotation: () => 0,
    });
  }

  isActive(): boolean {
    return Boolean(this.activeHandler) && this.isPointerDown;
  }

  isEditing(target: PIXI.Container): boolean {
    return this.activeHandler?.target === target;
  }

  activate(handler: GestureHandler): void {
    this.activeHandler = handler;
    this.refreshBoundsView();

    if (this.props) {
      handler.activate(this.props);
    }
  }

  deactivate() {
    this.activeHandler = null;
    this.refreshBoundsView();
    this.deactivateCallback();
  }

  render(props: LayoutProps) {
    this.props = props;
    this.refreshBoundsView();
  }

  destroy() {
    window.removeEventListener('mousedown', this.handleMouseDown);
  }

  disableRotation() {
    this.view.disableRotation();
  }

  disablePan(): void {
    this.panDisabled = true;
  }
}
