import * as PIXI from 'pixi.js';
import { PendantMaterialType } from '../../../hooks';
import { OutlineFilter } from '@pixi/filter-outline';

export const pendantTextColorByMaterial: Record<PendantMaterialType, string> = {
  [PendantMaterialType.GOLD]: '#FEFBD7',
  [PendantMaterialType.ROSE_GOLD]: '#FFEEED',
  [PendantMaterialType.SILVER]: '#FeFeFF',
};

export class EngravingMaterial {
  readonly root: PIXI.Container;

  private readonly layersContainer: PIXI.Container;

  private readonly view: PIXI.Sprite;

  private prevMaterial!: PendantMaterialType;

  private textureDirty = false;

  constructor(texture: PIXI.Texture) {
    this.root = new PIXI.Container();

    this.view = PIXI.Sprite.from(texture);
    this.view.anchor.set(0.5);
    this.root.addChild(this.view);

    this.layersContainer = new PIXI.Container();
    this.root.addChild(this.layersContainer);
  }

  setTexture(texture: PIXI.Texture) {
    this.textureDirty = true;
    this.view.texture = texture;
  }

  render(material: PendantMaterialType) {
    if (this.prevMaterial === material && !this.textureDirty) return;
    this.prevMaterial = material;

    this.view.tint = PIXI.utils.string2hex(pendantTextColorByMaterial[material]);

    this.layersContainer.removeChildren();

    [
      { color: 0x222222, thickness: 0.5, alpha: 0.9, x: 0, y: 0.5 },
      { color: 0xdddddd, thickness: 0.5, alpha: 0.8, x: 0, y: 1 },
    ].map(({ color, thickness, x, y, alpha }) => {
      const filter = new OutlineFilter();
      filter.color = color;
      filter.thickness = thickness;

      const layer = PIXI.Sprite.from(this.view.texture);
      layer.x = x;
      layer.y = y;
      layer.alpha = alpha;
      layer.tint = this.view.tint;
      layer.anchor.copyFrom(this.view.anchor);
      this.layersContainer.addChild(layer);

      layer.filters = [filter];

      return filter;
    });
  }

  destroy() {
    this.root.destroy();
  }
}
