import * as PIXI from 'pixi.js';
import cursorScalingAsset from '../../../assets/images/cursor/cursor-scaling.png';
import cursorRotatingAsset from '../../../assets/images/cursor/cursor-rotating.png';

export enum CursorType {
  Default = 'Default',
  Pan = 'Pan',
  PanHover = 'PanHover',
  Pinch = 'Pinch',
  PinchHover = 'PinchHover',
  Rotate = 'Rotate',
  RotateHover = 'RotateHover',
}

const configsByType: Record<
  CursorType,
  { asset: string; anchor: { x: number; y: number } } | null
> = {
  [CursorType.Default]: null,
  [CursorType.Pan]: null,
  [CursorType.PanHover]: null,
  [CursorType.Pinch]: { asset: cursorScalingAsset, anchor: { x: 0.5, y: 0.5 } },
  [CursorType.PinchHover]: { asset: cursorScalingAsset, anchor: { x: 0.5, y: 0.5 } },
  [CursorType.Rotate]: { asset: cursorRotatingAsset, anchor: { x: 0.5, y: 0.5 } },
  [CursorType.RotateHover]: { asset: cursorRotatingAsset, anchor: { x: 0.5, y: 0.5 } },
};

const realCursorByType: Record<CursorType, string> = {
  [CursorType.Default]: 'inherit',
  [CursorType.Pan]: 'grabbing',
  [CursorType.PanHover]: 'grab',
  [CursorType.Pinch]: 'none',
  [CursorType.PinchHover]: 'none',
  [CursorType.Rotate]: 'none',
  [CursorType.RotateHover]: 'none',
};

type Item = {
  target: PIXI.Container;
  getHoverType: () => CursorType;
  getClickType: () => CursorType;
  getRotation: () => number;
};

export class Cursor {
  private readonly app: PIXI.Application;
  private readonly view: PIXI.Sprite;

  private hoverItem: Item | null = null;
  private clickItem: Item | null = null;

  constructor(app: PIXI.Application) {
    this.app = app;

    this.view = PIXI.Sprite.from(PIXI.Texture.EMPTY);
    app.stage.addChild(this.view);

    app.renderer.plugins.interaction.on('pointermove', (e: PIXI.InteractionEvent) => {
      this.view.x = e.data.global.x;
      this.view.y = e.data.global.y;
      this.update();
    });

    Object.values(configsByType).forEach((config) => {
      if (!config) return;
      PIXI.Texture.fromURL(config.asset);
    });
  }

  update() {
    const item = this.clickItem ?? this.hoverItem;
    let type = CursorType.Default;

    if (item) {
      type = item === this.clickItem ? item.getClickType() : item.getHoverType();
    }

    const config = configsByType[type];
    this.view.texture = config ? PIXI.Texture.from(config.asset) : PIXI.Texture.EMPTY;
    this.view.anchor.copyFrom(config?.anchor ?? { x: 0.5, y: 0.5 });
    this.view.rotation = item?.getRotation() ?? 0;
    this.changeCursorMode(realCursorByType[type]);
  }

  changeCursorMode(cursorMode: string) {
    this.app.renderer.plugins.interaction.cursorStyles.default = cursorMode;
    this.app.renderer.plugins.interaction.setCursorMode(cursorMode);
    this.app.renderer.render(this.app.stage);
  }

  subscribe(item: Item) {
    const { target } = item;

    const removeHoverItem = () => {
      this.hoverItem = this.hoverItem === item ? null : this.hoverItem;
    };

    target.on('pointerover', (e: PIXI.InteractionEvent) => {
      this.hoverItem = item;
      this.update();
    });

    target.on('pointerout', (e: PIXI.InteractionEvent) => {
      removeHoverItem();
      this.update();
    });

    target.on('pointerupoutside', (e: PIXI.InteractionEvent) => {
      this.clickItem = null;
      removeHoverItem();
      this.update();
    });

    target.on('pointerup', (e: PIXI.InteractionEvent) => {
      this.clickItem = null;
      this.update();
    });

    target.on('pointerdown', (e: PIXI.InteractionEvent) => {
      this.clickItem = item;
      this.update();
    });
  }
}
