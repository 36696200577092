export async function promisifyEvent(element: HTMLElement, eventName: string): Promise<void> {
  return new Promise((resolve) => {
    const listener = () => {
      element.removeEventListener(eventName, listener);
      resolve();
    };

    element.addEventListener(eventName, listener);
  });
}
