import React from 'react';

import { AppMode, useCommonState, useNameplateSettings } from '@/hooks';
import { NameplateCanvasComponent } from '@/pixi/nameplate-canvas-app/NameplateCanvasComponent';
import NameplateHeader from '../NameplateHeader';
import NameplateMenuActions from './NameplateMenuActions';
import { Overlay } from '@components/common/Overlay';
import cls from 'classnames';
import { motion } from 'framer-motion';
import { Preloader } from '@/components/common/Preloader';

export const mobilePanel = 'mobilePanel';

export const NameplateMobile: React.FC = () => {
  const { texturesLoading, constructor, images } = useNameplateSettings();
  const { appMode } = useCommonState();

  const isLoading = texturesLoading || constructor.isLoading || !!images?.isLoading;

  return (
    <div
      className={cls('w-full absolute bg-cover bg-no-repeat bg-top bg-white', {
        'h-full': appMode === AppMode.CONSTRUCTOR,
      })}
    >
      <Overlay />
      <Preloader open={isLoading} />
      <div className='flex flex-col justify-between items-center w-full h-full'>
        <NameplateCanvasComponent />
        <NameplateHeader />
        <div
          className={cls('w-full h-full flex flex-col justify-end items-center', {
            'z-[100]': appMode === AppMode.CONSTRUCTOR,
          })}
        >
          <motion.div
            id={`${mobilePanel}`}
            animate={appMode === AppMode.LANDING ? { x: 0, y: 650 } : { x: 0, y: 0 }}
            transition={{ duration: 1, type: 'ease' }}
            className={cls('max-w-md xl:max-w-lg w-full relative z-[1000]')}
          >
            <NameplateMenuActions />
          </motion.div>
        </div>
      </div>
    </div>
  );
};
