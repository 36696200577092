import React from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './SettingsEditor.module.css';
import classNames from 'classnames';

import { NameplateMenuPages, useNamePlateMenu } from '../../../../../hooks';

import { Navigation } from '../Navigation';
import { Menu } from '../Menu';
import { MaterialMenu } from '../MaterialMenu';
import { SizeMenu } from '../SizeMenu';
import { DoneButton } from '../../../../UI/buttons/DoneButton';
import { OrderButton } from '../../../page/OrderButton';
import { useMediaQuery } from '@mui/material';

import namePlateIcon from '../../../../../assets/images/icons/common/nameplate.svg';

export const SettingsEditor: React.FC = () => {
  const { menuPage, onSelectMenuPage } = useNamePlateMenu();
  const isMobile = useMediaQuery('(max-width: 1023px)');
  const navigate = useNavigate();

  const handleGoBackMenuPage = React.useCallback(() => {
    onSelectMenuPage(NameplateMenuPages.MENU);
  }, [menuPage]);

  const handleGoToCheckoutProcess = () => {
    navigate("/checkout");
  };

  return (
    <>
      <Navigation />

      <div
        className={classNames(
          classes.settingsEditor,
          'w-full mt-1 pt-4 pl-3 pr-3 pb-7 flex flex-col justify-between items-center',
          !isMobile && classes.desktopSettingsEditor,
        )}
      >
        {!isMobile && <img src={namePlateIcon} alt='add' className='mt-2 mb-5' />}
        <div className={classes.contentContainer + ' w-full'}>
          {menuPage === NameplateMenuPages.MENU && <Menu />}
          {menuPage === NameplateMenuPages.MATERIAL && <MaterialMenu />}
          {menuPage === NameplateMenuPages.SIZE && <SizeMenu />}
        </div>

        {menuPage === NameplateMenuPages.MENU && isMobile && <OrderButton onClick={handleGoToCheckoutProcess} />}
        {menuPage !== NameplateMenuPages.MENU && <DoneButton onClick={handleGoBackMenuPage} />}
      </div>
    </>
  );
};
