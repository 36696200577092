import { useEffect, useState } from "react";

export function useMediaQuery(query: string) {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
        const media = window.matchMedia(query);
        if (media.matches !== matches) {
            setMatches(media.matches);
        }

        const listener = () => {
            setMatches(media.matches);
        };

        if (typeof media.addEventListener === "function") {
            media.addEventListener("change", listener);
        } else {
            media.addListener(listener);
        }

        return () => {
            if (typeof media.removeEventListener === "function") {
                media.removeEventListener("change", listener);
            } else {
                media.removeListener(listener);
            }
        };
    }, [matches, query]);

    return matches;
}

export const useIsXS = () => useMediaQuery('(min-width: 0px)');
export const useIsSM = () => useMediaQuery('(min-width: 390px)');
export const useIsMD = () => useMediaQuery('(min-width: 768px)');
export const useIsLG = () => useMediaQuery('(min-width: 1024px)');
export const useIsLGG = () => useMediaQuery('(min-width: 1144px)');
export const useIsXL = () => useMediaQuery('(min-width: 1280px)');
export const useIsXXLS = () => useMediaQuery('(min-width: 1440px)');
export const useIsXXL = () => useMediaQuery('(min-width: 1600px)');