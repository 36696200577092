import * as PIXI from 'pixi.js';
import {
  getLabelMaskAsset,
  getPendantAnchorY,
  getPossibleMessageShapes,
} from '../../hooks/pendant/use-pendant-settings/pendants';
import { PendantCanvasAppProps } from './PendantCanvasApp';
import { PendantCanvasAppLayout } from './PendantCanvasAppLayout';

export const tryToAdaptPendantShape = (
  props: PendantCanvasAppProps,
  layout: PendantCanvasAppLayout,
) => {
  const {
    autoAdaptShapeEnabled,
    shape,
    messageShape,
    setMessageShape,
    setShapeAutoAdapted,
    currentElement,
    pendantSize,
  } = props;
  const { labelById } = layout;
  const isSomeTextOutOfBounds = Object.values(labelById).some((label) =>
    label.getCachedOutOfBounds(),
  );

  let adapted = false;

  if (isSomeTextOutOfBounds && autoAdaptShapeEnabled) {
    const possibleMessageShapes = getPossibleMessageShapes(
      shape,
      pendantSize,
      currentElement,
    ).filter((asset) => asset !== messageShape);

    const labels = Object.values(labelById);

    for (const possibleMessageShape of possibleMessageShapes) {
      const asset = getLabelMaskAsset(shape, possibleMessageShape, pendantSize);
      const anchorY = getPendantAnchorY(shape, possibleMessageShape, pendantSize);
      const pendantTexture = PIXI.Texture.from(asset);

      if (labels.some((label) => label.calculateOutOfBounds(pendantTexture, anchorY))) continue;

      setMessageShape(possibleMessageShape);
      setShapeAutoAdapted(true);
      adapted = true;

      break;
    }
  }

  if (!adapted) {
    props.onTextBoundsChange(isSomeTextOutOfBounds);
  }

  return adapted;
};
