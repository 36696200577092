import React from 'react';
import classes from './DoneButton.module.css';

type Props = {
  onClick: () => any;
};

export const DoneButton: React.FC<Props> = ({ onClick }) => {
  const handleOnClick = React.useCallback(() => {
    onClick();
  }, []);

  return (
    <button className={classes.doneBtn + ' mt-6'} onClick={handleOnClick}>
      Done
    </button>
  );
};
