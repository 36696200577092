import { NameplateMaterialType } from '../../hooks/nameplate/use-nameplate-settings/types';
import {chain} from "lodash";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const backgroundAssetsContext = require.context('../../assets/images/backgrounds/');

export enum BackgroundName {
  BackgroundA = 'BackgroundA',
  Model3 = 'Model3',
  Model4 = 'Model4'
}

export enum ChainSide {
  ChainLeft = 'ChainLeft',
  ChainRight = 'ChainRight',
}

export enum CropVariant {
  Middle = 'Middle',
  Big = 'Big',
}

export type BackgroundConfig = {
  pendantProps: { x: number; y: number; scale: number };
  pendantPropsBottom: { x: number; y: number; scale: number };
  assetName: string;
  slicesRows: number;
  slicesCols: number;
  width: number;
  height: number;
  rulerColor: string;
  exportsCropRect: Record<CropVariant, { x: number; y: number; width: number; height: number }>;
  chainRing: {
    topPivot: { x: number; y: number };
    bottomPivot: { x: number; y: number };
    assetByMaterial: Record<NameplateMaterialType, string>;
  };
} & Record<
  ChainSide,
  {
    positionNeck: { x: number; y: number };
    byMaterial: Record<
      NameplateMaterialType,
      {
        asset: string;
        pivotNeck: { x: number; y: number };
        pivotNameplate: { x: number; y: number };
      }
    >;
  }
>;

export const backgroundConfigByName: Record<BackgroundName, BackgroundConfig> = {
  [BackgroundName.BackgroundA]: {
    assetName: 'model-1',
    slicesRows: 1,
    slicesCols: 2,
    width: 8192,
    height: 3436,
    pendantProps: {
      x: 2928,
      y: 2976,
      scale: 0.39,
    },
    pendantPropsBottom: {
      x: 2928,
      y: 2976,
      scale: 0.39,
    },
    rulerColor: '#ffffff',
    exportsCropRect: {
      [CropVariant.Middle]: { x: 0, y: 0, width: 1, height: 1 },
      [CropVariant.Big]: { x: 0, y: 0, width: 1, height: 1 },
    },
    [ChainSide.ChainLeft]: {
      positionNeck: { x: 2195, y: 1852 },
      byMaterial: {
        [NameplateMaterialType.GOLD]: {
          asset: 'gold L+blur.png',
          pivotNameplate: { x: 2162, y: 3604 },
          pivotNeck: { x: 78, y: 182 },
        },
        [NameplateMaterialType.ROSE_GOLD]: {
          asset: 'red gold L+blur.png',
          pivotNameplate: { x: 2232, y: 3482 },
          pivotNeck: { x: 42, y: 42 },
        },
        [NameplateMaterialType.SILVER]: {
          asset: 'silver L+blur.png',
          pivotNameplate: { x: 2232, y: 3482 },
          pivotNeck: { x: 42, y: 42 },
        },
      },
    },
    [ChainSide.ChainRight]: {
      positionNeck: { x: 3222, y: 2085 },
      byMaterial: {
        [NameplateMaterialType.GOLD]: {
          asset: 'gold R+blur.png',
          pivotNameplate: { x: 97, y: 2745 },
          pivotNeck: { x: 1025, y: 54 },
        },
        [NameplateMaterialType.ROSE_GOLD]: {
          asset: 'red gold R+blur.png',
          pivotNameplate: { x: 95, y: 2743 },
          pivotNeck: { x: 1020, y: 55 },
        },
        [NameplateMaterialType.SILVER]: {
          asset: 'silver R+blur.png',
          pivotNameplate: { x: 94, y: 2741 },
          pivotNeck: { x: 1022, y: 60 },
        },
      },
    },
    chainRing: {
      topPivot: { x: 33, y: 54 },
      bottomPivot: { x: 33, y: 104 },
      assetByMaterial: {
        [NameplateMaterialType.GOLD]: 'chain ring gold.png',
        [NameplateMaterialType.ROSE_GOLD]: 'chain ring gold.png',
        [NameplateMaterialType.SILVER]: 'chain ring silver.png',
      },
    },
  },
  [BackgroundName.Model3]: {
    assetName: 'model-3',
    slicesRows: 1,
    slicesCols: 2,
    width: 8192,
    height: 3436,
    pendantProps: {
      x: 2637,
      y: 2747,
      scale: 0.39,
    },
    pendantPropsBottom: {
      x: 2928,
      y: 2976,
      scale: 0.39,
    },
    rulerColor: '#ffffff',
    exportsCropRect: {
      [CropVariant.Middle]: { x: 2040, y: 2090, width: 1194, height: 1346 },
      [CropVariant.Big]: { x: 800, y: 0, width: 3636, height: 3436 },
    },
    [ChainSide.ChainLeft]: {
      positionNeck: { x: 2188, y: 1993 },
      byMaterial: {
        [NameplateMaterialType.GOLD]: {
          asset: 'chain gold L.png',
          pivotNameplate: { x: 1830, y: 3655 },
          pivotNeck: { x: 142, y: 1200 },
        },
        [NameplateMaterialType.ROSE_GOLD]: {
          asset: 'chain gold L.png',
          pivotNameplate: { x: 1830, y: 3655 },
          pivotNeck: { x: 142, y: 1200 },
        },
        [NameplateMaterialType.SILVER]: {
          asset: 'chain silver L.png',
          pivotNameplate: { x: 1830, y: 3655 },
          pivotNeck: { x: 142, y: 1200 },
        },
      },
    },
    [ChainSide.ChainRight]: {
      positionNeck: { x: 3218, y: 1991 },
      byMaterial: {
        [NameplateMaterialType.GOLD]: {
          asset: 'chain gold R.png',
          pivotNameplate: { x: 14, y: 2870 },
          pivotNeck: { x: 2097, y: 671 },
        },
        [NameplateMaterialType.ROSE_GOLD]: {
          asset: 'chain gold R.png',
          pivotNameplate: { x: 14, y: 2870 },
          pivotNeck: { x: 2097, y: 671 },
        },
        [NameplateMaterialType.SILVER]: {
          asset: 'chain silver R.png',
          pivotNameplate: { x: 14, y: 2870 },
          pivotNeck: { x: 2097, y: 671 },
        },
      },
    },
    chainRing: {
      topPivot: { x: 33, y: 54 },
      bottomPivot: { x: 33, y: 104 },
      assetByMaterial: {
        [NameplateMaterialType.GOLD]: 'chain ring gold.png',
        [NameplateMaterialType.ROSE_GOLD]: 'chain ring gold.png',
        [NameplateMaterialType.SILVER]: 'chain ring silver.png',
      },
    },
  },
  [BackgroundName.Model4]: {
    assetName: 'model-4',
    slicesRows: 2,
    slicesCols: 1,
    width: 2100,
    height: 3500,
    pendantProps: {
      x: 1165,
      y: 974,
      scale: 0.41,
    },
    pendantPropsBottom: {
      x: 1165,
      y: 1024,
      scale: 0.41
    },
    rulerColor: '#3E4966',
    exportsCropRect: {
      [CropVariant.Middle]: { x: 2040, y: 2090, width: 1194, height: 1346 },
      [CropVariant.Big]: { x: 800, y: 0, width: 3636, height: 3436 },
    },
    [ChainSide.ChainLeft]: {
      positionNeck: { x: 955, y: 332 },
      byMaterial: {
        [NameplateMaterialType.GOLD]: {
          asset: 'chain gold L.png',
          pivotNameplate: { x: 1830, y: 3655 },
          pivotNeck: { x: 142, y: 1200 },
        },
        [NameplateMaterialType.ROSE_GOLD]: {
          asset: 'chain silver L.png',
          pivotNameplate: { x: 1830, y: 3655 },
          pivotNeck: { x: 142, y: 1200 },
        },
        [NameplateMaterialType.SILVER]: {
          asset: 'chain silver L.png',
          pivotNameplate: { x: 1830, y: 3655 },
          pivotNeck: { x: 142, y: 1200 },
        },
      },
    },
    [ChainSide.ChainRight]: {
      positionNeck: { x: 1995, y: 249 },
      byMaterial: {
        [NameplateMaterialType.GOLD]: {
          asset: 'chain gold R.png',
          pivotNameplate: { x: 14, y: 2870 },
          pivotNeck: { x: 2097, y: 671 },
        },
        [NameplateMaterialType.ROSE_GOLD]: {
          asset: 'chain silver R.png',
          pivotNameplate: { x: 14, y: 2870 },
          pivotNeck: { x: 2097, y: 671 },
        },
        [NameplateMaterialType.SILVER]: {
          asset: 'chain silver R.png',
          pivotNameplate: { x: 14, y: 2870 },
          pivotNeck: { x: 2097, y: 671 },
        },
      },
    },
    chainRing: {
      topPivot: { x: 33, y: 54 },
      bottomPivot: { x: 33, y: 104 },
      assetByMaterial: {
        [NameplateMaterialType.GOLD]: 'chain ring gold.png',
        [NameplateMaterialType.ROSE_GOLD]: 'chain ring silver.png',
        [NameplateMaterialType.SILVER]: 'chain ring silver.png',
      },
    },
  },
};

export const getBackgroundSlicesAssets = (config: BackgroundConfig): string[][] => {
  return Array.from({ length: config.slicesCols }).map((v, i) => {
    return Array.from({ length: config.slicesRows }).map((v, j) => {
      return backgroundAssetsContext(`./${config.assetName}/slices/${i}${j}.jpg`);
    });
  });
};


export const getChainAsset = (
  config: BackgroundConfig,
  chainSide: ChainSide,
  material: NameplateMaterialType,
) => {
  return backgroundAssetsContext(
    `./${config.assetName}/chains/${config[chainSide].byMaterial[material].asset}`,
  );
};

export const getChainRingAsset = (config: BackgroundConfig, material: NameplateMaterialType) => {
  return getBgAsset(`${config.assetName}/chains/${config.chainRing.assetByMaterial[material]}`);
};


export const getBgAsset = (path: string) => {
  return backgroundAssetsContext(`./${path}`);
};

export const getPendantProps = (chainSize: number) => {
  return chainSize === 18 ? 'pendantPropsBottom' : 'pendantProps';
};