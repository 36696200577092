import React, { useState } from 'react';
import classes from './NameplateMenuActions.module.css';

import { MaterialMenu } from '../../../../components/nameplate/editors/settings/MaterialMenu';
import { SizeMenu } from '../../../../components/nameplate/editors/settings/SizeMenu';
import { TextEditor } from '../../../../components/nameplate/editors/text/TextEditor';
import { ChainEditor } from '../../../../components/nameplate/editors/chains/ChainEditor';
import { Logo } from '../../../../components/UI/Logo';
import { UndoRedoButtons } from '../../../../components/nameplate/page/UndoRedoButtons';
import { RulerButtons } from '../../../../components/UI/buttons/RulerButtons';
import { AppMode, useCommonState } from '@/hooks';
import cls from 'classnames';
import ReactGA from 'react-ga4'
import { motion } from 'framer-motion';
import { usePrice } from '@/hooks/use-price';
import { isMobile } from 'pixi.js';
import { Button } from '../../../../components/UI/buttons/Button'

export const NameplateMenuActions: React.FC = () => {
  const { appMode, onChangeAppMode, setIs3dViewEnabled } = useCommonState();
  const { priceData } = usePrice()
  const handleOpenConstructor = () => {
    if (appMode !== AppMode.CONSTRUCTOR) {

      ReactGA.gtag("event", "view_item", {
        currency: "USD",
        value: priceData?.singleProductPrice
      })

      onChangeAppMode(AppMode.CONSTRUCTOR);
    }
  };

  const isLanding = appMode === AppMode.LANDING;
  const [count, setCount] = useState(0);


  const handle3DButton = React.useCallback(() => {
    setCount((count) => {
      if (count % 2 == 0) {
        setIs3dViewEnabled(true)
      } else {
        setIs3dViewEnabled(false)
      } 
      return count + 1
    });
  }, []);

  return (
    <div
      className={cls(classes.menuActionsContainer, 'flex flex-col items-center w-full', {
        'ml-12': false,
      })}
    >
      <motion.div
        initial={false}
        className={cls('flex flex-col items-center w-full')}
        animate={isLanding ? { opacity: 0, transition: { duration: 0.5 } } : { opacity: 1, transition: { duration: 1.2 } }}
      >
        <div className={cls('w-[346px] flex flex-col justify-between items-start')}>
          <div style={{ opacity: 0 }}>
            <Logo />
          </div>
          <div className='flex justify-between items-center mt-8 w-[346px] h-[56px]'>
            <UndoRedoButtons />
            <Button
            style={{
            }}
              isTransparent={true}
              fullContent={true}
              onClick={handle3DButton}
            >
             <p style={{color: '#3E4966'}}>3D</p> 
             </Button>
            <RulerButtons />
          </div>
        </div>
        <SizeMenu />
        <div className='w-[346px] h-px bg-stylish-black opacity-20 my-3' />
      </motion.div>
      <div className='z-[1000] flex flex-col items-center w-full pl-0' onClick={handleOpenConstructor}>
        <MaterialMenu />
        <div className='w-[346px] h-px bg-stylish-black opacity-20 mt-2' />
        <TextEditor />
      </div>
      <div
        className={cls('flex flex-col items-center transition-all duration-1000', {
          'opacity-0': isLanding,
          'opacity-1': !isLanding,
        })}
      >
        <div className={isMobile ? 'w-[346px] h-px bg-stylish-black opacity-20 mb-4 mt-1' : 'w-[346px] h-px bg-stylish-black opacity-20 mb-4'} />
        <ChainEditor />
      </div>
    </div>
  );
};
