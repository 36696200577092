import React, { useEffect, useState } from 'react';
import CustomSlider from './CustomSlider';
import { NameplateSizeType, RulerUnits, useCommonState, useNameplateSettings } from '@/hooks';
import { mmToInch, preciseRound } from '@/utils/math';
import { getWeightInGrams } from '@/utils/nameplate';

export const PriceSlider = () => {
  const { fontSize, basePrice, materialPrice, chainPrice, selectedMaterial, onChangeFontSize, onChangeWidthInMM, onChangeHeightInMM, onChangeArea, area, baseArea, widthInMM, heightInMM } = useNameplateSettings();
  const { rulerMode } = useCommonState();

  const isCM = rulerMode === RulerUnits.CM;
  const width = isCM ? widthInMM : mmToInch(widthInMM);
  const height = isCM ? heightInMM : mmToInch(heightInMM);

  function calculatePriceWithCoefficient(input: number): number {
    const newArea = calculateAreaWithCoefficient(input, baseArea)
    const weight = preciseRound(getWeightInGrams(newArea, selectedMaterial));
    const newPrice = preciseRound(weight * preciseRound(materialPrice) + preciseRound(chainPrice))
    return newPrice
  }


  function calculateAreaWithCoefficient(input: number, baseArea: number): number {
    const coefficient = 0.76; // Коэффициент изменения на 1 единицу input
    return baseArea + (input - 50) * coefficient;
  }

  const smallChosen = fontSize == NameplateSizeType.SMALL || fontSize < NameplateSizeType.SMALL + (NameplateSizeType.MEDIUM - NameplateSizeType.SMALL) / 2;
  const mediumChosen = fontSize == NameplateSizeType.MEDIUM || (fontSize >= NameplateSizeType.MEDIUM / 2 && fontSize <= NameplateSizeType.MEDIUM + (NameplateSizeType.LARGE - NameplateSizeType.MEDIUM) / 2);
  const largeChosen = fontSize == NameplateSizeType.LARGE || fontSize > NameplateSizeType.MEDIUM + (NameplateSizeType.LARGE - NameplateSizeType.MEDIUM) / 2;

  const getTitle = () => {
    let res = '';

    if (smallChosen) {
      res = 'Small';
    } else if (mediumChosen) {
      res = 'Medium';
    } else if (largeChosen) {
      res = 'Large'
    }

    return res;
  }

  return (
    <div style={{
      display: 'relative',
      width: '348px',
      // padding: 16,
      height: 102,
      // backgroundColor: 'red'
    }}>
      <div
        style={{
          width: 48,
          height: 48,
          position: 'absolute',
          // left: 24,
          // backgroundColor: 'yellow',
          alignContent: 'center',
          justifyItems: 'center',
          border: '1px solid rgba(62, 73, 102, 0.16)',
          borderRadius: '50%',
          top: 7,
        }}
      >
        <p style={{ color: '#3E4966', textAlign: 'center'  }}>{`${preciseRound(calculatePriceWithCoefficient(0))}$`}</p>
      </div>
      <div
        style={{
          width: 48,
          height: 48,
          position: 'absolute',
          top: 7,
          left: 348,
          alignContent: 'center',
          justifyItems: 'center',
          border: '1px solid rgba(62, 73, 102, 0.16)',
          borderRadius: '50%',
        }}
      >
        <p style={{ color: '#3E4966', textAlign: 'center', width: '100%' }}>
          {`${preciseRound(calculatePriceWithCoefficient(100))}$`}
        </p>
      </div>
      <CustomSlider />
      <div style={{
        position: 'absolute',
        justifyItems: 'center',
        alignContent: 'center',
        top: 82,
        height: 20,
        width: 348,
        // backgroundColor: 'green'
      }}>
        <p style={{ color: '#3E4966', textAlign: 'center' }}>{`Size: ${getTitle()}, ${preciseRound(width)}x${preciseRound(height)} mm`}</p>
      </div>
    </div>
  )
}