import fontsDataJson from './fonts-data.json';
import connectionsDataJson from './connections-data.json';
import defaultConnectionsPairsJson from './default-connections-pairs.json';

const fontsData = fontsDataJson as Record<
  string,
  Record<
    string,
    {
      leftCircleCenter: {
        x: number;
        y: number;
      };
      leftCircleEdge: {
        x: number;
        y: number;
      };
      rightCircleCenter: {
        x: number;
        y: number;
      };
      rightCircleEdge: {
        x: number;
        y: number;
      };
    }
  >
>;

const connectionsData = connectionsDataJson as Record<
  string,
  Record<
    string,
    {
      leftConnections: {
        x: number;
        y: number;
        radius: number;
      }[];
      rightConnections: {
        x: number;
        y: number;
        radius: number;
      }[];
      innerConnections: {
        x: number;
        y: number;
        radius: number;
      }[];
    }
  >
>;

const defaultConnectionsPairs = defaultConnectionsPairsJson as Record<
  string,
  string[]
>;

export { fontsData, connectionsData, defaultConnectionsPairs };
