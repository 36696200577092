import React from 'react';
import classes from './Navigation.module.css';

import { NameplateModeType, useNamePlateMenu } from '../../../../../hooks';
import { Button } from '../../../../UI/buttons/Button';

import NamePlateIcon from '../../../../../assets/images/icons/common/nameplate.svg';
import NamePlateShortStringIcon from '../../../../../assets/images/icons/common/nameplate-short-string.svg';
import cls from 'classnames';
import { useMediaQuery } from '@mui/material';

export const Navigation: React.FC = () => {
  const { mode, onSelectMode } = useNamePlateMenu();
  const isMobile = useMediaQuery('(max-width: 1023px)');

  const handleGoTextPage = React.useCallback(() => {
    onSelectMode(NameplateModeType.TEXT);
  }, [mode]);

  return (
    <>
      {isMobile && (
        <div className='flex items-center w-full'>
          <Button className={classes.modifyNamePlate}>
            <img src={NamePlateIcon} alt='add' />
            <span>Product</span>
          </Button>
          <Button onClick={handleGoTextPage} className={cls(classes.smallNavButton, 'ml-1 z-10')}>
            <img src={NamePlateShortStringIcon} alt='icon' />
          </Button>
        </div>
      )}
    </>
  );
};
