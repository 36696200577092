import React, { useEffect, useState } from 'react';
import { Container } from '../Container';
import { motion } from 'framer-motion';
import { ReactComponent as BgSVG } from '@assets/images/landing/svg/constructor-bg-element-2.svg';
import { ReactComponent as Bg2SVG } from '@assets/images/landing/svg/pattern-top-right1.svg';
import { ReactComponent as Bg3SVG } from '@assets/images/landing/svg/pattern-top-right2.svg';
import { AppMode, useCommonState, useNameplateSettings } from '@/hooks';
import { NameplateMenuActions } from '@/pages/nameplate/NameplateDesktop/NameplateMenuActions';
import { NameplateCanvasComponent } from '@/pixi/nameplate-canvas-app/NameplateCanvasComponent';
import NameplateHeader from '@pages/nameplate/NameplateHeader';
import cls from 'classnames';
import { Overlay } from '@components/common/Overlay';
import styles from './NewContructor.module.css';
import { useNavigate } from 'react-router-dom';
import { OrderButton } from '@components/nameplate/page/OrderButton';
import { ZoomPicker } from '@components/common/ZoomPicker';
import { useIsXL, useIsXXLS, useIsLG, useIsLGG } from '@/hooks/nameplate/use-nameplate-menu/use-media-query';
import { Preloader } from '@/components/common/Preloader';
import { BasicNameplateCanvasComponent } from '@/pixi/nameplate-canvas-app/BasicNameplateCanvasComponent';
import ThreeDViewComponent from '@/pixi/nameplate-canvas-app/ThreedViewComponent';
// import { addCart, deleteCart } from '@/api';

function webgl_support() {
    try {
        const canvas = document.createElement('canvas');
        return !!window.WebGLRenderingContext &&
            (canvas.getContext('webgl') || canvas.getContext('experimental-webgl'));
    } catch (e) {
        return false;
    }
}
const isWebGL = webgl_support()


const consturctorOpenDuration = 0.8
const consturctorCloseDuration = 0.8

const variants = {
    showTextBlock: { opacity: 1, transition: { duration: consturctorCloseDuration } },
    hideTextBlock: { opacity: 0, transition: { duration: consturctorOpenDuration + 1 } },
    hideContructorElements: { opacity: 0, transition: { duration: consturctorCloseDuration } },
    showContructorElements: { opacity: 1, transition: { duration: consturctorOpenDuration } },
    hideOverlay: { opacity: 0, transition: { delay: consturctorCloseDuration / 3, duration: consturctorCloseDuration * 2 / 3 } },
    showOverlay: { opacity: 1, transition: { duration: consturctorOpenDuration / 2 } },
    showLips: { opacity: 1, transition: { duration: consturctorCloseDuration }, transitionEnd: { display: 'block' } },
    hideLips: { opacity: 0, transition: { duration: 0.2 }, transitionEnd: { display: 'none' } }
};

export const NewConstructor: React.FC = () => {
    const { appMode, onChangeAppMode, is3dViewEnabled } = useCommonState();
    const navigate = useNavigate();
    const { texturesLoading, constructor, images, selectedMaterial, text, chainSize, textSize, price } = useNameplateSettings();

    const isLoading = texturesLoading || constructor.isLoading || !!images?.isLoading;

    const [isWebGLSupported, setIsWebGLSuppoted] = useState(true);

    const isXL = useIsXL();
    const isXXLS = useIsXXLS();
    const isLG = useIsLG();
    const isLGG = useIsLGG();

    const objectWidth = isXXLS ? 780 : isXL ? 700 : isLGG ? 600 : isLG ? 450 : 780

    const handleGoToCheckoutProcess = async () => {
        // const customerId = localStorage.getItem("mc_customerId")
        // if (customerId) {
        //     const cartId = localStorage.getItem("mc_cartId")
        //     if (cartId) {
        //         await deleteCart({ cartId }).then(async () => {
        //             localStorage.removeItem("mc_cartId")
        //             await addCart({
        //                 customerId: customerId,
        //                 orderTotal: price.data,
        //                 productMaterial: selectedMaterial,
        //                 productName: text,
        //                 productSize: `${chainSize}`
        //             }).then((addCartResponse) => {
        //                 localStorage.setItem("mc_cartId", addCartResponse.data.id)
        //             }).catch((err) => {
        //                 console.log(err)
        //             })
        //         }).catch(() => { })
        //     } else {
        //         await addCart({
        //             customerId: customerId,
        //             orderTotal: price.data,
        //             productMaterial: selectedMaterial,
        //             productName: text,
        //             productSize: `${chainSize}`
        //         }).then((addCartResponse) => {
        //             localStorage.setItem("mc_cartId", addCartResponse.data.id)
        //         }).catch((err) => {
        //             console.log(err)
        //         })
        //     }
        // }
        navigate('/checkout');
    };

    const handleOpenContructor = () => {
        if (appMode !== AppMode.CONSTRUCTOR) {
            onChangeAppMode(AppMode.CONSTRUCTOR);
        }
    };

    useEffect(() => {
        if (isWebGL == false || isWebGL == null) {
            setIsWebGLSuppoted(false)
            alert("Your browser doesn't support some libraries. Please use another browser or update to the last version.")
        } else {
            setIsWebGLSuppoted(true)
        }
    }, [])


    return (
        <>
            <Container>
                <motion.div
                    initial={false}
                    variants={variants}
                    style={{ y: 0, x: '89%' }}
                    animate={!(appMode === AppMode.CONSTRUCTOR) ? 'showLips' : 'hideLips'}
                >
                    <Bg3SVG className='absolute -z-20' />
                </motion.div>
                <motion.div
                    initial={false}
                    variants={variants}
                    animate={!(appMode === AppMode.CONSTRUCTOR) ? 'hideOverlay' : 'showOverlay'}
                    style={{ zIndex: 35, position: 'absolute' }}
                >
                    <Overlay />
                </motion.div>
                <div className='flex flex-col pt-28 lg:flex-row justify-between gap-x-4 lg:gap-x-14 xl:gap-x-32 items-center lg:items-start py-5'>
                    <div className='pl-5'>
                        <motion.h1
                            className='font-averta font-extrabold pl-5 lg:pl-0 text-stylish-black text-5xl lg:text-[4rem] leading-10 lg:leading-[3.5rem] mb-5 mt-10'
                        >
                            Customize <br /> in a tap!
                        </motion.h1>
                        <motion.p
                            className='hidden lg:block text-stylish-black text-2xl mb-12'
                        >
                            Create your designs on flight
                        </motion.p>
                    </div>
                    <motion.div
                        initial={false}
                        onClick={handleOpenContructor}
                        transition={{ duration: 1 }}
                        className='shadow-3xlwarmCont xl:w-[700px] xxls:w-[780px] lg:w-[450px] lgg:w-[600px]'
                        animate={
                            appMode === AppMode.CONSTRUCTOR ?
                                { height: '100vh', left: 0, top: 0, width: '100%', x: 0 }
                                :
                                { x: '500px', aspectRatio: 1.226, left: 'auto', top: 'auto', width: objectWidth, height: objectWidth / 1.226 }
                        }
                        style={
                            appMode === AppMode.CONSTRUCTOR ?
                                {
                                    zIndex: 20,
                                    position: 'absolute',
                                    overflow: 'hidden',
                                    borderRadius: "0"
                                }
                                :
                                {
                                    zIndex: 10,
                                    position: 'absolute',
                                    overflow: 'hidden',
                                    borderRadius: "1.5rem",
                                }
                        }
                    >
                        {/* <Preloader open={isWebGLSupported && isLoading} /> */}

                        <motion.div
                            initial={false}
                            variants={variants}
                            animate={!(appMode === AppMode.CONSTRUCTOR) ? 'showOverlay' : 'hideOverlay'}
                            className='h-[200px] w-full fixed left-0 bottom-0 right-0 z-10 pointer-events-none'
                            style={{
                                background:
                                    'linear-gradient(0deg, rgba(245,245,245,0.8) 1%, rgba(245,245,245,0) 100%, rgba(245,245,245,0) 100%)',
                            }}
                        />
                        <motion.div
                            initial={false}
                            transition={{ duration: 1 }}
                            animate={appMode === AppMode.CONSTRUCTOR ?
                                { x: 0, y: 0 }
                                :
                                { position: 'absolute', x: -(window.innerWidth * 1 / 3 + window.innerWidth / 10), y: -100 }
                            }
                        >
                            <BasicNameplateCanvasComponent />
                            <ThreeDViewComponent/>
                            {/* {
                                // (isWebGL == false || isWebGL == null) ? <></> : <NameplateCanvasComponent />
                                // <BasicNameplateCanvasComponent />
                                is3dViewEnabled ? <ThreeDViewComponent/> : <BasicNameplateCanvasComponent />
                                
                            } */}
                        </motion.div>
                        <NameplateHeader />
                        <motion.div
                            initial={false}
                            variants={variants}
                            animate={appMode === AppMode.CONSTRUCTOR ? 'showContructorElements' : 'hideContructorElements'}
                        >
                            {!is3dViewEnabled && <ZoomPicker min={1} max={2} step={0.00001} />}
                            <OrderButton onClick={handleGoToCheckoutProcess} />
                        </motion.div>
                        <motion.div
                            initial={false}
                            variants={variants}
                            style={{ y: -10 }}
                            animate={!(appMode === AppMode.CONSTRUCTOR) ? 'showLips' : 'hideLips'}
                        >
                            <Bg2SVG className='absolute z-40 right-1' />
                        </motion.div>
                    </motion.div>

                </div>
                <motion.div
                    initial={false}
                    className='absolute top-[14rem] -z-20'
                    style={{ x: -100 }}
                >
                    <BgSVG />
                </motion.div>

                <div style={{ position: 'relative', width: '410px', height: '450px' }}></div>
                <div style={{ position: 'absolute', width: '410px', height: '100vh', top: '70px' }}>
                    <div>
                        <motion.div
                            initial={false}
                            style={{ x: -34 }}
                            // style={{x: -34, top: -300}}
                            className={appMode === AppMode.CONSTRUCTOR ? cls('max-w-md xl:max-w-lg w-full h-full absolute z-40 overflow-auto', styles.menu) : cls('max-w-md xl:max-w-lg w-full h-full absolute z-40 overflow-hidden', styles.menu)}
                        >
                            <NameplateMenuActions />
                        </motion.div>
                    </div>
                </div>
            </Container>
        </>
    );
};
