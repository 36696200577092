export const addWhiteBg = (canvas: HTMLCanvasElement): HTMLCanvasElement => {
  const whiteCanvas = document.createElement('canvas');
  const whiteCtx = whiteCanvas.getContext('2d')!;
  whiteCanvas.width = canvas.width;
  whiteCanvas.height = canvas.height;
  whiteCtx.fillStyle = 'white';
  whiteCtx.fillRect(0, 0, whiteCanvas.width, whiteCanvas.height);
  whiteCtx.drawImage(canvas, 0, 0);

  return whiteCanvas;
};
