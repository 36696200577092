import React from 'react';
import { useNameplateSettings } from '../use-nameplate-settings';

export const useNamePlateSnapshots = () => {
  const { onUndo, onRedo, state } = useNameplateSettings();

  return {
    undoDisabled: state.snapshotIndex === 0,
    redoDisabled: state.snapshotIndex === state.snapshots.length - 1,
    undo: onUndo,
    redo: onRedo,
  };
};
